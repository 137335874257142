import { handleActions } from "redux-actions";
import { push } from 'react-router-redux'
import { message, notification } from "antd";
import { api } from "api";

export const actionTypes = {
    GET_CONFIG: "GET_CONFIG",
    SET_CONFIG: "SET_CONFIG",
    SET_FEATURES: "SET_FEATURES",
    SET_BANNER: "SET_BANNER",
    SEND_SOLICITUD: "SEND_SOLICITUD",
    SET_SLIDER: "SET_SLIDER",
    SET_INFO: "SET_INFO",
    SELECT_SLIDER: "SELECT_SLIDER",
    SET_PARITY: "SET_PARITY"
};

const modalSuccessAny = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        duration: 3
    });
};

export function setFeatures(features) {
    return {
        type: actionTypes.SET_FEATURES,
        features
    };
}

export function setParity(parity) {
    return {
        type: actionTypes.SET_PARITY,
        parity
    };
}

export function setConfig(config) {
    return {
        type: actionTypes.SET_CONFIG,
        config
    };
}

export function setDashboard(info) {
    return {
        type: actionTypes.SET_INFO,
        info
    };
}

export function setBanners(banners) {
    return {
        type: actionTypes.SET_BANNER,
        banners
    };
}

export function setSlider(slider) {
    return {
        type: actionTypes.SET_SLIDER,
        slider
    };
}

export function selectedSlider(selected_slider) {
    return {
        type: actionTypes.SELECT_SLIDER,
        selected_slider
    };
}

export const getConfig = () => (dispatch) => {
    api.get("config/configuraciones")
        .then((config) => {
            dispatch(setConfig(config));
            // dispatch();
        })
        .catch((error) => {
            const message = "Hubo un error al enviar los datos";
            const description = "Algo salió mal";
            dispatch(modalSuccessAny("error", message, description));
        })
        .finally(() => {
            // dispatch(setLoader(false));
        });
};

export const getParity = () => (dispatch) => {
    api.get("config/get_parity")
        .then((parity) => {
            dispatch(setParity(parity));
            // dispatch();
        })
        .catch((error) => {
            const message = "Hubo un error al enviar los datos";
            const description = "Algo salió mal";
            dispatch(modalSuccessAny("error", message, description));
        })
        .finally(() => {
            // dispatch(setLoader(false));
        });
};

export const getDashboard = () => (dispatch) => {
    api.get("config/dashboard")
        .then((info) => {
            dispatch(setDashboard(info));
            // dispatch();
        })
        .catch((error) => {
            const message = "Hubo un error al enviar los datos";
            const description = "Algo salió mal";
            dispatch(modalSuccessAny("error", message, description));
        })
        .finally(() => {
            // dispatch(setLoader(false));
        });
};

export const getFeatures = () => (dispatch) => {
    api.get("features/features")
        .then((features) => {
            dispatch(setFeatures(features));
            // dispatch();
        })
        .catch((error) => {
            const message = "Hubo un error al enviar los datos";
            const description = "Algo salió mal";
            dispatch(modalSuccessAny("error", message, description));
        })
        .finally(() => {
            // dispatch(setLoader(false));
        });
};

export const getBanners = () => (dispatch) => {
    api.get("banner/banners")
        .then((banners) => {
            dispatch(setBanners(banners));
            // dispatch();
        })
        .catch((error) => {
            const message = "Hubo un error al enviar los datos";
            const description = "Algo salió mal";
            modalSuccessAny("error", message, description);
        })
        .finally(() => {
            // dispatch(setLoader(false));
        });
};


export const addBanner= (data) => (dispatch) => {
    api.post("banner/", data)
        .then((response) => {
            const message = "Completado";
            const description = "Se ha ingresado el banner correctamente";
            modalSuccessAny("success", message, description);
            window.location.reload()
        })
        .catch((error) => {
            let message = "";
            if (error) {
                message = error.error;
            } else {
                message = "Hubo un error al ingresar el Banner";
            }
            const description = "";
            dispatch(modalSuccessAny("error", message, description));
        });
};

export const updateBanner = (data) => (dispatch) => {
    api.post("banner/update_banner", data)
        .then((response) => {
            dispatch(getBanners());
            const message = "Completado";
            const description = "se actualizó el registro correctamente";
            modalSuccessAny("success", message, description);
        })
        .catch((error) => {
            const message = "Hubo un error al enviar los datos";
            const description = "Algo salió mal";
            modalSuccessAny("error", message, description);
        });
};

export const deleteBanner = (data) => (dispatch) => {
    api.eliminar(`banner/${data.id}/`, data)
        .then((response) => {
            
            const message = "Completado";
            const description = "Se eliminó el registro correctamente";
            location.reload()
            modalSuccessAny("success", message, description);
        })
        .catch((error) => {
            const message = "Hubo un error al enviar los datos";
            const description = "Algo salió mal";
            modalSuccessAny("error", message, description);
        });
};


export const updateFeatures = (data) => (dispatch) => {
    api.post("features/update_features", data)
        .then((response) => {
            dispatch(getFeatures());
            const message = "Completado";
            const description = "Se actualizó el registro correctamente";
            modalSuccessAny("success", message, description);
        })
        .catch((error) => {
            const message = "Hubo un error al enviar los datos";
            const description = "Algo salió mal";
            modalSuccessAny("error", message, description);
        });
};

export const createFeatures = (data) => (dispatch) => {
    api.post("features", data)
        .then((response) => {
            dispatch(getFeatures());
            const message = "Completado";
            const description = "Se creo el registro correctamente";
            modalSuccessAny("success", message, description);
        })
        .catch((error) => {
            const message = "Hubo un error al enviar los datos";
            const description = error.detail ? error.detail : "Algo salió mal";
            modalSuccessAny("error", message, description);
        });
};

export const deleteFeatures = (id) => (dispatch) => {
    api.eliminar(`features/${id}`)
        .then((response) => {
            dispatch(getFeatures());
            const message = "Completado";
            const description = "Se elimino el registro correctamente";
            modalSuccessAny("success", message, description);
        })
        .catch((error) => {
            const message = "Hubo un error al enviar los datos";
            const description = "Algo salió mal";
            modalSuccessAny("error", message, description);
        });
};

export const createSlider = (data) => (dispatch) => {
    api.post("cgf/slider", data)
        .then((response) => {
            dispatch(getSlider());
            const message = "Completado";
            const description = "Se ha creado un nuevo slider";
            modalSuccessAny("success", message, description);
            dispatch(push('/sliders'));
        })
        .catch((error) => {
            console.error(error)
            let message = "";
            if (error) {
                message = error.error;
            } else {
                message = "Hubo un error crear el registro";
            }
            const description = "";
            modalSuccessAny("error", message, description);
        });
};

export const deleteSlider = (data) => (dispatch) => {
    api.post("cgf/slider/eliminacion_permanente", data)
        .then((response) => {
            dispatch(getSlider());
            const message = "Completado";
            const description = "Se ha elminado el objeto seleccionado";
            modalSuccessAny("success", message, description);
        })
        .catch((error) => {
            let message = "";
            if (error) {
                message = error.error;
            } else {
                message = "Hubo un error al eliminar el objeto";
            }
            const description = "";
            modalSuccessAny("error", message, description);
        });
};

export const updateImageSlider = (data) => (dispatch) => {
    api.post("cgf/slider-image/actualizar_imagen", data)
        .then((response) => {
            dispatch(getSlider());
            const message = "Completado";
            const description = "Se ha actualizado el objeto seleccionado";
            modalSuccessAny("success", message, description);
            dispatch(push(`/slider/detalle/${data["slider"]}`))
        })
        .catch((error) => {
            let message = "";
            if (error) {
                message = error.error;
            } else {
                message = "Hubo un error al actualizar el objeto";
            }
            const description = "";
            modalSuccessAny("error", message, description);
        });
};

export const deleteImageSlider = (data) => (dispatch) => {
    api.post("cgf/slider-image/eliminar_imagen", data)
        .then((response) => {
            dispatch(getSlider());
            const message = "Completado";
            const description = "Se ha eliminado el objeto seleccionado";
            modalSuccessAny("success", message, description);
            dispatch(push(`/slider/detalle/${data.slider}`))
            location.reload()
        })
        .catch((error) => {
            let message = "";
            if (error) {
                message = error.error;
            } else {
                message = "Hubo un error al eliminar el objeto";
            }
            const description = "";
            modalSuccessAny("error", message, description);
        });
};

export const selectSlider = (data) => (dispatch) => {
    api.post("cgf/slider/slider_seleccionado", data)
        .then((response) => {
            dispatch(getSlider());
            const message = "Completado";
            const description = "Se ha seleccionado como predeterminado";
            modalSuccessAny("success", message, description);
        })
        .catch((error) => {
            let message = "";
            if (error) {
                message = error.error;
            } else {
                message = "Hubo un error al realizar el cambio";
            }
            const description = "";
            modalSuccessAny("error", message, description);
        });
};

export const addImageSlider = (data) => (dispatch) => {
    api.post("cgf/slider-image", data)
        .then((response) => {
            const message = "Completado";
            const description = "Se ha seleccionado como predeterminado";
            modalSuccessAny("success", message, description);
            dispatch(push(`/slider/detalle/${data["slider"]}`))
        })
        .catch((error) => {
            let message = "";
            if (error) {
                message = error.error;
            } else {
                message = "Hubo un error al realizar el cambio";
            }
            const description = "";
            dispatch(modalSuccessAny("error", message, description));
        });
};

export const setStateSlider = (selected_slider) => (dispatch) => {
    try {
        dispatch({ type: actionTypes.SELECT_SLIDER, selected_slider });
    } catch (error) {
        console.error(error);
    }
};

export const getSlider = () => (dispatch) => {
    api.get("cgf/slider")
        .then((slider) => {
            dispatch(setSlider(slider.results));
        })
        .catch((error) => {
            const message = "Hubo un error al enviar los datos";
            const description = "Algo salió mal";
            dispatch(modalSuccessAny("error", message, description));
        });
};


export const selectedBanner = (data) => (dispatch) => {
    api.post("banner/selected_banner", data)
        .then((response) => {
            dispatch(getBanners());
            const message = "Completado";
            const description = "Se ha actualizado el banner correctamente";
            modalSuccessAny("success", message, description);
        })
        .catch((error) => {
            const message = "Hubo un error al enviar los datos";
            const description = "Algo salió mal";
            modalSuccessAny("error", message, description);
        });
};


export const actions = {
    getConfig,
    getFeatures,
    getBanners,
    deleteBanner,
    getSlider,
    updateBanner,
    selectedBanner,
    addBanner,
    updateFeatures,
    createFeatures,
    deleteFeatures,
    getDashboard,
    selectSlider,
    setStateSlider,
    addImageSlider,
    createSlider,
    deleteSlider,
    updateImageSlider,
    deleteImageSlider,
    getParity
};

export const reducers = {
    [actionTypes.SEND_SOLICITUD]: (state) => {
        return {
            ...state
        };
    },
    [actionTypes.GET_CONFIG]: (state) => {
        return {
            ...state
        };
    },
    [actionTypes.SET_CONFIG]: (state, { config }) => {
        return {
            ...state,
            config
        };
    },
    [actionTypes.SET_FEATURES]: (state, { features }) => {
        return {
            ...state,
            features
        };
    },
    [actionTypes.SET_BANNER]: (state, { banners }) => {
        return {
            ...state,
            banners
        };
    },
    [actionTypes.SET_SLIDER]: (state, { slider }) => {
        return {
            ...state,
            slider
        };
    },
    [actionTypes.SET_INFO]: (state, { info }) => {
        return {
            ...state,
            info
        };
    },
    [actionTypes.SELECT_SLIDER]: (state, { selected_slider }) => {
        return {
            ...state,
            selected_slider
        };
    },
    [actionTypes.SET_PARITY]: (state, { parity }) => {
        return {
            ...state,
            parity
        };
    }
};

export const initState = {
    features: [],
    banners: [],
    config: [],
    slider: [],
    info: [],
    selected_slider: [],
    parity: {}
};

export default handleActions(reducers, initState);
