import React, { Component } from 'react';
import DefaultHeader from '../layout/Header/DefaultHeader'
import {Modal} from 'antd'
import { connect } from "react-redux";
import {actions} from '../../../redux/modules/solicitudes/solicitudes'
import RetiroForm from './RetiroForm';
import LoadMask from "../Utils/LoadMask/LoadMask";
import TableSolicitud from "./TableSolicitud"
import TableActividad from "./TableActividad"


class Solicitudes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            isModalRequested: false,
            isModalPaid:false,
            loader: true
        }
    }

    componentDidMount(){
        this.handleLoader()
        this.props.getSolicitudes()
    }

    showModal = () => {
        this.setState({isModalVisible:true})
      };

      showModalRequested = () => {
        this.setState({isModalRequested:true})
      };

      showModalPaid = () => {
        this.setState({isModalPaid:true})
      };
    
       handleOk = () => {
        this.setState({isModalVisible:false})
        this.setState({isModalRequested:false})
        this.setState({isModalPaid:false})
      };
    
       handleCancel = () => {
        this.setState({isModalVisible:false})
        this.setState({isModalRequested:false})
        this.setState({isModalPaid:false})
      };

      handleSend = (data) =>{
        this.props.onSubmitSolicitud(data);
      }

      handleLoader = () => {
        setTimeout(()=> this.setState({loader:false}),1000);
    }

    render() {
        return (
            <div>
                <DefaultHeader title="Solicitudes de retiro" visible={true}/>
                {this.state.loader?
                    <LoadMask loading={this.state.loader} dark>
                    </LoadMask>
                :
                    <div>
                    {this.props.data?
                        <div className="container">
                            {this.props.data.length>0?
                            <div className="ps-card">
                                <TableActividad info={this.props.data} styled={true}/>
                            </div>
                            :
                            <p>No hay solicitudes en este momento</p>
                            }
                        </div>
                    :null}
                    </div>
                }

            <Modal 
                title="" 
                visible={this.state.isModalVisible} 
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >
                <RetiroForm onSubmit={this.handleSend}/>            
            </Modal>

            <Modal
                width={800} 
                title="Ultimas 10 solicitudes" 
                visible={this.state.isModalRequested} 
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                >
                    <TableSolicitud info={this.props.data.por_liberar} styled={false}/>
            </Modal>

            <Modal
                width={800} 
                title="Ultimos 10 retiros" 
                visible={this.state.isModalPaid} 
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                >
                    <TableSolicitud info={this.props.data.pagadas} styled={false}/>       
            </Modal>
        </div>

        );
    }
}


const ms2p = (state) => {
    return state.solicitudes;
  };

const md2p = {...actions}
  
export default connect(ms2p ,md2p)(Solicitudes);
