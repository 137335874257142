import React, { useState } from "react";
import { Table, Modal } from "antd";

import moment from "moment";
import DetalleProducto from "./DetalleProducto";

const TableProductos = (info, styled) => {
    const [desde, setDate1] = useState(
        moment().clone().startOf("month").format("YYYY-MM-DD")
    );
    const [hasta, setDate2] = useState(moment().format());
    const [dataSource, setDataSource] = useState(info.info);
    const [originalData, setOriginalData] = useState(info.info);
    const [isModalVisible, setModal] = useState(false);
    const [producto, setInfo] = useState([]);

    const handleOk = () => {
        setOk(false);
        setModal(false);
        setInfo([]);
    };

    const handleCancel = () => {
        setModal(false);
        setInfo([]);
    };

    const getDate = (date, split1, split2, order) => {
      const first_split = date.split(split1);
      const second_split = first_split[0].split(split2);
      return new Date(parseInt(second_split[order[0]]), parseInt(second_split[order[1]]) - 1, parseInt(second_split[order[2]]));
    }

    const handleSetFilter = () => {
        const inicio = getDate(desde, 'T', '-', [0, 1, 2]);
        inicio.setHours(0, 0, 0);
        const final = getDate(hasta, 'T', '-', [0, 1, 2]);
        final.setHours(23, 59, 59);

        const resultData = originalData.filter((obj) => {
            const time = getDate(obj.agregado, " - ", '-', [0, 2, 1]);
            time.setHours(0, 0, 0);
            return inicio.getTime() <= time.getTime() && time.getTime() <= final.getTime();
        });
        setDataSource(resultData);
    };

    const columns = [
        {
            title: "No.",
            key: "id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
            showSorterTooltip: false
        },
        {
            title: "Fecha Solicitud",
            dataIndex: "agregado",
            sorter: (a, b) =>
                moment(a.agregado).unix() -
                moment(b.agregado).unix(),
            showSorterTooltip: false,
            render: (agregado) => {
              const agregado_split = agregado.split(" - ");
              return (
                <p>{agregado_split[0]}</p>
            )}
        },
        {
            title: "Vendedor",
            dataIndex: "vendedor",
            sorter: (a, b) => a.vendedor.localeCompare(b.vendedor),
            showSorterTooltip: false,
            render: (vendedor) => <p>{vendedor}</p>
        },
        {
            title: "Nombre del producto / Envase",
            render: (record) => (
                <p>
                    {record.nombre_comercial} - {record.envase}
                </p>
            )
        },
        {
            title: "Familia",
            dataIndex: "descripcion",
            render: (descripcion) => <p>{descripcion.familia}</p>
        },
        {
            title: "Categoría",
            dataIndex: "descripcion",
            render: (descripcion) => <p>{descripcion.categoria}</p>
        },
        {
            title: "-",
            render: (record) => (
                <React.Fragment>
                    <button
                        className="ps-btn"
                        onClick={(e) => {
                            setInfo(record), setModal(true);
                        }}
                    >
                        Ver
                    </button>
                </React.Fragment>
            )
        }
    ];

    return (
        <div>
            <div className="form-group">
                {styled ? (
                    <React.Fragment>
                        <section className="ps-dashboard">
                            <div
                                className="container"
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center"
                                }}
                            >
                                <div className="col-4">
                                    <label htmlFor="estados">Desde:</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="fecha_i"
                                        value={desde}
                                        onChange={(e) => {
                                            const currValue = e.target.value;
                                            setDate1(currValue);
                                        }}
                                    />

                                    <hr />
                                </div>
                                <div className="col-4">
                                    <label htmlFor="estados">Hasta:</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="fecha_f"
                                        value={moment(hasta).format(
                                            "YYYY-MM-DD"
                                        )}
                                        onChange={(e) => {
                                            const currValue = e.target.value;
                                            setDate2(currValue);
                                        }}
                                    />
                                    <hr />
                                </div>
                                <div>
                                    <button
                                        className="ps-btn xs"
                                        onClick={handleSetFilter}
                                    >
                                        Filtrar
                                    </button>
                                </div>
                            </div>
                        </section>

                        <Table
                            dataSource={dataSource}
                            columns={columns}
                            className="table ps-table--market"
                        />
                    </React.Fragment>
                ) : (
                    <Table dataSource={dataSource} columns={columns} />
                )}
            </div>

            <Modal
                title=""
                width={800}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
            >
                <DetalleProducto producto={producto} closeModal={handleCancel} />
            </Modal>
        </div>
    );
};

export default TableProductos;
