import { handleActions } from 'redux-actions'
import { push } from 'react-router-redux'
import { initialize as initializeForm } from 'redux-form'
import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router-dom'
import { api } from 'api'
import { notification } from 'antd'
import Swal from 'sweetalert2'

const actionTypes = {
  SET_CATALOGO: 'SET_CATALOGO',
  SET_PRODUCTOS_AP: 'SET_PRODUCTOS_AP',
  SET_CATEGORIAS_AP: 'SET_CATEGORIAS_AP',
  SET_FAMILIA_AP: 'SET_FAMILIA_AP',
  SET_PRODUCTO: 'SET_PRODUCTO',
  SET_CATEGORIA: 'SET_CATEGORIA',
  SET_FAMILIA: 'SET_FAMILIA'

}

// ------------------------------------
// Pure Actions
// ------------------------------------

const SwalAlert = (title, text, type) => {
  Swal.fire({
    width: '400px',
    heightAuto: true,
    title: title,
    text: text,
    type: type

  })
}

const modalSuccessAny = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
    duration: 1
  })
}

export function setCatalogo (productos) {
  return {
    type: actionTypes.SET_CATALOGO,
    productos
  }
};

export function setFamilias (familias) {
  return {
    type: actionTypes.SET_FAMILIA,
    familias
  }
};

export function setCategoria (categorias) {
  return {
    type: actionTypes.SET_CATEGORIA,
    categorias
  }
};

export function setProductosAP (productos_ap) {
  return {
    type: actionTypes.SET_PRODUCTOS_AP,
    productos_ap
  }
};

export function setCategoriasAP (categorias_ap) {
  return {
    type: actionTypes.SET_CATEGORIAS_AP,
    categorias_ap
  }
};

export function setFamiliasAP (familias_ap) {
  return {
    type: actionTypes.SET_FAMILIA_AP,
    familias_ap
  }
};

export function setProducto (producto) {
  return {
    type: actionTypes.SET_PRODUCTO,
    producto
  }
};

export const selectProducto = (producto) => (dispatch) => {
  dispatch(setProducto(producto));
}

export const getCatalogo = () => (dispatch) => {
  api.get('productos/productos/catalogo_general').then(productos => {
    dispatch(setCatalogo(productos))
  })
    .catch(() => {
    }).finally(() => {})
}

export const getProductosAP = () => (dispatch) => {
  api.get('productos/productos/productos_ap').then(productos_ap => {
    dispatch(setProductosAP(productos_ap))
  })
    .catch(() => {
      modalSuccessAny('error', 'Error al obtener las productos agropartner')
    }).finally(() => {})
}

export const getFamiliasAP = () => (dispatch) => {
  api.get('productos/productos/familias_ap').then(familias_ap => {
    dispatch(setFamiliasAP(familias_ap))
  }).catch(() => {
    modalSuccessAny('error', 'Eerror al obtener las familias agropartner')
  }).finally(() => {})
}

export const getCategoriasAP = () => (dispatch) => {
  api.get('productos/productos/categorias_ap').then(categorias_ap => {
    dispatch(setCategoriasAP(categorias_ap))
  })
    .catch(() => {
      modalSuccessAny('error', 'Error al obtener las categorias agropartner')
    }).finally(() => {})
}

export const importProductsAP = (data) => (dispatch) => {
  api.post('productos/productos/sync_ap', data).then(response => {
    dispatch(push('/productos'))
  })
    .catch(() => {
      modalSuccessAny('error', 'Error al importar productos agropartner')
    }).finally(() => {})
}

export const createProducto = (data) => (dispatch) => {
  api.post('productos/productos/create_admin', data).then(response => {
    dispatch(push('/productos'));
    modalSuccessAny('success', 'Se creo el producto correctamente' , '')
  })
    .catch((error) => {
      modalSuccessAny('error', 'Hubo un error al enviar el formulario' , '')
    }).finally(() => {})
}

export const updateProducto = (data) => (dispatch) =>{
  api.post('productos/productos/update_producto_local', data).then(response => {
    dispatch(push('/productos'));
    modalSuccessAny('success', 'Se actualizo el producto correctamente' , '')
  })
    .catch((error) => {
      modalSuccessAny('error', 'Hubo un error al enviar el formulario' , '')
    }).finally(() => {})
}

export const getFamilias = () => (dispatch) => {
  api.get('productos/familias/familias').then(familias => {
    dispatch(setFamilias(familias))
  }).catch(() => {
    modalSuccessAny('error', 'Hubo un error al obtener las familias')
  }).finally(() => {})
}

export const getCategorias = () => (dispatch) => {
  api.get('productos/categorias/categorias').then(categorias => {
    dispatch(setCategoria(categorias))
  }).catch(() => {
    modalSuccessAny('error', 'Hubo un error al obtener las familias')
  }).finally(() => {})
}


export const actions = {
  getCatalogo,
  getProductosAP,
  getCategoriasAP,
  importProductsAP,
  getFamiliasAP,
  getFamilias,
  getCategorias,
  createProducto,
  updateProducto,
}

export const reducers = {
  [actionTypes.SET_CATALOGO]: (state, { productos }) => {
    return {
      ...state,
      productos
    }
  },
  [actionTypes.SET_PRODUCTOS_AP]: (state, { productos_ap }) => {
    return {
      ...state,
      productos_ap
    }
  },
  [actionTypes.SET_CATEGORIAS_AP]: (state, { categorias_ap }) => {
    return {
      ...state,
      categorias_ap
    }
  },
  [actionTypes.SET_FAMILIA_AP]: (state, { familias_ap }) => {
    return {
      ...state,
      familias_ap
    }
  },
  [actionTypes.SET_PRODUCTO]: (state, { producto }) => {
    return {
      ...state,
      producto
    }
  },
  [actionTypes.SET_FAMILIA]: (state, { familias }) => {
    return {
      ...state,
      familias
    }
  },
  [actionTypes.SET_CATEGORIA]: (state, { categorias }) => {
    return {
      ...state,
      categorias
    }
  }
}

export const initialState = {
  loader: false,
  productos: [],
  productos_ap: [],
  categorias_ap: [],
  familias_ap: [],
  producto: [],
  categorias:[],
  familias: []

}

export default handleActions(reducers, initialState)
