import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Table, Modal } from 'antd'
import FeatureForm from '../Modal/FeatureForm'
import { actions } from '../../../../redux/modules/sitio/sitio'

const TableFeatures = (props) => {
  const [dataSource, setDataSource] = useState(props.sitio.features)
  const [value, setValue] = useState('')
  const [isModalVisible, setModal] = useState(false)
  const [Ok, setOk] = useState(false)
  const [Cancel, setCancel] = useState(false)
  const [imagen, setImagen] = useState(null)
  const [feature, setInfo] = useState([])

  const showModal = () => {
    setModal(true)
  }

  const handleOk = () => {
    setModal(false)
    setInfo([])
  }

  const handleCancel = () => {
    setModal(false)
    setInfo([])
  }

  const handleSend = (data) => {
    // props.vincularCuenta(data)
    handleOk()
  }

  const columns = [
    {
      title: 'Titulo',
      dataIndex: 'titulo',
      key: 'id',
      sorter: (a, b) => a.titulo.localeCompare(b.titulo),
      showSorterTooltip: false
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      sorter: (a, b) => a.descripcion.localeCompare(b.descripcion),
      showSorterTooltip: false,
      key: 'id'
    },
    {
      title: 'Icono',
      dataIndex: 'icon',
      sorter: (a, b) => a.icon.localeCompare(b.icon),
      showSorterTooltip: false,
      key: 'id',
      render: (record) => (
        <React.Fragment>
          <i className={record} />
        </React.Fragment>
      )
    },
    {
      title: '-',
      key: 'id',
      render: (record) => (
        <div className='d-flex justify-content-sm-around'>
          <React.Fragment>
            <button
              className='ps-btn' onClick={e => {
                setInfo(record),
                showModal()
              }}
            >
              Cambiar
            </button>
          </React.Fragment>
        </div>
      )
    }
  ]

  let is_valid = false
  { props.sitio.features.length > 0
    ? is_valid = true
    : false
  }

  return (
    <div>
      <section className='pl-0 pr-0'>
        <div className='container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {is_valid
            ? <div className='col-6 pl-0'>
              <div className='ps-form--search-simple'>
                <input
                  placeholder='Buscar...'
                  className='form-control'
                  value={value}
                  onChange={e => {
                    const currValue = e.target.value
                    setValue(currValue)
                    const filteredData = props.sitio.features.filter(entry =>
                      entry.titulo.toLowerCase().includes(currValue.toLowerCase())
                    )
                    setDataSource(filteredData)
                  }}
                />
                <button><i className='icon icon-magnifier' /></button>
              </div>
            </div>
            : null}
        </div>
      </section>
      <hr />
      {is_valid === true
        ? <Table dataSource={dataSource} columns={columns} className='table ps-table--market' />
        : <p>Aun no tienes caracteristicas agregadas...</p>}
      <Modal
        title=''
        width={800}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <button type='reset' className='ps-btn ps-btn' onClick={handleCancel}>Cancelar</button>
        ]}
      >
        <FeatureForm onSubmit={handleSend} feature={feature} initialValues={feature} />

      </Modal>
    </div>
  )
}

const ms2p = (state) => {
  return state
}

const md2p = { ...actions }

export default connect(ms2p, md2p)(TableFeatures)
