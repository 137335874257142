const validate = (values) => {
    const errors = {};

    if (!values.nombre) {
        errors.nombre = "Este campo es requerido";
    }
    return errors;
};

export default validate;
