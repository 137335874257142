import React, { useEffect, useState } from 'react'
import { Field, reduxForm, change } from 'redux-form'
import { renderImageUpload } from '../Utils/renderField/renderField'
import moment from 'moment'

const RetiroForm = (props) => {
  const { handleSubmit, change, title, button, setImagen, aprobado } = props

  const [error, SetError] = useState(false)

  const handleError = (parse) => {
    SetError(true),
    change('monto_transferido', parse)
  }

  const handleSuccess = (parse) => {
    SetError(false),
    change('monto_transferido', parse)
  }

  const handleRead = (e) => {
    const current = e.target.value
    const parse = Number(current.replace(/[^0-9-]+/g, ''))
    if (parse > props.solicitud.monto_solicitado) {
      handleError(parse)
    } else {
      SetError(false),
      handleSuccess(parse)
    }
  }

  useEffect(() => {
    change('id', props.solicitud.id),
    change('monto_transferido', props.solicitud.monto_solicitado)
  }, [], [])

  return (
    <div className='container'>
      <div className='container'>

        <h3 style={{ textAlign: 'center' }}>{title} #{props.solicitud.id} - {props.solicitud.vendedor}</h3>
        <hr />
      </div>
      <form onSubmit={handleSubmit}>
        <div className='container' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div>
            <h4 className='pb-10'>
              Fecha: {moment().format('-L')}
            </h4>
          </div>
        </div>

        <hr />
        <div>
          {aprobado
            ? <div className='form-group'>
              <label>Adjuntar deposito</label>
              <Field
                name='comprobante'
                setFile={setImagen}
                component={renderImageUpload}
              />
              </div>
            : <div>
              {props.solicitud.comprobante.length > 0
                ? props.solicitud.comprobante.map(comprobante => (
                  <div style={{ border: '2px' }}>
                  <a className='ps-btn' href={props.solicitud.comprobante} target='_blank'>Ver comprobante</a>
                </div>
                  ))

                : <p>No hay ningun comprobante de entrega adjunto.</p>}
              </div>}

          <hr />
          <ul>
            <li> <strong>Vendedor:</strong> {props.solicitud.tienda_nobre}</li>
            <li> <strong>Datos bancarios:</strong> {props.solicitud.banco} </li>
          </ul>
        </div>
        <div className='form-group col-8'>
          {/* Some Field Here! */}
        </div>
        {props.solicitud.status >= 40
          ? null
          : <div style={{ alignContent: 'center' }}>
            <br />
            <button type='submit' className='ps-btn' disabled={error === true}>{button}</button>
          </div>}
      </form>
    </div>
  )
}

export default reduxForm({
  form: 'pagar_solicitud', // a unique identifier for this form
  destroyOnUnmount: false // <------ preserve form data

})(RetiroForm)
