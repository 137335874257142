import React from "react";
import { connect } from "react-redux";
import { handleSubmit } from "../../../../../../redux/modules/notificaciones/notificaciones";
import DefaultHeader from "../../../../../components/layout/Header/DefaultHeader";
import SliderForm from "./SliderForm";
import { createSlider } from "../../.././../../../redux/modules/sitio/sitio";

const SliderNew = (props) => {
    const handleSubmit = (data) => {
        props.createSlider(data);
    };

    return (
        <div>
            <DefaultHeader title="Crear un nuevo slider" visible={true} />
            <div className="alert alert-info" role="alert">
                <ul>
                    <h3><i className='icon icon-bubble-alert'/> Informacion importante</h3>
                    <li>
                        Los slider son utilizados para mostrar imagenes en el feed del
                        marketplace de forma dinámica.
                    </li>
                    <li>
                        Cuando termines de crear el elemento deberás cargar
                        imagenes para poder mostrarlo en el feed para los visitantes de la tienda.
                    </li>
                    <li>
                        Un slider que no contenga imagenes no podrá ser
                        utilizado para mostrar hasta que le agregues almenos una
                        imagen.
                    </li>
                </ul>
            </div>
            <SliderForm onSubmit={handleSubmit} />
        </div>
    );
};

const md2p = { createSlider };

export default connect(null, md2p)(SliderNew);
