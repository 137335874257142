import React from 'react'

const FooterCopyright = () => {
  return (
    <div className='ps-copyright'>
      <p>
        <br />
      </p>
    </div>
  )
}

export default FooterCopyright
