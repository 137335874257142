import React from "react";
import { aprobarProducto, rechazarProducto } from "../../../../redux/modules/solicitudes/solicitudes";
import DefaultHeader from "../../layout/Header/DefaultHeader";
import { connect } from "react-redux";

const DetalleProducto = (props) => {
    console.log(props);
    const { closeModal } = props;

    const handleAprobar = (e, product) => {
        closeModal();
        const data = { id: product.id, action: "True" };
        props.aprobarProducto(data);
    };

    const handleRechazo = (e, product) => {
        closeModal();
        const data = { producto: product };
        props.rechazarProducto(data);
    }

    return (
        <div>
            <div
                className="container"
                style={{ display: "flex", justifyContent: "space-evenly" }}
            >
                <div className="">
                    <img
                        src={props.producto.imagen}
                        alt=""
                        style={{ width: "300px", height: "auto" }}
                    />
                </div>
                <div>
                    <DefaultHeader title={props.producto.vendedor} visible={true} />
                    <ul>
                        <li>Familia: {props.producto.descripcion.familia}</li>
                        <li>Categoría: {props.producto.descripcion.categoria}</li>
                        <li> Nombre comercial: {props.producto.nombre_comercial}</li>
                        <li>Marca: {props.producto.descripcion.marca}</li>


                        <li>Envase: {props.producto.envase}</li>
                        <li>
                            Ingrediente activo:{" "}
                            {props.producto.descripcion.sustancia_activa}
                        </li>
                        <li>Concentración: {
                            (props.producto.descripcion.concentracion).replace(/ /g, "").length > 0 ? props.producto.descripcion.concentracion : "No aplica"
                            }</li>
                        <li>
                            Tipo de formulación:{" "}
                            {props.producto.descripcion.tipo_formulacion}
                        </li>
                        <li>SKU: {props.producto.sku}</li>
                    </ul>
                </div>
            </div>
            <hr />
            <div
                className="container"
                style={{ display: "flex", justifyContent: "space-around" }}
            >
                <button className="ps-btn ps-btn--gray" onClick={(e) => {
                    handleRechazo(e, props.producto);
                }}>Rechazar</button>
                <button
                    className="ps-btn"
                    onClick={(e) => {
                        handleAprobar(e, props.producto);
                    }}
                >
                    Aprobar
                </button>
            </div>
        </div>
    );
};

const md2p = { aprobarProducto, rechazarProducto };

export default connect(null, md2p)(DetalleProducto);
