import React, { useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'
import { renderField } from '../../Utils/renderField/renderField'

const ModalRechazo = (props) => {
  const { handleSubmit, info, change } = props

  const motivos = [
    { id: 1, nombre: 'INFORMACION INCOMPLETA' },
    { id: 2, nombre: 'DATOS ERRONEOS EN LOS CAMPOS' },
    { id: 3, nombre: 'NO SE PUDO CONFIRMAR LA INFORMACION' }
  ]

  const opt_motivos = []

  { motivos.length > 0
    ? motivos.map((option) => (
        opt_motivos.push({ value: option.id, label: option.nombre })
      )
      )
    : null
  }

  useEffect(() => {
    change('id', props.info.id)
  }, [])

  return (
    <div className='container'>
      <div className='container'>

        <h3 style={{ textAlign: 'center' }}>Respuesta de rechazo</h3>
        <hr />
      </div>
      <form onSubmit={handleSubmit}>
        <div className='container' style={{ display: 'flex', justifyContent: 'space-evenly' }} />
        <hr />
        <div className='form-group col-12 pt-10'>
          <label htmlFor=''>Motivo de rechazo</label>
          <Field
            className='form-control'
            name='motivo'
            component={renderField}
            placeholder='Motivo de rechazo'
            required
          />
        </div>
        <button className='ps-btn' type='submit'>Enviar</button>
      </form>
    </div>
  )
}

export default reduxForm({
  form: 'rechazo_vendedor' // a unique identifier for this form

})(ModalRechazo)
