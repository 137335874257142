import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { renderField } from "../../../../Utils/renderField/renderField";
import validate from "./validate"

const SliderForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form className="form" onSubmit={handleSubmit}>
            <hr />
            <div className="ps-form__content">
                <figure className="ps-block--form-box">
                    <figcaption></figcaption>
                    <div className="ps-block__content">
                        <b>Por el momento solo es necesario que elijas un nombre para identificarlo</b>
                        <br/>
                        <div className="form-group mt-20">
                            <label>Nombre del slider:</label>
                            <Field
                                name="nombre"
                                component={renderField}
                                type="text"
                                placeholder="Nombre del slider"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <div>
                                <Link
                                    to="/sliders"
                                    className="ps-btn ps-btn--gray"
                                >
                                    Cancelar
                                </Link>
                            </div>
                            <button type="submit" className="ps-btn">
                                Crear
                            </button>
                        </div>
                    </div>
                </figure>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "slider_form",
    validate
})(SliderForm);
