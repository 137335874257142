import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderInitialField } from "../Utils/renderField/renderField";

const PlantillaForm = (props) => {
    const { handleSubmit, handleCancel } = props;

    return (
        <form className="form" onSubmit={handleSubmit}>
            <div className="ps-form__content">
                <figure className="ps-block--form-box">
                    <figcaption>
                        Cambiar nombre
                    </figcaption>
                    <div className="ps-block__content">
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <div className="form-group">
                                    <label>Nombre:</label>
                                    <Field
                                        name="nombre"
                                        component={renderInitialField}
                                        placeholder="Comisión fija"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            className="container"
                            style={{
                                display: "flex",
                                justifyContent: "space-around"
                            }}
                        >
                            <button
                                type="button"
                                className="ps-btn"
                                onClick={handleCancel}
                            >
                                Cancelar
                            </button>
                            <button type="submit" className="ps-btn">
                                Guardar Cambios
                            </button>
                        </div>
                    </div>
                </figure>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "plantilla_form", // a unique identifier for this form
    enableReinitialize: true
})(PlantillaForm);
