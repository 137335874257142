import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Table, Modal } from 'antd'
import BannerForm from '../Modal/BannerForm'
import { actions } from '../../../../redux/modules/sitio/sitio'

const TableBanners = (props) => {
  const [dataSource, setDataSource] = useState(props.sitio.banners)
  const [value, setValue] = useState('')
  const [isModalVisible, setModal] = useState(false)
  const [Ok, setOk] = useState(false)
  const [Cancel, setCancel] = useState(false)
  const [imagen, setImagen] = useState(null)
  const [banner, setInfo] = useState([])

  const setBanner = (imagen) => {
    setImagen(imagen)
  }

  const showModal = () => {
    setModal(true)
  }

  const handleOk = () => {
    setModal(false)
    setInfo([])
  }

  const handleCancel = () => {
    setModal(false)
    setInfo([])
  }

  const handleSend = (data) => {
    const banner_img = { name: 'imagen', file: imagen }
    data.id = banner.id
    data.imagen = banner_img
    props.updateBanner(data)
    handleOk()
  }

  const columns = [
    {
      title: 'Descripción del banner',
      dataIndex: 'nombre',
      key: 'nombre',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      showSorterTooltip: false
    },
    {
      title: 'Identificador',
      dataIndex: 'slug',
      sorter: (a, b) => a.slug.localeCompare(b.slug),
      showSorterTooltip: false,
      key: 'slug'
    },
    {
      title: '-',
      key: 'id',
      render: (record) => (
        <div className='d-flex justify-content-sm-around'>
          <React.Fragment>
            <button
              className='ps-btn' onClick={e => {
                setInfo(record),
                showModal()
              }}
            >
              Cambiar
            </button>
          </React.Fragment>
        </div>
      )
    }
  ]

  let is_valid = false
  { props.sitio.banners.length > 0
    ? is_valid = true
    : false
  }

  return (
    <div>
      <section className='pl-0 pr-0'>
        <div className='container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {is_valid
            ? <div className='col-6 pl-0'>
              <div className='ps-form--search-simple'>
                <input
                  placeholder='Buscar...'
                  className='form-control'
                  value={value}
                  onChange={e => {
                    const currValue = e.target.value
                    setValue(currValue)
                    const filteredData = props.sitio.banners.filter(entry =>
                      entry.nombre.toLowerCase().includes(currValue.toLowerCase())
                    )
                    setDataSource(filteredData)
                  }}
                />
                <button><i className='icon icon-magnifier' /></button>
              </div>
            </div>
            : null}
        </div>
      </section>
      <hr />
      {is_valid === true
        ? <Table dataSource={dataSource} columns={columns} className='table ps-table--market' />
        : <p>Aun no tienes cuentas agregadas...</p>}
      <Modal
        title=''
        width={800}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <button type='reset' className='ps-btn ps-btn' onClick={handleCancel}>Cancelar</button>
        ]}
      >
        <BannerForm onSubmit={handleSend} setBanner={setBanner} banner={banner} />

      </Modal>
    </div>
  )
}

const ms2p = (state) => {
  return state
}

const md2p = { ...actions }

export default connect(ms2p, md2p)(TableBanners)
