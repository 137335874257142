import { handleActions } from "redux-actions";
import { api } from "api";
import { NotificationManager } from "react-notifications";
import Swal from 'sweetalert2';
import { getAllCategorias } from "../categorias/categorias";

const actionTypes = {
    SET_FAMILIAS: "SET_FAMILIAS",
    SET_ALL_FAMILIAS: "SET_ALL_FAMILIAS"
};

export function setFamilias(familias) {
    return {
        type: actionTypes.SET_FAMILIAS,
        familias
    };
}

export function setAllFamilias(all_familias) {
    return {
        type: actionTypes.SET_ALL_FAMILIAS,
        all_familias
    };
}

export const getFamilias = (params) => (dispatch) => {
    api.get("productos/familias/" + params)
        .then((familias) => {
            dispatch(setFamilias(familias));
        })
        .catch((err) => console.log(err));
};

export const getAllFamilias = () => (dispatch) => {
    api.get("productos/familias/all")
        .then((all_familias) => {
            dispatch(setAllFamilias(all_familias));
        })
        .catch((err) => console.log(err));
};

export const postFamilia = (data) => (dispatch) => {
    api.post("productos/familias", data)
        .then(() => {
            NotificationManager.success(
                "Satisfactorio",
                "Familia agregada satisfactoriamente."
            );
        })
        .catch((err) => {
            let message = "Insatisfactorio";
            let description = err.message;
            NotificationManager.error(message, description);
        });
};

export const updateFamilia = (data) => (dispatch) => {
    console.log(data)
  api.put("productos/familias/" + data.id, data)
      .then(() => {
          NotificationManager.info(
              "Satisfactorio",
              "Familia actualizada satisfactoriamente."
          );
      })
      .catch((err) => {
          let message = "Hubo un error al enviar los datos";
          let description = "Intenta mas tarde";
          NotificationManager.error(message, description);
      });
};

export const deleteFamilia = (id) => (dispatch) => {
    Swal.fire({
        title: '¿Eliminar?',
        text: '¡No podrá revertir esta acción!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: '¡Sí, eliminar!',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            api.eliminar(`productos/familias/${id}`)
            .then(() => {
                dispatch(getFamilias("/?page=1&limit=10"))
                dispatch(getAllCategorias(`/?page=1&limit=10`))
                NotificationManager.success(
                    "Satisfactorio",
                    "Familia eliminada satisfactoriamente."
                );
            })
            .catch((err) => {
                let message = "Hubo un error al enviar los datos";
                let description = "Intenta mas tarde";
                NotificationManager.error(message, description);
            });
        }
    });
};

export const actions = {
    getFamilias,
    postFamilia,
    updateFamilia,
    getAllFamilias,
    deleteFamilia,
};

export const reducers = {
    [actionTypes.SET_FAMILIAS]: (state, { familias }) => {
        return {
            ...state,
            familias
        };
    },
    [actionTypes.SET_ALL_FAMILIAS]: (state, { all_familias }) => {
        return {
            ...state,
            all_familias
        };
    }
};

export const initialState = {
    familias: {},
    all_familias: []
};

export default handleActions(reducers, initialState);
