import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect} from 'react-router-dom';
import LoginForm from './LoginForm';
import './Login.scss';
import LoadMask from "Utils/LoadMask/LoadMask";
import FooterCopyright from '../../shared/footer/FooterCopyright';
import FooterWidgets from '../../shared/footer/FooterWidgets';

class Login extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    render() {
        const { onSubmit, loader } = this.props;
        if (localStorage.getItem('token')) {
            return (<Redirect to="/" />);
        }
        return (
            <React.Fragment>
                <div className="register-photo">

                    <div className="form-container">
                        {/* CAMBIAR ESTE LINK EN PRODUCION */}
                        <a href='https://test127.ciancoders.com/' className="image-holder" id="register-login"></a>
                            <LoadMask loading={loader} light>
                                <LoginForm onSubmit={onSubmit} />
                            </LoadMask>
                    </div>
                </div>
                <footer className="ps-footer">
                <div className="ps-container">
                        <FooterWidgets />
                        <FooterCopyright />
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default Login;
