import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import {
    renderupdateField,
    renderField,
    SelectField
} from "../Utils/renderField/renderField";

const CategoriaForm = (props) => {
    const {
        handleSubmit,
        handleCancel,
        change,
        feature,
        titulo,
        all_familias,
        editar
    } = props;

    const handleSave= (e) =>{
        e.preventDefault();
        if (feature != null) {
            const data = {
                id: feature.id,
                nombre: e.target["nombre"].value,
                familia: feature.familia
            }
          e.target.nombre.value = ""
          props.onSubmit(data)
          }
        if(e.target["nombre"].value){
          const data = {
            nombre: e.target["nombre"].value,
            familia: e.target["familia"].value
          }
          e.target.nombre.value = ""
          props.onSubmit(data)
        }
      }

    useEffect(
        () => {
            change("nombre", !feature ? "" : feature.nombre),
                change("familia", !feature ? "" : feature.familia.id);
        },
        [],
        []
    );

    return (
        <div>
            <div className="container">
                <h4 style={{ textAlign: "center" }}>{titulo}</h4>
                <form onSubmit={handleSave}>
                    <div
                        className="container"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column"
                        }}
                    >

                        {!editar ? (
                            <div className="form-group col-8 pt-10">
                                <label>
                                    Familia:{" "}
                                    {feature ? feature.familia.id : null}
                                </label>
                                <Field
                                    name="familia"
                                    auxiliar_key="id"
                                    options={all_familias}
                                    value={feature ? feature.familia.id : null}
                                    placeholder={"Familia"}
                                    component={SelectField}
                                />
                            </div>
                        ) : null}
                        <div className="form-group col-8 pt-10">
                            <label>Nombre:</label>
                            <Field
                                    name="nombre"
                                    component={
                                        !feature ? renderField : renderupdateField
                                    }
                            />
                        </div>
                    </div>

                    <div
                        className="container mt-20 pt-20"
                        style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                    >
                        <button
                            type="button"
                            onClick={handleCancel}
                            className="ps-btn cancel--button"
                        >
                            Cancel
                        </button>
                        <button type="submit" className="ps-btn btn-success">
                            Guardar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default reduxForm({
    form: "categoria_form_1", // a unique identifier for this form
    enableReinitialize: true
})(CategoriaForm);
