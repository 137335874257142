import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Table, Modal } from 'antd'
import { actions } from '../../../../../redux/modules/sitio/sitio'
import Swal from 'sweetalert2';

import FeatureForm from './FeatureForm'

const TableFeatures = (props) => {
  const { deleteFeatures } = props

  const [value, setValue] = useState('')
  const [dataSource, setDataSource] = useState(props.info)
  const [dataSourceFilter, setDataSourceFilter] = useState(dataSource)
  const [isModalVisible, setModal] = useState(false)
  const [feature, setInfo] = useState([])
  const [Ok, setOk] = useState(false)
  const [Cancel, setCancel] = useState(false)
  const [isCreate, setIsCreate] = useState(false)

  useEffect(() => setDataSource(props.info), props.info)

  const showModal = () => {
    setModal(true)
  }

  const handleSetImagen = (imagen) => {
    setImagen(imagen)
  }

  const handleOk = () => {
    setOk(false)
    setModal(false)
    setInfo([])
  }

  const handleCancel = () => {
    setCancel(false)
    setModal(false)
    setInfo([])
  }

  const handleSubmit = (data) => {
    if (!isCreate) {
      data.id = feature.id
      props.updateFeatures(data)
    }
    else {
      props.createFeatures(data)
    }
    handleCancel()
  }

  const columns = [
    {
      title: 'Titulo',
      dataIndex: 'titulo',
      key: 'titulo'
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion'
    },
    {
      title: 'Icono',
      render: (record) => (
        <React.Fragment>
          <i className={record.icon} />
        </React.Fragment>
      )
    },
    {
      title: 'Acciones',
      render: (record) => (
        <React.Fragment>
          <button
            className='ps-btn' onClick={e => {
              setInfo(record),
              showModal()
            }}
          >
            Ver
          </button>
          <button
            style={{ backgroundColor: "red", color: "white", marginLeft: 5, paddingLeft: 12, paddingRight: 12 }}
            className='ps-btn' onClick={e => {
              Swal.fire({
                title: '¿Eliminar?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true,
              }).then((result) => {
                  if (result.value) {
                    deleteFeatures(record.id)
                  }
              });
            }}
          >
            -
          </button>
        </React.Fragment>
      )
    },
  ]

  return (
    <div>
      <div className='form-group'>
        {props.styled
          ? <React.Fragment>
            <section className='ps-dashboard' style={{ display: 'flex', justifyItems: 'flex-end', justifyContent:'space-between'}}>
              <div style={{ display: 'flex', alignItems: 'flex-end'}}>
                <div style={{ marginLeft: 5,}}>
                  <Link
                    to='/sitio'
                    className='ps-btn'
                    style={{backgroundColor: "#2e3d50", color: "white"}}
                  >
                      Regresar
                  </Link>
                </div>
                <div style={{ marginLeft: 5 }}>
                  <button
                    style={{backgroundColor: "#2e3d50", color: "white"}}
                    className='ps-btn'
                    onClick={() => {
                      setInfo({
                        titulo: '', descripcion: '', icon: '',
                      })
                      showModal()
                    }}
                  >
                    Agregar
                  </button>
                </div>
              </div>
              <div className='' style={{display: 'flex',justifyContent: 'center', flexDirection: 'column' , alignItems: 'flex-end'}}>
                <label htmlFor='estados' style={{margin: '0', padding: '0', display: 'flex',justifyContent: 'flex-end', gap:"0.5rem"}}>Filtrar:</label>
                <div className='col-12 col-sm-8 pl-0'style={{margin: '0', padding: '0', display: 'flex',justifyContent: 'flex-end', gap:"0.5rem"}}>
                  <div className='ps-form--search-simple'>
                    <input
                    placeholder='Buscar...'
                    className='form-control'
                    value={value}
                    onChange={e => {
                        const currValue = e.target.value
                        setValue(currValue)
                        const filteredData = dataSource.filter(entry =>
                          entry.titulo.toLowerCase().includes(currValue.toLowerCase())
                        );
                        setDataSourceFilter(filteredData)
                      }}
                  >
                  </input>
                    <button><i className='icon icon-magnifier' /></button>
                  </div>
                </div>
              </div>
            </section>

            <hr />
            <Table dataSource={dataSourceFilter} columns={columns} className='table ps-table--market' />
            </React.Fragment>
          : <Table dataSource={dataSourceFilter} columns={columns} />}
      </div>
      <Modal
        title=''
        width={800}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <FeatureForm feature={feature} onSubmit={handleSubmit} initialValues={feature} setIsCreate={setIsCreate} />
      </Modal>
    </div>

  )
}

const md2p = { ...actions }

export default connect(null, md2p)(TableFeatures)
