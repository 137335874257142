import React from "react";
import { Field, reduxForm, reset,  formValueSelector } from "redux-form";
import { connect } from 'react-redux';
import validate from "./validate";
import {
    renderupdateField,
    SelectField
} from "../Utils/renderField/renderField";
import { Link } from "react-router-dom";
import productos from "../../../redux/modules/productos/productos";

let ProductoForm = (props) => {
    const { onSubmit,handlerClose, setFile, valid, imagen, categorias, familias, producto,
        selected_familia } =
        props;

        const unidades_medidas = [
            { value: "500", label: "cc" },
            { value: "200", label: "grs" },
            { value: "100", label: "kg" },
            { value: "300", label: "lt" },
            { value: "800", label: "mg" },
            { value: "600", label: "mk" },
            { value: "400", label: "ml" },
            { value: "700", label: "und" },

        ];

    const opt_categorias = [];
    const opt_familia = [];

    console.log(selected_familia, 'SELECCIONADO')

    {
      categorias.length > 0
        ? categorias.filter(cat => cat.familia.id === selected_familia).map((categoria) =>
            opt_categorias.push({
              label: categoria.nombre,
              value: categoria.id,
            })
          )
        : null;
    }

    {
      familias.length > 0
        ? familias.map((familia) =>
            opt_familia.push({
              label: familia.nombre,
              value: familia.id,
            })
          )
        : null;
    }
    // {
    //     categorias.length > 0
    //         ? categorias.map((categoria) =>
    //               opt_categorias.push({
    //                   label: categoria.nombre,
    //                   value: categoria.id
    //               })
    //           )
    //         : null;
    // }

    // {
    //     familias.length > 0
    //         ? familias.map((familia) =>
    //               opt_familia.push({
    //                   label: familia.nombre,
    //                   value: familia.id
    //               })
    //           )
    //         : null;
    // }

    const handleUpdate = (e) =>{

        e.preventDefault()
        try {
            const dataNew = {
                familia:e.target["familia"].value,
                categoria:e.target["categoria"].value,
                nombre_comercial:e.target["nombre_comercial"].value,
                marca:e.target["marca"].value,
                tipo_formulacion:e.target["tipo_formulacion"].value,
                sustancia_activa:e.target["sustancia_activa"].value,
                concentracion_sustancia_activa:e.target["concentracion_sustancia_activa"].value,
                envase:e.target["envase"].value,
                unidad_medida:e.target["unidad_medida"].value,
            }
            onSubmit(producto.id,dataNew)
        } catch (error) {
            console.log("Ocurrio un error al enviar el Formulario.")
        }
    }
    return (
        <form className="form" name="producto_form" onSubmit={handleUpdate}>
            <hr />
            <div className="alert alert-info" role="alert">
                <ul>
                    <h4><i className='icon icon-bubble-alert'/> Actualizar producto</h4>

                </ul>
            </div>
            <div className="ps-form__content">
                <p style={{ marginLeft: "3px" }}>
                    Campos requeridos: <small style={{ color: "red" }}>*</small>
                </p>
                <figure className="ps-block--form-box">
                    <figcaption>Editar información del producto</figcaption>
                    <div className="ps-block__content">

                    <div className="form-group">
                            <label>Familia: <small style={{ color: "red" }}>*</small></label>
                            <Field
                                className="form-control"
                                name="familia"
                                options={opt_familia}
                                component={SelectField}
                                placeholder="Familia"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Categorías: <small style={{ color: "red" }}>*</small></label>
                            <Field
                                className="form-control"
                                name="categoria"
                                options={opt_categorias}
                                component={SelectField}
                                placeholder="Categoría"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Marca: <small style={{ color: "red" }}>*</small></label>
                            <Field
                                name="marca"
                                component={renderupdateField}
                                type="text"
                                placeholder="Marca"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Nombre del producto: <small style={{ color: "red" }}>*</small></label>
                            <Field
                                name="nombre_comercial"
                                component={renderupdateField}
                                type="text"
                                placeholder="Nombre del producto"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Tipo de formulación:</label>
                            <Field
                                name="tipo_formulacion"
                                component={renderupdateField}
                                type="text"
                                placeholder="Fomulacion"
                            />
                        </div>
                        <div className="form-group">
                            <label>Ingrediente Activo:</label>
                            <Field
                                name="sustancia_activa"
                                component={renderupdateField}
                                type="text"
                                placeholder="Ingrediente Activo"
                            />
                        </div>

                        <div className="form-group">
                            <label>Concentración:</label>
                            <Field
                                name="concentracion_sustancia_activa"
                                component={renderupdateField}
                                type="text"
                                placeholder="Concentración"
                            />
                        </div>

                        <div className="form-group">
                            <label>Envase: <small style={{ color: "red" }}>*</small></label>
                            <Field
                                name="envase"
                                component={renderupdateField}
                                type="number"
                                max={9999.99}
                                placeholder="Envase"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Unidad de medida: <small style={{ color: "red" }}>*</small></label>
                            <Field
                                className="form-control"
                                name="unidad_medida"
                                options={unidades_medidas}
                                component={SelectField}
                                placeholder="Unidad de medida"
                                required
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >

                            <div
                                className="ps-btn ps-btn--gray"
                                onClick={handlerClose}
                            >
                                Cancelar
                            </div>

                            <button
                              className={
                                !valid
                                  ? 'ps-btn'
                                  : 'ps-btn'
                              }
                              style={{
                                  backgroundColor: !valid ? '#ccc' : '',
                                  cursor:!valid ? 'not-allowed' : '',
                                  color: !valid ? '#000' : '' }}
                              type='submit'
                              disabled={!valid}
                              >
                              Guardar
                            </button>
                        </div>
                    </div>
                </figure>
            </div>
        </form>
    );
};

ProductoForm = reduxForm({
    form: "producto_form", // a unique identifier for this form
    enableReinitialize: true,
    validate,
    onSubmitSuccess: (result, dispatch) => dispatch(reset("producto_form")),
})(ProductoForm);

const selector = formValueSelector('producto_form')
ProductoForm = connect(state => {
  const selected_familia = selector(state, 'familia')
  return {
    selected_familia,
  }
})(ProductoForm)

export default ProductoForm
