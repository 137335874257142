import React from "react";
import { Field, reduxForm } from "redux-form";

import { Link } from "react-router-dom";
import { renderInitialField } from "../../Utils/renderField/renderField";

const CotizacionesForm = (props) => {
    const { handleSubmit, handleCancel, titulo } = props;

    return (
        <form className="form" onSubmit={handleSubmit}>
            <div className="ps-form__content">
                <figure className="ps-block--form-box">
                    <figcaption>
                        Fees & Comisiones Categoría: {titulo}
                    </figcaption>
                    <div className="ps-block__content">
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <div className="form-group">
                                    <label>Comisión fija (CPL + IVA):</label>
                                    <Field
                                        name="comision_f"
                                        component={renderInitialField}
                                        placeholder="Comisión fija"
                                    />
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <div className="form-group">
                                    <label>Comisión variable: (% + IVA)</label>
                                    <Field
                                        name="comision_v"
                                        component={renderInitialField}
                                        placeholder="Comisión variable"
                                    />
                                </div>
                            </div>
                        </div>

                        <div
                            className="container"
                            style={{
                                display: "flex",
                                justifyContent: "space-around"
                            }}
                        >
                            <button
                                type="button"
                                className="ps-btn"
                                onClick={handleCancel}
                            >
                                Cancelar
                            </button>
                            <button type="submit" className="ps-btn">
                                Guardar Cambios
                            </button>
                        </div>
                    </div>
                </figure>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "site_settings_cot", // a unique identifier for this form
    enableReinitialize: true
})(CotizacionesForm);
