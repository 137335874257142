import React from "react";
import "../../../../style/scss/custom/FamiliaModal.scss";

const FamiliaModal = ({ handleClose, show, children }) => {
    const showHideClassName = show
        ? "modal display-block"
        : "modal display-none";

    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                <div className="container-fluid container--margin">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                            <button type="button" className="icon--button" onClick={handleClose}>
                                <i className="icon-cross"></i>
                            </button>
                        </div>
                    </div>
                    {children}
                </div>
            </section>
        </div>
    );
};

export default FamiliaModal;
