import React, { Component } from 'react';
import DefaultHeader from '../../../layout/Header/DefaultHeader'
import { connect } from "react-redux";
import {actions} from '../../../../../redux/modules/sitio/sitio'
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import TableBanner from "./TableBanner"

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            isModalRequested: false,
            isModalPaid:false,
            loader: true,
            create: false,
        }
    }

    componentDidMount(){
        this.handleLoader()
        this.props.getBanners()
    }

    showModal = () => {
        this.setState({isModalVisible:true})
      };
        
    handleOk = () => {
        this.setState({isModalVisible:false})
      };
    
       handleCancel = () => {
        this.setState({isModalVisible:false})
      };

      handleLoader = () => {
        setTimeout(()=> this.setState({loader:false}),1000);
    }

    handleCreate = () => {
        this.setState({create:true})
    }

render() {

    const {banners} = this.props.sitio
    const {me} = this.props.login
    return (
    <div>
    <DefaultHeader title="Banner" visible={true}/>
    {this.state.loader?
    <LoadMask loading={this.state.loader} dark>
         
    </LoadMask>
    :
    <div>
    {banners?
    <div className="container">

        <div className="ps-card">
        {banners.length>0 ?
        <div>
          <hr/>
        <TableBanner info={banners}  styled={true}/>
        </div>
        :
        <div>
            <hr/>
            <p>No hay banners agregados en este momento</p>
        </div>
        }

        </div>
    </div>
    :null}


</div>
}
</div>

        );
    }
}


const ms2p = (state) => {
    return state;
  };

const md2p = {...actions}
  
export default connect(ms2p ,md2p)(Banner);
