import React, { useState, useEffect } from "react";
import { api } from "../../../utility/api";
import { connect } from "react-redux";
import DefaultHeader from "../layout/Header/DefaultHeader";
import ProductoForm from "./ProductoForm";
import { createProducto } from "../../../redux/modules/productos/productos";

const NuevoProducto = (props) => {
    const [familias, setFamilias] = useState([]);
    const [categorias, setCategorias] = useState([]);

    const handleSend = (data) => {
        props.createProducto(data)
    };


    const getCategorias = async () => {
        const response = await api
            .get(`/productos/categorias/all/`)
            .then((categorias) => {
                setCategorias(categorias);
            });
    };

    const getFamilias = async () => {
        const response = await api
            .get(`/productos/familias/all/`)
            .then((familias) => {
                setFamilias(familias);
            });
    };

    useEffect(() => {
        getCategorias();
        getFamilias();
    }, []);

    return (
        <div>
            <DefaultHeader
                title={`Nuevo producto`}
                visible={true}
            />
            <ProductoForm
                onSubmit={handleSend}
                categorias={categorias}
                familias={familias}
            />
        </div>
    );
};

const md2p = {createProducto };

export default connect(null, md2p)(NuevoProducto);
