import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderInitialField } from "../../Utils/renderField/renderField";

const IvaForm = (props) => {
    const { handleSubmit } = props;

    return (
        <div className="container-fluid">
            <form className="form" onSubmit={handleSubmit}>
                <div className="ps-form__content">
                    <figure
                        className="ps-block--form-box"
                        style={{ marginBottom: "5px" }}
                    >
                        <figcaption style={{ color: "white" }}>
                            IVA general
                        </figcaption>
                        <div className="ps-block__content">
                            <div className="row">
                                <div className="col-xs-6 col-md-6">
                                    <div className="input-group">
                                        <Field
                                            name="valor_numerico"
                                            component={renderInitialField}
                                            placeholder="IVA general"
                                        />
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                %
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-md-6">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end"
                                        }}
                                    >
                                        <button
                                            type="submit"
                                            className="ps-btn"
                                        >
                                            Actualizar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </figure>
                </div>
            </form>
        </div>
    );
};

export default reduxForm({
    form: "iva_form", // a unique identifier for this form
    enableReinitialize: true
})(IvaForm);
