import React, { Component } from "react";
import DefaultHeader from "../layout/Header/DefaultHeader";
import { connect } from "react-redux";
import { actions } from "../../../redux/modules/usuarios/usuarios";
import { getVendedores } from "../../../redux/modules/solicitudes/solicitudes";
import LoadMask from "../Utils/LoadMask/LoadMask";
import TableVendedor from "./TableVendedor";
import { Link } from "react-router-dom";

class Vendedores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            isModalRequested: false,
            isModalPaid: false,
            loader: true
        };
    }

    componentDidMount() {
        this.handleLoader();
        this.props.getVendedoresAll();
        this.props.getVendedores();
    }

    showModal = () => {
        this.setState({ isModalVisible: true });
    };

    showModalRequested = () => {
        this.setState({ isModalRequested: true });
    };

    showModalPaid = () => {
        this.setState({ isModalPaid: true });
    };

    handleOk = () => {
        this.setState({ isModalVisible: false });
        this.setState({ isModalRequested: false });
        this.setState({ isModalPaid: false });
    };

    handleCancel = () => {
        this.setState({ isModalVisible: false });
        this.setState({ isModalRequested: false });
        this.setState({ isModalPaid: false });
    };

    handleSend = (data) => {
        this.props.onSubmitSolicitud(data);
    };

    handleLoader = () => {
        setTimeout(() => this.setState({ loader: false }), 1000);
    };

    render() {
        var solicitudes;
        {
            this.props.solicitudes.vendedores.length > 0
                ? (solicitudes = this.props.solicitudes.vendedores.length)
                : (solicitudes = 0);
        }

        return (
            <div>
                <DefaultHeader title="Vendedores" visible={true} />
                {this.state.loader ? (
                    <LoadMask loading={this.state.loader} dark></LoadMask>
                ) : (
                    <div>
                        {this.props.usuarios.vendedores ? (
                            <div className="container">
                                <div className="ps-card">
                                    {this.props.usuarios.vendedores.length >
                                    0 ? (
                                        <div>
                                            <TableVendedor
                                                info={
                                                    this.props.usuarios
                                                        .vendedores
                                                }
                                                solicitudes={solicitudes}
                                                styled={true}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <Link
                                                to="/vendedores-solicitudes"
                                                className="ps-btn"
                                            >
                                                solicitudes de aprobación (
                                                {solicitudes})
                                            </Link>
                                            <hr />
                                            <p>
                                                En este momento no hay
                                                vendedores de aprobados
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        );
    }
}

const ms2p = (state) => {
    return state;
};

const md2p = { ...actions, getVendedores };

export default connect(ms2p, md2p)(Vendedores);
