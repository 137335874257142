import React from "react";

const DesactivarUsuario = (props) => {
    return (
        <div className="container" style={{ height: "150px"}}>
            <div className="pb-50 mb-50">
                <h4 style={{ textAlign: "center" }}>
                    ¿Está seguro que desea eliminar este usuario?
                </h4>
                <p style={{ textAlign: "center" }}>Toma en cuenta que esto eliminará de catálogo los productos y usuarios (vendedores) que pertenezcan a esta tienda.</p>
                <p style={{ textAlign: "center" }}>
                    {" "}
                    <b>Nota:</b> Esta acción no se puede deshacer.
                </p>
            </div>
        </div>
    );
};

export default DesactivarUsuario;
