import React from 'react'

/* FUNCIONALIDAD PARA ICONOS DE FEATURES */
const icons = [
    "icon-calendar-full",
    "icon-leaf",
    "icon-store",
    "icon-credit-card",
    "icon-cart",
]
export const icons_options = icons.map(icon => {
    return {
        label:  <React.Fragment>
                    <i className={icon} /> {icon}
                </React.Fragment>,
        value: icon,
    }
})