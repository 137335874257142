import React, { useState } from 'react'
import { aprobarVendedor, rechazarVendedor } from '../../../../redux/modules/solicitudes/solicitudes'

import { Modal } from 'antd'
import { connect } from 'react-redux'
import ModalRechazo from './ModalRechazo'

const DetalleUsuario = (props) => {
  const [is_true, setModal2] = useState(false)

  const openModal = () => {
    setModal2(true)
  }

  const handle_cancel = () => {
    setModal2(false)
  }

  const handle_ok = () => {
    setModal2(false)
  }

  const handleAprobar = (data) => {
    props.aprobarVendedor(data)
  }

  const handleRechazar = (data) => {
    props.rechazarVendedor(data)
  }

  return (
    <div>
      <div className='container' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <div className=''>
          <img src={props.usuario.tienda_logo} alt='' style={{ width: '300px', height: 'auto' }} />
        </div>
      </div>
      <hr />
      <table className='table ps-table--solicitud'>
        <thead>
          <tr>
            <th>DETALLE VENDEDOR</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><b>Nombre de la tienda:</b> {props.usuario.empresa_nombre}</td>
          </tr>
          <tr>
            <td> <b>Dirección:</b> {props.usuario.empresa_direccion}</td>
          </tr>
          <tr>
            <td> <b>Telefono:</b> {props.usuario.empresa_telefono}</td>
          </tr>
          <tr>
            <td> <b>Razón social:</b> {props.usuario.empresa_razon}.</td>
          </tr>
          <tr>
            <td> <b>Giro:</b> {props.usuario.empresa_giro}. </td>
          </tr>
          <tr>
            <td> <b>Descripción:</b> {props.usuario.tienda_descripcion}.</td>
          </tr>
        </tbody>
      </table>
      <table className='table ps-table--solicitud'>
        <thead>
          <tr>
            <th>INFORMACIóN DE CONTACTO</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> <b>Representante:</b> {props.usuario.representante_nombre} {props.usuario.representante_primer_apellido} {props.usuario.representante_segundo_apellido}</td>
          </tr>
          <tr>
            <td> <b>Cargo</b>: {props.usuario.representante_cargo}</td>
          </tr>
          <tr>
            <td> <b>Correo electronico:</b> {props.usuario.representante_email}</td>
          </tr>
          <tr>
            <td> <b>Contacto operacional:</b> {props.usuario.representante_contacto}</td>
          </tr>
          <tr>
            <td> <b>Correo:</b> {props.usuario.representante_email_operacional} </td>
          </tr>
          <tr>
            <td> <b>Contacto administrativo:</b> {props.usuario.representante_contacto_admin} </td>
          </tr>
          <tr>
            <td> <b>Correo administrativo:</b> {props.usuario.representante_email_admin} </td>
          </tr>
        </tbody>
      </table>

      <table className='table ps-table--solicitud'>
        <thead>
          <tr>
            <th>DATOS DE FACTURACIÓN</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> <b>Nombre de empresa:</b> {props.usuario.fact_nombre_comercial}</td>
          </tr>
          <tr>
            <td> <b>Razón Social</b>: {props.usuario.fact_razon_social}</td>
          </tr>
          <tr>
            <td> <b>RUT:</b> {props.usuario.fact_rut}</td>
          </tr>
          <tr>
            <td> <b>Giro:</b> {props.usuario.fact_giro}</td>
          </tr>
          <tr>
            <td> <b>Dirección:</b> {props.usuario.fact_direccion} , {props.usuario.fact_comuna} - {props.usuario.fact_region} {props.usuario.fact_provincia}</td>
          </tr>
          <tr>
            <td> <b>Teléfono:</b> {props.usuario.fact_telefono} </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <div className='container' style={{ display: 'flex', justifyContent: 'space-around' }}>
        <button className='ps-btn ps-btn--gray' onClick={e => { openModal() }}>Rechazar</button>
        <button className='ps-btn' onClick={handleAprobar.bind(this, props.usuario)}>Aprobar</button>

      </div>
      <Modal
        width={800}
        title=''
        visible={is_true}
        onOk={handle_ok}
        onCancel={handle_cancel}
        footer=''
      >
        <ModalRechazo info={props.usuario} onSubmit={handleRechazar} />
      </Modal>
    </div>
  )
}

const md2p = { aprobarVendedor, rechazarVendedor }

export default connect(null, md2p)(DetalleUsuario)
