import React from 'react'
import { Field, reduxForm } from 'redux-form'
import validate, { password, passwordsMustMatch } from './validate'
import { renderField } from '../../Utils/renderField/renderField'
import { Link } from 'react-router-dom'

const AsociadoForm = (props) => {
  const { handleSubmit } = props

  return (
    <form className='form' onSubmit={handleSubmit}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Link to='asociados' className='ps-btn' style={{backgroundColor: "#2e3d50", color: "white"}}>
            Regresar
          </Link>
        </div>
      </div>
      <hr />
      <div className='ps-form__content'>
        <figure className='ps-block--form-box'>
          <figcaption>Crea un usuario</figcaption>
          <div className='ps-block__content'>
            <div className='form-group'>
              <label>Correo electrónico:</label>
              <Field
                name='email'
                type='text'
                component={renderField}
                placeholder='Correo electrónico'
              />
            </div>
            <div className='form-group'>
              <label>Nombre de usuario:</label>
              <Field
                name='username'
                type='text'
                component={renderField}
                placeholder='Usuario'
              />
            </div>
            <div className='form-group'>
              <label>Nombre(s):</label>
              <Field
                name='first_name'
                type='text'
                component={renderField}
                placeholder='Nombres'
              />
            </div>
            <div className='form-group'>
              <label>Apellidos(s):</label>
              <Field
                name='last_name'
                type='text'
                component={renderField}
                placeholder='Apellidos'
              />
            </div>
            <div className='form-group'>
              <label>Contraseña:</label>
              <Field
                name='password'
                type='password'
                component={renderField}
                validate={password}
                placeholder='Contraseña'
              />
            </div>
            <div className='form-group'>
              <label>Confirmación de contraseña</label>
              <Field
                name='password_confirmation'
                type='password'
                component={renderField}
                placeholder='Confirmación de contraseña'
                validate={[passwordsMustMatch]}
              />
            </div>
            <div>
              <button type='submit' className='ps-btn ps-btn--fullwidth'>
                Continuar
              </button>
            </div>
          </div>
        </figure>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'invitar_usuario',
  validate
})(AsociadoForm)
