import React, { Component } from "react";
import DefaultHeader from "../layout/Header/DefaultHeader";
import { connect } from "react-redux";
import { api } from "../../../utility/api";
import LoadMask from "../Utils/LoadMask/LoadMask";
import Card from "../Perfil/Card";
import moment from "moment";
import { getDashboard } from "../../../redux/modules/sitio/sitio";
import Paridad from './Paridad';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dashboard: [],
            now: moment().format("YYYY-MM-DD"),
            init_month: moment().format("YYYY-MM-01"),
            sin_enviar: "",
            ganadas: "",
            todas: "",
            loader: true,
            value: "",
            dataSource: [],
            desde: moment().clone().startOf("month").format("YYYY-MM-DD"),
            hasta: moment().format("YYYY-MM-DD")
        };
    }

    componentDidMount() {
        this.handleLoader();
        this.handleSetFilter();
    }

    handleLoader = () => {
        setTimeout(() => this.setState({ loader: false }), 1000);
    };

    handleSetFilter = () => {
        this.handleLoader();
        this.getFilteredDashboard();
    };

    getFilteredDashboard = async () => {
        const reponse = api
            .get(
                `config/dashboard/?inicial=${this.state.desde}&final=${this.state.hasta}`
            )
            .then((response) => {
                this.setState({ dashboard: response });
                this.setState({ loader: true });
            })
            .catch((error) => {
                {
                    error: JSON.stringify(error);
                }
                this.setState({ cotizaciones: [] });
            });
        return reponse;
    };

    render() {
        const data = this.state.dashboard;
        return (
            <div>
                <DefaultHeader title="Dashboard" visible={true} />
                {this.state.loader ? (
                    <LoadMask loading={this.state.loader} dark></LoadMask>
                ) : (
                    <div>
                        <div
                            className="container"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center"
                            }}
                        >
                            <div className="col-4">
                                <label htmlFor="estados">Desde:</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="fecha_i"
                                    value={this.state.desde}
                                    onChange={(e) => {
                                        const currValue = e.target.value;
                                        this.setState({ desde: currValue });
                                    }}
                                />
                            </div>
                            <div className="col-4">
                                <label htmlFor="estados">Hasta:</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    name="fecha_f"
                                    value={moment(this.state.hasta).format(
                                        "YYYY-MM-DD"
                                    )}
                                    onChange={(e) => {
                                        const currValue = e.target.value;
                                        this.setState({ hasta: currValue });
                                    }}
                                />
                            </div>
                            <div>
                                <button
                                    className="ps-btn xs"
                                    onClick={this.handleSetFilter}
                                    style={{marginTop: '15px'}}
                                >
                                    Filtrar
                                </button>
                            </div>
                        </div>
                            <Paridad />
                        <h4>Compradores</h4>
                        <hr />
                        <section className="ps-dashboard">
                            <div
                                className="container"
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-evenly"
                                }}
                            >
                                <div className="ps-block--form-box2">
                                    <Card
                                        title="Compradores"
                                        description={data.clientes}
                                        text_button="Ver"
                                        url="/slider-principal"
                                    />
                                </div>
                                <div className="ps-block--form-box2">
                                    <Card
                                        title="Compradores Activos"
                                        description={data.clientes_activos}
                                        text_button="Ver"
                                        url="/slider-principal"
                                    />
                                </div>

                                <div className="ps-block--form-box2">
                                    <Card
                                        title="Ticket promedio"
                                        description="-"
                                        text_button="Ver"
                                        url="/slider-principal"
                                    />
                                </div>
                            </div>
                        </section>
                        <h4>Tiendas</h4>
                        <hr />
                        <section className="ps-dashboard">
                            <div
                                className="container"
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-evenly"
                                }}
                            >
                                <div className="ps-block--form-box2">
                                    <Card
                                        title="Tiendas"
                                        description={data.vendedores}
                                        text_button="Ver"
                                        url="/slider-principal"
                                    />
                                </div>
                                <div className="ps-block--form-box2">
                                    <Card
                                        title="Productos (activos)"
                                        description={data.productos}
                                        text_button="Ver"
                                        url="/slider-principal"
                                    />
                                </div>
                                <div className="ps-block--form-box2">
                                    <Card
                                        title="Productos registrados"
                                        description={data.productos_totales}
                                        text_button="Ver"
                                        url="/slider-principal"
                                    />
                                </div>

                                <div className="ps-block--form-box2">
                                    <Card
                                        title="Venta total (CLP)"
                                        currency={true}
                                        description={data.ventas}
                                        text_button="Ver"
                                        url="/slider-principal"
                                    />
                                </div>
                                <div className="ps-block--form-box2">
                                    <Card
                                        title="Venta promedio (CLP)"
                                        currency={true}
                                        description={data.promedio}
                                        text_button="Ver"
                                        url="/slider-principal"
                                    />
                                </div>
                                <div className="ps-block--form-box2">
                                    <Card
                                        title="Comisiones (CLP)"
                                        currency={true}
                                        description={
                                            data.comisiones_fijas +
                                            data.comisiones_variable
                                        }
                                        text_button="Ver"
                                        url="/slider-principal"
                                    />
                                </div>
                                <div className="ps-block--form-box2">
                                    <Card
                                        title="SKU promedio"
                                        description={data.sku_promedio}
                                        text_button="Ver"
                                        url="/slider-principal"
                                    />
                                </div>
                            </div>
                        </section>

                        <h4>Pedidos</h4>
                        <hr />
                        <section className="ps-dashboard">
                            <div
                                className="container"
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-evenly"
                                }}
                            >
                                <div className="ps-block--form-box2">
                                    <Card
                                        title="Pedidos cotizados"
                                        description={data.cotizaciones_totales}
                                        text_button="Ver"
                                        url="/slider-principal"
                                    />
                                </div>
                                <div className="ps-block--form-box2">
                                    <Card
                                        title="Pedidos pagados"
                                        description={data.cotizaciones_pagadas}
                                        text_button="Ver"
                                        url="/slider-principal"
                                    />
                                </div>
                                <div className="ps-block--form-box2">
                                    <Card
                                        title="Pedidos entregados"
                                        description={data.entregadas}
                                        text_button="Ver"
                                        url="/slider-principal"
                                    />
                                </div>
                                <div className="ps-block--form-box2">
                                    <Card
                                        title="Pendientes de entregar"
                                        description={data.pend_entrega}
                                        text_button="Ver"
                                        url="/slider-principal"
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                )}
            </div>
        );
    }
}

const ms2p = (state) => ({ ...state });

const md2p = { getDashboard };

export default connect(ms2p, md2p)(Home);
