import React, { Component, useState } from 'react'
import { Currency } from 'react-intl-number-format/dist/index.js'
import { Table } from 'antd'
import LoadMask from "../Utils/LoadMask/LoadMask";
import { api } from '../../../utility/api'

import moment from 'moment'
import { useEffect } from 'react';

const TableSolicitud = (info) => {
  const [dataSource, getDataSource] = useState(info.info)

  const ESTADOS = [
    '-',
    'ENVIADA',
    'ACEPTADA',
    'DEPOSITADA',
    'FINALIZADA'
  ]

  const filter_status = []

  ESTADOS.map((status, index) => (
    filter_status.push(
      {
        text: status,
        value: (index * 10)
      })
  ))

  const [loading, setLoading] = useState(true)
  const setData = (comisionData) => {
    let resetData = [];
    dataSource.map((movimiento, index) => {
        resetData.push({
            ...movimiento,
            comision_total: comisionData[index].comision_total,
        });
        }
    )
    getDataSource(resetData);
  }

  function calculateComisiones(callback) {
    let data = {id_cotizacion: [], id_tienda: []}
    dataSource.map((movimiento) =>{
        data.id_cotizacion.push(movimiento.venta)
        data.id_tienda.push(movimiento.tienda)
    })
    setLoading(true)
    api.post(`/categorias-feeds-commissions/calcule_comisions`, data)
        .then((res) => {
            callback(res)
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false)
        });
  };

  const filter_estados = [
    { id: 0, nombre: '-', color: 'default' },
    { id: 1, nombre: 'ENVIADA', color: 'orange' },
    { id: 4, nombre: 'FINALIZADA', color: 'success' }
  ]

  const columns = [
    {
      title: 'No.',
      key: 'id',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      showSorterTooltip: false
    },
    {
      title: 'Fecha Solicitud',
      dataIndex: 'fecha_creacion',
      key: 'fecha_creacion',
      sorter: (a, b) => moment(a.fecha_creacion).unix() - moment(b.fecha_creacion).unix(),
      showSorterTooltip: false,
      render: fecha_creacion => (
        <p>
          {moment(fecha_creacion).format('L')} - ({moment(fecha_creacion).startOf('day').fromNow()})
        </p>
      )
    },
    {
      title: 'Monto solicitado',
      dataIndex: 'monto_solicitado',
      key: 'monto_solicitado',
      render: monto_solicitado => (
        <p>
          <Currency locale='es-CL' currency='CLP'>{monto_solicitado}</Currency>
        </p>
      )
    },
    {
      title: 'A depositar',
      dataIndex: 'monto_transferido',
      key: 'monto_transferido',
      render: (monto_transferido, record) => (
        <p>
          {monto_transferido > 0
            ? <Currency locale='es-CL' currency='CLP'>{monto_transferido}</Currency>
            : <Currency locale='es-CL' currency='CLP'>
              {record.monto_solicitado - record.comision_total}
            </Currency>}
        </p>
      )
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      filters: filter_status,
      onFilter: (value, record) => record.status === value,
      render: status => (
        <p>
          {ESTADOS[status / 10]}
        </p>
      )
    }
  ]
  useEffect(() => {
    calculateComisiones(setData)
  }, [info.info])
  return (

    <LoadMask  loading={loading} blur={true}>
    <div>
      <div className='form-group'>
        <Table dataSource={dataSource} columns={columns} />
      </div>
      <div className='form-group' />
    </div>
    </LoadMask>
  )
}

export default TableSolicitud
