import React, { Component } from "react";
import DefaultHeader from "../../layout/Header/DefaultHeader";
import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/solicitudes/solicitudes";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import TableProductos from "./TableProductos";

class AprobacionProductos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }

    componentDidMount() {
        this.handleLoader();
        this.getProducts();
    }

    getProducts() {
        this.props.getProductos();
    }

    handleLoader = () => {
        setTimeout(() => this.setState({ loader: false }), 1000);
    };

    render() {
        return (
            <div>
                <DefaultHeader title="Aprobación de productos" visible={true} />
                {this.state.loader ? (
                    <LoadMask loading={this.state.loader} dark></LoadMask>
                ) : (
                    <div>
                        {this.props.data ? (
                            <div className="container">
                                <hr />
                                <div className="ps-card">
                                    {this.props.productos.length > 0 ? (
                                        <div>
                                            <TableProductos
                                                getProdotti={this.getProducts}
                                                info={this.props.productos}
                                                styled={true}
                                            />
                                        </div>
                                    ) : (
                                        <p>
                                            En este momento no hay solicitudes
                                            de aprobación
                                        </p>
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        );
    }
}

const ms2p = (state) => {
    return state.solicitudes;
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(AprobacionProductos);
