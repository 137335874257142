import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Modal } from "antd";
import DetalleUsuario from "./DetalleUsuario";
import { connect } from "react-redux";
import moment from "moment";
import { FormControlLabel, Switch } from "@material-ui/core";
import {
    updateUser,
    getVendedoresAll,
    eliminarVendedor
} from "../../../redux/modules/usuarios/usuarios";
import DesactivarUsuario from "./DesactivarUsuario";

const TableVendedor = (props) => {
    const [value, setValue] = useState("");
    const [desde, setDate1] = useState(
        moment().clone().startOf("month").format("YYYY-MM-DD")
    );
    const [hasta, setDate2] = useState(moment().format());
    console.log(props.info)
    // const dataInfo = props.info.filter(vendedor => vendedor.activo == true);
    const dataInfo = props.info;
    const [dataSource, setDataSource] = useState(dataInfo);
    const [isModalVisible, setModal] = useState(false);
    const [isDeleteModal, setDeleteModal] = useState(false);
    const [usuario, setInfo] = useState([]);
    const [Ok, setOk] = useState(false);
    const [Cancel, setCancel] = useState(false);
    const showModal = () => {
        setModal(true);
    };

    const handleOk = () => {
        setModal(false);
        setDeleteModal(false);
        setInfo([]);
    };

    const handleCancel = () => {
        setModal(false);
        setDeleteModal(false);
        setInfo([]);
    };

    const deleteUser = (usuario) => {
        props.eliminarVendedor({id: usuario.id});
        setModal(false);
        setDeleteModal(false);
    }

    const handleChange = (e, record) => {
        const data = { id: record.id, action: e.target.checked };
        props.updateUser(data);
        setDataSource(dataInfo);
    };

    useEffect(() => {
        setDataSource(dataInfo);
    }, [props.info]);

    const ESTADOS = [
        "-",
        "SOLICITADO",
        "DEPOSITADO",
        "DEPOSITADA",
        "FINALIZADA"
    ];

    const filter_status = [];

    ESTADOS.map((status, index) =>
        filter_status.push({
            text: status,
            value: index * 10
        })
    );

    const filter_estados = [
        { id: 0, nombre: "-", color: "default" },
        { id: 1, nombre: "RECIBIDA", color: "orange" },
        { id: 4, nombre: "ACEPATADA", color: "success" },
        { id: 5, nombre: "RECHAZADA", color: "warning" }
    ];

    const handleSetFilter = () => {
        const inicio = new Date(desde).getTime();
        const final = new Date(hasta).getTime();

        const resultData = props.info.filter((obj) => {
            const time = new Date(obj.fecha_creacion).getTime();
            return inicio < time && time < final;
        });
        getDataSource(resultData);
    };

    const columns = [
        {
            title: "Registrado en",
            dataIndex: "date_joined",
            key: "date_joined",
            sorter: (a, b) =>
                moment(a.date_joined).unix() - moment(b.date_joined).unix(),
            showSorterTooltip: false,
            render: (date_joined) => <p>{moment(date_joined).format("L")}</p>
        },
        {
            title: "Empresa",
            dataIndex: "nombre",
            key: "id",
            sorter: (a, b) => a.nombre.localeCompare(b.nombre),
            showSorterTooltip: false,
            render: (nombre, record) => (
                <p>
                    {nombre} , RUT: {record.rut}
                </p>
            )
        },
        {
            title: "Direccion",
            key: "id",
            render: (record) => (
                <p>
                    {record.direccion}, {record.comuna}
                </p>
            )
        },
        {
            title: "Contacto",
            key: "id",
            render: (record) => (
                <p>
                    {record.contacto_nombre}, {record.contacto_primer_apellido}{" "}
                    Tel. {record.empresa_telefono}
                </p>
            )
        },
        {
            title: "Activo",
            dataIndex: "activo",
            key: "id",
            render: (activo, record) => (
                <FormControlLabel
                    control={
                        <Switch
                            checked={activo}
                            onClick={(e) => {
                                handleChange(e, record);
                                props.getVendedoresAll({
                                    type: "SET_VENDEDORES"
                                });
                            }}
                            name="checkedB"
                            color="primary"
                        />
                    }
                />
            )
        },
        {
            title: "-",
            render: (activo, record) => (
                <div className="d-flex justify-content-around">
                    <button
                        className="ps-btn ps-btn--opt"
                        onClick={(e) => {
                            setInfo(record), showModal();
                        }}
                    >
                        <i className="icon-eye" style={{ fontWeight: "600" }} />
                    </button>
                    <button
                        style={{
                            backgroundColor: activo.activo ? '#ccc' : '',
                            cursor: activo.activo ? 'not-allowed' : '',
                            color: activo.activo ? '#000' : ''}
                        }
                        disabled={activo.activo}
                        className="ps-btn ps-btn--opt"
                        onClick={(e) => {
                            setInfo(record), setDeleteModal(true);
                        }}
                    >
                        <i
                            className="icon-trash2"
                            style={{ fontWeight: "600" }}
                        />
                    </button>
                </div>
            )
        }
    ];

    return (
        <div>
            <div className="form-group">
                {props.styled ? (
                    <React.Fragment>
                        <section
                            className="ps-dashboard"
                            style={{ alignItems: "center",marginBottom: "5px", }}

                        >
                            <div
                                className=""
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center"
                                }}
                            >
                                <div>
                                    <Link
                                        to="/vendedores-solicitudes"
                                        className="ps-btn"
                                    >
                                        solicitudes de aprobación (
                                        {props.solicitudes})
                                    </Link>
                                </div>
                            </div>

                            <div className="ps-form--filter" >
                                <div style={{display:"flex",flexDirection:"column",alignItems:"end"}} >
                                    <div className="ps-form--search-simple" >
                                        <input
                                            placeholder="Buscar..."
                                            className="form-control"
                                            value={value}
                                            onChange={(e) => {
                                                const currValue =
                                                    e.target.value;
                                                setValue(currValue);
                                                const filteredData =
                                                    dataInfo.filter((entry) =>
                                                        entry.nombre
                                                            .toLowerCase()
                                                            .includes(
                                                                currValue.toLowerCase()
                                                            )
                                                    );
                                                setDataSource(filteredData);
                                            }}
                                        />
                                        <button>
                                            <i className="icon icon-magnifier" />
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </section>

                        <Table
                            dataSource={dataSource}
                            rowKey="id"
                            columns={columns}
                            className="table ps-table--solicitud"
                        />
                    </React.Fragment>
                ) : (
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        rowKey="id"
                    />
                )}
            </div>
            <div className="form-group" />
            <Modal
                title=""
                width={800}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <button
                        type="reset"
                        className="ps-btn ps-btn"
                        onClick={handleCancel}
                    >
                        Cerrar
                    </button>
                ]}
            >
                <DetalleUsuario usuario={usuario} />
            </Modal>

            <Modal
                title=""
                width={800}
                visible={isDeleteModal}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <div className="d-flex justify-content-around">
                        <button
                            className="ps-btn ps-btn--gray"
                            onClick={handleCancel}
                        >
                            Cancelar
                        </button>
                        <button className="ps-btn" onClick={() => deleteUser(usuario)}>Eliminar</button>
                    </div>
                ]}
            >
                <DesactivarUsuario usuario={usuario} />
            </Modal>
        </div>
    );
};

const md2p = { updateUser, getVendedoresAll, eliminarVendedor };

export default connect(null, md2p)(TableVendedor);
