import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { password, passwordsMustMatch } from './validate'
import { renderField } from '../../../Utils/renderField/renderField'
import { Link } from 'react-router-dom'
import logo from '../../../../../../assets/img/logo.png'
import FooterCopyright from '../../../shared/footer/FooterCopyright';
import FooterWidgets from '../../../shared/footer/FooterWidgets';

const RecoverPasswordForm = (props) => {
  const { handleSubmit, pristine, submitting, token, is_valid } = props


  return (
    <div>
      {is_valid
        ? <React.Fragment>
        <div className="register-photo">
            <div className="form-container">
                <div className="image-holder pointer-login" id="image-login"></div>
            <form onSubmit={handleSubmit} >
                <div className="ps-form--account">
                    <div className="ps-tab active" id="register">
                        <div className="ps-form__content testfont template-content">  
                            <h2>Reestablecimiento de contraseña</h2>
                        <div className="form-group">
                            <Field
                            className="form-control"
                            name="password"
                            type="password"
                            component={renderField}
                            validate={password}
                            placeholder="Contraseña"
                            />
                        </div>
                        <div className="form-group">
                            <Field
                            name="password_confirmation"
                            type="password"
                            component={renderField}
                            placeholder="Confirmación de contraseña"
                            validate={passwordsMustMatch}
                            />
                        </div>
                        <button type="submit" className="ps-btn ps-btn--fullwidth p-4" disabled={pristine || submitting}>
                        Continuar
                        </button>
                    </div>
                </div>
                <br/><br/><br/><br/>
            </div>
            </form>
            </div>
        </div> 

        <footer className="ps-footer">
                <div className="ps-container">
                    <FooterWidgets />
                    <FooterCopyright />
                </div>
        </footer>
          </React.Fragment>
        : <React.Fragment>

          <div className='container'>
            <div className=''>
              <div>
                <img src='' alt='' />
                <h3>Ohh! hubo un error en  la página</h3>
                <p>
                  No encontramos el recurso que buscabas.{' '}
                  Puesdes regresar a la página principal
                </p>
                <br />
                <hr />
                <Link href='/login'>
                  <a className='ps-btn ps-btn--fullwidth' style={{ color: 'black' }}>
                    Iniciar sesión
                  </a>
                </Link>
              </div>
            </div>
          </div>
          </React.Fragment>}
    </div>

  )
}

export default reduxForm({
  form: 'change_password'
})(RecoverPasswordForm)
