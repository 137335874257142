import React, { Component } from "react";
import { connect } from "react-redux";
import {
    getAllCategorias,
    postCategoria,
    updateCategoria,
    deleteCategoria
} from "../../../redux/modules/categorias/categorias";
import { getAllFamilias } from "../../../redux/modules/familias/familias";
import "../../../../style/scss/custom/FamiliasList.scss";
import FamiliaModal from "./FamiliaModal";
import CategoriaForm from "./CategoriaForm";

class CategoriaList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valor: "",
            params: `/?page=1&limit=10`,
            show: false,
            show1: false,
            categoria: null
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.saveCategoria = this.saveCategoria.bind(this);
        this.editCategoria = this.editCategoria.bind(this);
    }

    componentDidMount() {
        this.handleLoader();
    }

    handleLoader = () => {
            this.props.getAllCategorias(this.state.params);
    };


    getRange = (inicio, final) => {
        let rango = [];
        for (let i = inicio; i < final + 1; i++) {
            rango.push(i);
        }
        return rango;
    };

    searchCategoria = async (e) => {
        this.setState({ valor: e.target.value }, () => {
            this.paginator(1, 0);
        });
    };

    paginator = (page, pagina_actual) => {
        if (page != pagina_actual) {
            let params = "/?";
            if (this.state.valor) {
                params = params + "search=" + this.state.valor + "&";
            }
            params = params + `page=${page}&limit=10`;
            this.setState({ params }, () => {
                this.props.getAllCategorias(this.state.params);
            });
        }
    };

    showModal() {
        this.setState({ show: true });
        this.props.getAllFamilias();
    }

    showEditModal(element) {
        this.setState({ categoria: element, show1: true });
    }

    saveCategoria(data) {
        if (Object.keys(data).length > 0) {
            data.activo = true;
            this.props.postCategoria(data);
            this.hideModal();
            this.setState({ loader: true });
            this.props.getAllCategorias(this.state.params);
        }
    }

    editCategoria(data) {
        data.familia = data.familia.id
        if (Object.keys(data).length > 0) {
            this.props.updateCategoria(data);
            this.hideModal();
            this.setState({ loader: true, categoria: null });
            this.props.getAllCategorias(this.state.params);
        }
    }

    hideModal() {
        this.setState({ show: false, show1: false, categoria: null });
    }

    render() {
        const response = this.props.categorias.all_categorias;
        let all_familias = this.props.familias.all_familias;
        if (all_familias.length > 0) {
            all_familias.forEach((e) => {
                e.value = e.id;
                e.label = e.nombre;
            });
        }
        const categorias = response.datos;
        const total = response.meta ? response.meta.page.total : 0;
        const anterior = response.links ? response.links.previos : 0;
        const primera = response.links ? response.links.first : 0;
        const ultima = response.links ? response.links.last : 0;
        const pagina_actual = response.meta
            ? response.meta.page.current_page
            : 0;
        const rango = this.getRange(primera, ultima);
        return (
            <div>
                <FamiliaModal
                    show={this.state.show}
                    handleClose={this.hideModal}
                >
                    <CategoriaForm
                        titulo={"Nueva categoria"}
                        onSubmit={this.saveCategoria}
                        handleCancel={this.hideModal}
                        feature={null}
                        editar={false}
                        all_familias={all_familias}
                    />
                </FamiliaModal>
                <FamiliaModal
                    show={this.state.show1}
                    handleClose={this.hideModal}
                >
                    <CategoriaForm
                        titulo={"Editar categoria"}
                        onSubmit={this.editCategoria}
                        handleCancel={this.hideModal}
                        editar={true}
                        feature={this.state.categoria}
                        initialValues={this.state.categoria}
                        all_familias={all_familias}
                    />
                </FamiliaModal>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12">
                            <h3 className="fw-b fs-22">Categorías({total})</h3>
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <div className="col-12">
                            <nav
                                className="navbar justify-content-between"
                                style={{
                                    marginBottom: "5px",
                                    padding:"0"
                                }}
                            >
                                <div className="d-flex justify-content-left" style={{alignItems:"left"}}>
                                    <button
                                        onClick={() => this.showModal()}
                                        className="ps-btn"
                                    >
                                        Nueva categoria
                                    </button>
                                </div>
                                <div className="ps-form--search-simple">
                                    <input
                                        placeholder="Buscar..."
                                        className="form-control"
                                        value={this.state.valor}
                                        onChange={(e) =>
                                            this.searchCategoria(
                                                e,
                                                pagina_actual
                                            )
                                        }
                                    ></input>
                                    <button>
                                        <i className="icon icon-magnifier" />
                                    </button>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <table className="table custom--table">
                                <thead>
                                    <tr>
                                        <th scope="col">Id</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Familia</th>
                                        <th scope="col">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {categorias ? (
                                        categorias.length > 0 ? (
                                            categorias.map((element, index) => (
                                                <tr key={index}>
                                                    <td scope="row">
                                                        {element.id}
                                                    </td>
                                                    <td>{element.nombre}</td>
                                                    <td>
                                                        {element.familia == null ? element.familia = "sin asignar" : element.familia.nombre }
                                                    </td>
                                                    <td>
                                                        <button
                                                            style={{
                                                                marginRight:
                                                                    "5px",
                                                                color: "white"
                                                            }}
                                                            title="Editar"
                                                            className="ps-btn ps-btn--opt edit--button"
                                                            onClick={() =>
                                                                this.showEditModal(
                                                                    element
                                                                )
                                                            }
                                                        >
                                                            <i className="icon-pencil"></i>
                                                        </button>
                                                        <button
                                                            style={{
                                                                color: "white",
                                                                backgroundColor: "red",
                                                            }}
                                                            title="Eliminar"
                                                            className="ps-btn ps-btn--opt delete--button"
                                                            onClick={() => this.props.deleteCategoria(element.id)}
                                                        >
                                                            <i className="icon-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <th
                                                    colSpan="4"
                                                    style={{
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    No hay datos.
                                                </th>
                                            </tr>
                                        )
                                    ) : (
                                        <tr>
                                            <th
                                                colSpan="4"
                                                style={{
                                                    textAlign: "center"
                                                }}
                                            >
                                                No hay datos.
                                            </th>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 d-flex justify-content-center">
                            {categorias && categorias.length > 0 ? (
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                        <li
                                            className={`page-item ${
                                                anterior == 0 ? "disabled" : ""
                                            }`}
                                        >
                                            <a
                                                className="page-link"
                                                onClick={() =>
                                                    this.paginator(
                                                        anterior,
                                                        pagina_actual
                                                    )
                                                }
                                            >
                                                &laquo;
                                            </a>
                                        </li>
                                        {rango.map((e, index) => (
                                            <li
                                                key={index}
                                                style={{
                                                    margin: "0px 25px"
                                                }}
                                                className={`page-item ${
                                                    pagina_actual == e
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                <a
                                                    className="page-link"
                                                    onClick={() =>
                                                        this.paginator(
                                                            e,
                                                            pagina_actual
                                                        )
                                                    }
                                                >
                                                    {e}
                                                </a>
                                            </li>
                                        ))}
                                        <li
                                            className={`page-item ${
                                                pagina_actual == ultima
                                                    ? "disabled"
                                                    : ""
                                            }`}
                                        >
                                            <a
                                                className="page-link"
                                                onClick={() =>
                                                    this.paginator(
                                                        ultima,
                                                        pagina_actual
                                                    )
                                                }
                                            >
                                                &raquo;
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const ms2p = (state) => {
    return state;
};

const md2p = {
    getAllCategorias,
    postCategoria,
    updateCategoria,
    getAllFamilias,
    deleteCategoria,
};

export default connect(ms2p, md2p)(CategoriaList);
