import React, { useEffect, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { renderInitialField } from '../../Utils/renderField/renderField'

const FeatureForm = (props) => {
  const { handleSubmit, change, feature } = props

  useEffect(() => {
    change('titulo', feature.titulo)
    change('descripcion', feature.descripcion)
    change('icon', feature.icon)
  }, [], [], [])

  return (
    <div>
      <div className='container'>
        <h4 style={{ textAlign: 'center' }}>Cambiando caracteristica: {feature.titulo}</h4>
        <form onSubmit={handleSubmit}>

          <div className='container' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div className='form-group col-8 pt-10'>
              <label>Titulo:</label>
              <Field
                name='titulo'
                component={renderInitialField}
                value={feature.titulo}
              />
            </div>
          </div>

          <div className='container' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div className='form-group col-8 pt-10'>
              <label>Descripción:</label>
              <Field
                name='descripcion'
                component={renderInitialField}
              />
            </div>
          </div>

          <div className='container' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div className='form-group col-8 pt-10'>
              <label>Icono:</label>
              <Field
                name='icon'
                component={renderInitialField}
              />
            </div>
          </div>

          <div className='container mt-20 pt-20' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button type='submit' className='ps-btn btn-warning'>Guardar</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'features_form', // a unique identifier for this form
  enableReinitialize: true
})(FeatureForm)
