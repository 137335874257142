import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { actions } from '../../../../redux/modules/cuenta/login'
import profile from '../../../../redux/modules/cuenta/profile'
import LazyLoad from 'react-lazyload'
import { api, basepath } from '../../../../utility/api'
import LogoAgromall from '../../../../../assets/img/logo.png'

const WidgetUserWelcome = (props) => {
  const handleLogout = (e) => {
    props.logOut()
  }

  console.log(props)

  const { me } = props

  if (props.login) {
    return (
      <div className='ps-block--user-wellcome'>
        <div className='ps-block__left'>
          <img src={require('../../../../../assets/img/admin.jpg')} alt='' />
        </div>
        <div className='ps-block__right'>
          <p>
            Hola!,<a href='#'>{me.username}</a>
          </p>
        </div>
        {/* 
        <div className='ps-block__action icon-w10'>
          <Link to='#' onClick={handleLogout}>
            <a>
              <i className='icon-exit' />
            </a>
          </Link>
          {// <span className="fs-12">Salir</span> }
        </div>
        */}
      </div>
    )
  } else {
    return (
      <React.Fragment>
      
      <div className='d-flex justify-content-center ps-block--user-wellcome'>
        <div className=''>
          <LazyLoad>
            <img
              src={LogoAgromall}
              alt='Logo agromall'
            />
          </LazyLoad>
        </div>
      </div>
        <div className='ps-block--user-wellcome d-flex justify-content-center '>
          <p>
            <a href='#'>Hola, {me.username}</a>
          </p>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return state.login
}

const md2p = { ...actions }

export default connect(mapStateToProps, md2p)(WidgetUserWelcome)
