const validate = values => {
  const errors = {};

  if(values.nombre_comercial){
    const regex = /^([A-Z]{1}([a-z \s \- 0-9 ü é á í ó ú ñ Ñ . / &]+))+$/;
    if(regex.test(values.nombre_comercial.trim())){
      values.nombre_comercial = values.nombre_comercial.trim()
    }else{
      errors.nombre_comercial = "El formato del nombre no es correcto"
    }
  }else{
    errors.nombre_comercial = "Este campo es requerido";
  }

  if(!values.marca){
    errors.marca = "Este campo es requerido.";
  }

//   if(values.marca){
//     const regex = /^([A-Z]{1}([a-z \s \- 0-9 ü é á í ó ú ñ Ñ . / &]+))+$/;
//     if(regex.test(values.marca.trim())){
//       values.marca = values.marca.trim()
//     }else{
//       errors.marca = "Formato incorrecto debes Iniciar con Mayuscula, simbolos permitidos - . / &"
//     }
//   }else{
//     errors.marca = "Este campo es requerido.";
//   }

  //if (!values.tipo_formulacion) {
  //  errors.tipo_formulacion = "Este campo es requerido";
  //}
  //if (!values.sustancia_activa) {
  // errors.sustancia_activa = "Este campo es requerido";
  //}
  //if (!values.concentracion) {
  //  errors.concentracion = "Este campo es requerido";
  //}
  if (!values.envase) {
    errors.envase = "Este campo es requerido";
  }
  if (!values.unidad_medida) {
    errors.unidad_medida = "Este campo es requerido";
  }
  if (!values.familia) {
    errors.familia = "Este campo es requerido";
  }
  if (!values.categoria) {
    errors.categoria = "Este campo es requerido";
  }


  if (values.envase > 9999.99) {
    errors.envase = "El valor maximo es 9999.99";
  }
  if(values.envase){
    if (!/^[0-9]+([.][0-9]+)?$/g.test(values.envase.trim())) {
      if(/^[0-9]+([\/][0-9]+)?$/g.test(values.envase.trim())){
        values.envase = parseFloat(eval(values.envase)).toFixed(2);
      }else if(/^[0-9]+([,][0-9]+)?$/g.test(values.envase.trim())){
        values.envase = values.envase.replace(",", ".");
      }
      else{
        errors.envase = "El dato ingresado es inválido (El campo debe ser Numerico)";
      }
    }
  }

  return errors;
};

export default validate;
