import { handleActions } from "redux-actions";
import { api } from "api";
import { NotificationManager } from "react-notifications";
import Swal from 'sweetalert2';

const actionTypes = {
    SET_CATEGORIAS: "SET_CATEGORIAS",
    SET_CATEGORIA: "SET_CATEGORIA",
    SET_CATEGORIA_FEED: "SET_CATEGORIA_FEED",
    SET_IVA: "SET_IVA",
    SET_ALL_CATEGORIAS: "SET_ALL_CATEGORIAS"
};

export function setIva(iva) {
    return {
        type: actionTypes.SET_IVA,
        iva
    };
}

export function setAllCategorias(all_categorias) {
    return {
        type: actionTypes.SET_ALL_CATEGORIAS,
        all_categorias
    };
}

export function setCategorias(categorias) {
    return {
        type: actionTypes.SET_CATEGORIAS,
        categorias
    };
}

export function setCategoria(categoria) {
    return {
        type: actionTypes.SET_CATEGORIA,
        categoria
    };
}

export function setCategoriaFeed(categoria_feed) {
    return {
        type: actionTypes.SET_CATEGORIA_FEED,
        categoria_feed
    };
}

export const getIva = () => (dispatch) => {
    api.get("config/get_iva")
        .then((iva) => {
            dispatch(setIva(iva));
        })
        .catch((err) => console.log(err));
};

export const getAllCategorias = (params) => (dispatch) => {
    console.log("productos/categorias/paginate_all" + params);
    api.get("productos/categorias/paginate_all" + params)
        .then((all_categorias) => {
            dispatch(setAllCategorias(all_categorias));
        })
        .catch((err) => console.log(err));
};

export const getCategorias = (params) => (dispatch) => {
    api.get("productos/categorias/" + params)
        .then((categorias) => {
            dispatch(setCategorias(categorias));
        })
        .catch((err) => console.log(err));
};

export const getCategoria = (id) => (dispatch) => {
    api.get("productos/categorias/" + id)
        .then((categoria) => {
            dispatch(setCategoria(categoria));
        })
        .catch((err) => console.log(err));
};

export const getCategoriaFeed = (id) => (dispatch) => {
    api.get("categorias-feeds-commissions/" + id)
        .then((categoria_feed) => {
            if (categoria_feed.code == 404) categoria_feed = null;
            dispatch(setCategoriaFeed(categoria_feed));
        })
        .catch((err) => console.log(err));
};


export const postCategoria = (data) => (dispatch) => {
    const regex = /^([A-Z]{1}([a-z \s \- 0-9 ü é á í ó ú ñ Ñ . / &]+))+$/;
    if(data.nombre && regex.test(data.nombre.trim())){
        api.post("productos/categorias", data)
        .then(() => {
            NotificationManager.success(
                "Satisfactorio",
                "Categoría agregada satisfactoriamente."
            );
        })
        .catch((err) => {
            let message = "Insatisfactorio";
            let description = err.message;
            NotificationManager.error(message, description);
        });
    }else{
            let message = "Campo Invalido Categoria";
            let description = "El Formato del Nombre no es Valido ";
            NotificationManager.error(message, description);
    }
};

export const updateCategoria = (data) => (dispatch) => {
    api.put("productos/categorias/" + data.id, data)
        .then(() => {
            NotificationManager.info(
                "Satisfactorio",
                "Categoría actualizada satisfactoriamente."
            );
        })
        .catch((err) => {
            let message = "Hubo un error al enviar los datos";
            let description = "Intenta mas tarde";
            NotificationManager.error(message, description);
        });
};

export const deleteCategoria = (id, next_params="") => (dispatch) => {
    Swal.fire({
        title: '¿Eliminar?',
        text: '¡No podrá revertir esta acción!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: '¡Sí, eliminar!',
        cancelButtonText: 'No, cancelar',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            api.eliminar(`productos/categorias/${id}`)
            .then(() => {
                if(next_params !== "") dispatch(getCategorias(next_params))
                else dispatch(getAllCategorias(`/?page=1&limit=10`))
                NotificationManager.info(
                    "Satisfactorio",
                    "Categoría eliminada satisfactoriamente."
                );
            })
            .catch((err) => {
                let message = "Hubo un error al enviar los datos";
                let description = "Intenta mas tarde";
                NotificationManager.error(message, description);
            });
        }
    });
};

export const postCategoriaFeedCommission = (data) => (dispatch) => {
    api.post("categorias-feeds-commissions", data)
        .then(() => {
            NotificationManager.success(
                "Satisfactorio",
                "Feed y comisión agregada satisfactoriamente."
            );
        })
        .catch((err) => {
            let message = "Hubo un error al enviar los datos";
            let description = "Intenta mas tarde";
            NotificationManager.error(message, description);
        });
};

export const updateCategoriaFeedCommission = (data) => (dispatch) => {
    api.put("categorias-feeds-commissions/" + data.id, data)
        .then(() => {
            NotificationManager.info(
                "Satisfactorio",
                "Feed y comisión actualizada satisfactoriamente."
            );
        })
        .catch((err) => {
            let message = "Hubo un error al enviar los datos";
            let description = "Intenta mas tarde";
            NotificationManager.error(message, description);
        });
};

export const updateIva = (data) => (dispatch) => {
    api.put("config/update_iva", data)
        .then(() => {
            NotificationManager.info(
                "Satisfactorio",
                "IVA actualizado satisfactoriamente."
            );
        })
        .catch((err) => {
            let message = "Hubo un error al enviar los datos";
            let description = "Intenta mas tarde";
            NotificationManager.error(message, description);
        });
};

export const actions = {
    getCategorias,
    postCategoria,
    updateCategoria,
    deleteCategoria,
    getCategoria,
    getCategoriaFeed,
    postCategoriaFeedCommission,
    updateCategoriaFeedCommission,
    getIva,
    updateIva,
    getAllCategorias
};

export const reducers = {
    [actionTypes.SET_CATEGORIAS]: (state, { categorias }) => {
        return {
            ...state,
            categorias
        };
    },
    [actionTypes.SET_CATEGORIA]: (state, { categoria }) => {
        return {
            ...state,
            categoria
        };
    },
    [actionTypes.SET_CATEGORIA_FEED]: (state, { categoria_feed }) => {
        return {
            ...state,
            categoria_feed
        };
    },
    [actionTypes.SET_IVA]: (state, { iva }) => {
        return {
            ...state,
            iva
        };
    },
    [actionTypes.SET_ALL_CATEGORIAS]: (state, { all_categorias }) => {
        return {
            ...state,
            all_categorias
        };
    }
};

export const initialState = {
    categorias: {},
    categoria: {},
    categoria_feed: {},
    iva: null,
    all_categorias: {}
};

export default handleActions(reducers, initialState);
