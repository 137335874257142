import React, { Component } from 'react';
import DefaultHeader from '../../layout/Header/DefaultHeader'
import { connect } from "react-redux";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { Link } from 'react-router-dom';
import TableBanners from '../Tables/TableBanners';
import TableFeatures from '../Tables/TableFeatures';


class Tienda extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            loader: true
        }; 
    }

    timer = null;

    showModal = () => {
        this.setState({isModalVisible:true})
      };

    handleOk = () => {
        this.setState({isModalVisible:false})
      };
    
    handleCancel = () => {
    this.setState({isModalVisible:false})
    };
    
    componentDidMount(){
        this.handleLoader()
    }

    handleLoader = () => {
        setTimeout(()=> this.setState({loader:false}),1000);
    }
    
    handleSend = (data) =>{
        //this.props.updateProfile(data)
    }



render() {

    const {banners} = this.props.sitio
    return (
        <div>
            <DefaultHeader title="Ajustes de agromall" visible={true}/>
        {this.state.loader?
        <LoadMask loading={this.state.loader} dark>
        
        </LoadMask>
        :
        <div> 
            <Link to={`/ajustes`} className="ps-btn" style={{backgroundColor: "#2e3d50", color: "white"}}>
                Regresar
            </Link> 
            <hr/>
            <section>
            <h3 className="pt-50">Banner de la pagina </h3>
            <TableBanners/>
            <hr/>
            <h3 className="pt-50">Caracteristicas del sitio </h3>
            <TableFeatures/>
            </section>
        </div>
        }
        </div>
            );
        }
}



const ms2p = state => ({...state})

const md2p = {};
  
export default connect(ms2p, md2p)(Tienda);
