import React from "react";

export const TiendasDiponibles = (props) => {
    const { tiendas } = props;
    return (
        <div className="card text-center" style={{ width: "100%" }}>
            <table className="table">
                <tr style={{ backgroundColor: "#6799c5" }}>
                    <td colSpan="2" style={{ fontWeight: "bold" }}>
                        {tiendas.length} tiendas ofrecen este producto
                    </td>
                </tr>
                    {tiendas.length>0
                    ? tiendas.map(tienda => (
                          <tr>
                              <td colSpan="2">{tienda.tienda__nombre}</td>
                          </tr>
                      ))
                    : 
                        <tr>
                           <td colSpan="2">
                        Ninguna tienda vende este producto...
                    </td> 
                        </tr>
                    }
            </table>
        </div>
    );
};
