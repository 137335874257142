import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Table, Modal } from 'antd'
import { actions } from '../../../../../redux/modules/sitio/sitio'
import { FormControlLabel, Switch } from "@material-ui/core";
import BannerForm from './BannerForm'
import BannerNew from './BannerNew'
import DeleteModal from '../../../Utils/GenericsModals/DeleteModal'


const TableBanner = (props) => {
  const {} = props
  const [value, setValue] = useState('')
  const [dataSource, setDataSource] = useState(props.info)
  const [dataSourceDataFilter, setDataSourceDataFilter] = useState(dataSource)
  const [isModalVisible, setModal] = useState(false)
  const [banner, setInfo] = useState([])
  const [banner_img, setImagen] = useState([])
  const [Ok, setOk] = useState(false)
  const [OkAdd, setOkAdd] = useState(false)
  const [CancelAdd, setCancelAdd] = useState(false)
  const [Cancel, setCancel] = useState(false)
  const [selected, setSelected] = useState(null);
  const [confirm, showConfirm] = useState(false);
  const [image, setImage] = useState(null);
  const [isModalAddVisible, setAddModal] = useState(false)

  const closeModal = () => {
    setImagen([]);
    setSelected([]);
    showConfirm(false);
    setModal(false);
  };

  const showModal = () => {
    setModal(true)
  }

  const showAddModal = () => {
    setAddModal(true)
  }

  const handleSetImagen = (imagen) => {
    setImagen(imagen)
  }

  const handleSetImage = (imagen) => {
    setImage(imagen)
  }
  


  const handleOk = () => {
    setOk(false)
    setModal(false)
    setInfo([])
  }

  const handleOkAdd = () => {
    setOkAdd(false)
    setAddModal(false)
    setInfo([])
    setImage(null)
  }

  const handleDelete = (e, record) => {
    e.preventDefault();
    setSelected(record);
    showConfirm(true);
};

  const handleCancel = () => {
    setCancel(false)
    setModal(false)
    showConfirm(false);
    setInfo([])
  }

  const handleCancelAdd = () => {
    setCancelAdd(false)
    setAddModal(false)
    showConfirm(false);
    setInfo([])
  }
  const handleNewBanner = (data) => {
    const imagen = { name: "imagen", file: image };
    data.imagen = imagen
    props.addBanner(data)
    handleOkAdd()
};

  const handleSubmit = (data) => {
    const imagen = { name: 'imagen', file: banner_img }
    data.imagen = imagen
    data.id = banner.id
    props.updateBanner(data)
  }

  const handleSubmitDelete = () => {
    props.deleteBanner(selected)
    showConfirm(true);
  }

  const handleChange = (e, record) => {
    const data = { id: record.id, action: e.target.checked };
    props.selectedBanner(data)
    props.getBanners()
    location.reload()
};

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre'
    },
      {
        title: "PREDETERMINADO",
        dataIndex: "selected",
        render: (selected, record) => {
          return (
            <FormControlLabel
                control={
                    <Switch
                        checked={record.slug==="banner-prueba"?true:false}
                        onClick={(e) => {
                            handleChange(e, record);
                        }}
                        name="checkedB"
                        color="primary"
                    />
                }
            />
        )}
    },
    {
      title: '-',
      render: (record) => (
        <React.Fragment>
          <div  className="wrap" style={{display:"flex",justifyContent:"center" ,gap:"0.2rem"}}>
              <button
                className="ps-btn ps-btn--opt"
                onClick={e => {
                  setInfo(record),
                  showModal()
                }}
              >
                <i
                  className="icon-eye"
                  style={{ fontWeight: "600" }}
                />
              </button>
              <button
                className="ps-btn ps-btn--opt"
                onClick={(e) => handleDelete(e, record)}
              >
                 <i
                    className="icon-trash2"
                    style={{ fontWeight: "600" }}
                  />
              </button>
            </div>
        </React.Fragment>
      )
    }
  ]
  return (
    <div>
      <div className='form-group'>
        {props.styled
          ? <React.Fragment>
            <section className='ps-dashboard' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <div style={{ display: 'flex',gap:"0.5rem" }}>
                  <button
                    className="ps-btn"
                    onClick={e => {
                      showAddModal()
                    }}
                  >
                      Agregar +
                  </button>
                  <Link
                    to='/sitio'
                    className='ps-btn'
                    style={{backgroundColor: "#2e3d50", color: "white"}}
                  >
                      Regresar
                  </Link>
              </div>

              <div className='' style={{display: 'flex',justifyContent: 'flex-end' }}>
                <div className='col-12 col-sm-8' style={{margin: '0', padding: '0', display: 'flex',justifyContent: 'flex-end', gap:"0.5rem"}}>
                  <div className='ps-form--search-simple'>
                    <input
                    placeholder='Buscar...'
                    className='form-control'
                    value={value}
                    onChange={e => {
                        const currValue = e.target.value
                        setValue(currValue)
                        const filteredData = dataSource.filter(entry =>
                          entry.nombre.toLowerCase().includes(currValue.toLowerCase())
                        );
                        setDataSourceDataFilter(filteredData)
                      }}
                  >
                  </input>
                    <button><i className='icon icon-magnifier' /></button>
                  </div>
                </div>
              </div>
            </section>
            <hr />
            <Table dataSource={dataSourceDataFilter} columns={columns} className='table ps-table--market' />
            </React.Fragment>
          : <Table dataSource={dataSourceDataFilter} columns={columns} />}
      </div>
      <Modal
        title=''
        width={800}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <BannerForm banner={banner} onSubmit={handleSubmit} setBanner={handleSetImagen} />
      </Modal>
      <Modal
        title=''
        width={800}
        visible={isModalAddVisible}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
        footer={[]}
      >
        <BannerNew image={image} onSubmit={handleNewBanner} setImage={handleSetImage}/>
      </Modal>
      <DeleteModal
          visible={confirm}
          closeModal={closeModal}
          handleSubmit={handleSubmitDelete}
          size={600}
          item_name={"la imagen seleccionada"}
      />
    </div>
  )
}

const md2p = { ...actions }
const mapStateToProps = state => (
  state
)
export default connect(mapStateToProps, md2p)(TableBanner)
