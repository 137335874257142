import React, { Component } from "react";
import DefaultHeader from "../layout/Header/DefaultHeader";
import { connect } from "react-redux";
import { getCatalogo } from "../../../redux/modules/productos/productos";
import { actions } from "../../../redux/modules/solicitudes/solicitudes";
import LoadMask from "../Utils/LoadMask/LoadMask";
import TableProductos from "./TableProductos";
import { Link } from "react-router-dom";

class Productos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            isModalRequested: false,
            isModalPaid: false,
            loader: true
        };
    }

    componentDidMount() {
        this.handleLoader();
        this.props.getCatalogo();
        this.props.getProductos();
    }

    showModal = () => {
        this.setState({ isModalVisible: true });
    };

    showModalRequested = () => {
        this.setState({ isModalRequested: true });
    };

    showModalPaid = () => {
        this.setState({ isModalPaid: true });
    };

    handleOk = () => {
        this.setState({ isModalVisible: false });
        this.setState({ isModalRequested: false });
        this.setState({ isModalPaid: false });
    };

    handleCancel = () => {
        this.setState({ isModalVisible: false });
        this.setState({ isModalRequested: false });
        this.setState({ isModalPaid: false });
    };

    handleSend = (data) => {
        this.props.onSubmitSolicitud(data);
    };

    handleLoader = () => {
        setTimeout(() => this.setState({ loader: false }), 1500);
    };

    render() {
        var solicitudes;
        {
            this.props.solicitudes.productos.length > 0
                ? (solicitudes = this.props.solicitudes.productos.length)
                : (solicitudes = 0);
        }
        const { me } = this.props.login;
        if (this.state.loader) {
            return (
                <div>
                    <DefaultHeader title="Catálogo" visible={true} />
                    <LoadMask loading={this.state.loader} dark />
                </div>
            );
        } else {
            return (
                <div>
                    <DefaultHeader title="Catálogo" visible={true} />
                    <div className="alert alert-info" role="alert">
                        <ul>
                            <h3>
                                <i className="icon icon-bubble-alert" />{" "}
                                Información importante
                            </h3>
                            <li>
                                La lista de productos en la tabla son todos
                                aquellos que los vendedores pueden incorporar a
                                su catálogo.
                            </li>
                            <li>
                                Sí un producto no existe puedes agregarlo de
                                forma manual en usando la opción "<b>Nuevo +</b>
                                " para que las tiendas puedan incorporarlo.
                            </li>
                            <li>
                                Una tienda tambien puede crear un producto pero
                                debe pasar el proceso de aprobación, puedes
                                verlo en "<b>Aprobaciones</b>".
                            </li>
                            <li>
                                También puedes agregar productos importandolos
                                desde AP usando la opción "<b>Importar</b>".
                            </li>
                        </ul>
                    </div>
                    {this.props.productos.productos.length > 0 ? (
                        <div>
                            <hr />
                            <TableProductos
                                info={this.props.productos.productos}
                                solicitudes={solicitudes}
                                aprobarProducto1={this.props.aprobarProducto1}
                                getCatalogo={this.props.getCatalogo}
                                handleLoader={this.handleLoader}
                                deleteProductoLocal={this.props.deleteProductoLocal}
                                styled={true}
                            />
                        </div>
                    ) : (
                        <div>
                            <hr/>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center"
                                }}
                            >
                                <div>
                                    <Link to="/migracion-ap" className="ps-btn">
                                        Importar
                                        <i className="icon-sync pl-3" />
                                    </Link>
                                </div>
                                <div>
                                    <Link
                                        to="/aprobacion-productos"
                                        className="ps-btn"
                                    >
                                        Aprobaciones({solicitudes.length})
                                    </Link>
                                </div>
                                <div>
                                    <Link
                                        to="/producto-nuevo"
                                        className="ps-btn"
                                    >
                                        Nuevo +
                                    </Link>
                                </div>
                            </div>
                                <div className="alert alert-secondary mt-20" role="alert" style={{textAlign:"center"}}>
                                <p>
                                    En este momento no hay ningún producto agregado...
                                </p>
                                </div>
                        </div>
                    )}
                </div>
            );
        }
    }
}

const ms2p = (state) => {
    return state;
};

const md2p = { ...actions, getCatalogo };

export default connect(ms2p, md2p)(Productos);
