
import React, { Component} from 'react'
import { Link } from 'react-router-dom'

class Failed extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <div className='container'>
        <div className='ps-block--form-box'>
          <div className='ps-page--single'>
            <div className='ps-my-account'>
              <div className='container pt-50 pb-80'>
                <div className='ps-form--account'>
                  <div className='ps-tab active pb-25 mb-25' id='register'>
                    <div className='ps-form__content'>
                      <div className='container'>
                        <h1 style={{ textAlign: 'center' }}>
                        ¡Hubo un error al activar la cuenta!
                                        </h1>
                        <p style={{ textAlign: 'center' }}>
                        Si no puedes acceder comunicate con el 
                        administrador del sitio.
                                        </p>
                        <div style={{ textAlign: 'center' }}>
                        <Link to='/login'>
                            <a className='ps-btn' style={{ color: 'black' }}>
                                Iniciar sesión
                                                </a>
                          </Link>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Failed
