import React, { Component } from 'react';
import DefaultHeader from '../../layout/Header/DefaultHeader'
import { connect } from "react-redux";
import {actions} from '../../../../redux/modules/solicitudes/solicitudes'
import LoadMask from "../../Utils/LoadMask/LoadMask";
import TableSolicitudVendedores from "./TableSolicitudVendedores"
import {Link} from 'react-router-dom';



class Vendedores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            isModalRequested: false,
            isModalPaid:false,
            loader: true
        }
    }

    componentDidMount(){
        this.handleLoader()
        this.props.getVendedores()
    }

    showModal = () => {
        this.setState({isModalVisible:true})
      };

      showModalRequested = () => {
        this.setState({isModalRequested:true})
      };

      showModalPaid = () => {
        this.setState({isModalPaid:true})
      };
    
       handleOk = () => {
        this.setState({isModalVisible:false})
        this.setState({isModalRequested:false})
        this.setState({isModalPaid:false})
      };
    
       handleCancel = () => {
        this.setState({isModalVisible:false})
        this.setState({isModalRequested:false})
        this.setState({isModalPaid:false})


      };

      handleSend = (data) =>{
        this.props.onSubmitSolicitud(data);
      }

      handleLoader = () => {
        setTimeout(()=> this.setState({loader:false}),1000);
    }

render() {

    return (
    <div>
    <DefaultHeader title="Aprobación de vendedores" visible={true}/>
    {this.state.loader?
    <LoadMask loading={this.state.loader} dark>
         
    </LoadMask>
    :
    <div>
    {this.props.data?
    <div className="container">
      <Link 
          to="/vendedores"
          className="ps-btn"
          style={{backgroundColor: "#2e3d50", color: "white"}}
          >
          Regresar
      </Link>
        <hr/>
        <div className="ps-card">
        
        {this.props.vendedores.length>0 ?
        <div>
        <TableSolicitudVendedores info={this.props.vendedores} styled={true}/>
        </div>
        :
        
        <p>En este momento no hay solicitudes de aprobación</p>
        }
    </div>
    </div>

    :null}


</div>
}


</div>

        );
    }
}


const ms2p = (state) => {
    return state.solicitudes;
  };

const md2p = {...actions}
  
export default connect(ms2p ,md2p)(Vendedores);
