import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { renderupdateField, SelectField, renderRUT, renderImageUpload, renderTelefonoFormat } from '../../Utils/renderField/renderField'
import { Modal } from 'antd'
import PasswordChangeForm from './PasswordChangeForm'
import db_comunas from '../../../database/comunas'
import db_regiones from '../../../database/regiones'
import db_provincias from '../../../database/provincias'

const UserForm = (props) => {
  const { handleSubmit, me, change, setAvatar, changePassword } = props

  const [regiones, setRegiones] = useState(db_regiones)
  const [provincias, setProvincias] = useState(db_provincias)
  const [comunas, setComunas] = useState(db_comunas)
  const [filtered_provincias, setFilteredProvincias] = useState([])
  const [filtered_comunas, setFilteredComunas] = useState([])
  const [avatar, setlocalLogo] = useState([])
  const [isModalVisible, setModal] = useState(false)

  const showModal = (e) => {
    e.preventDefault()
    setModal(true)
  }

  const handleOk = (e) => {
    e.preventDefault()
    setModal(false)
  }

  const handleCancel = (e) => {
    e.preventDefault()
    setModal(false)
  }

  const handleCancelForm = (e) => {
    e.preventDefault()
    history.push('/sitio')
  }

  const setImageForm = () => {
    change('imagen', avatar)
  }

  const opt_regiones = []
  const opt_provincias = []
  let opt_comunas = []

  { regiones.length > 0
    ? regiones.map((option) => (
        opt_regiones.push({ value: option.id, label: option.nombre })
      )
      )
    : null
  }

  { provincias.length > 0
    ? provincias.map((option) => (
        opt_provincias.push({ value: option.value, label: option.label })
      ))
    : null
  }

  { comunas.length > 0
    ? comunas.map((option) => (
        opt_comunas.push({ value: option.value, label: option.label })
      ))
    : null
  }

  const handleChangeRegion = (e) => {
    const target = `${e[0]}${e[1]}`
    const filteredData = provincias.filter(entry =>
      entry.region.includes(target)
    )
    const region_texto = regiones.find(entry =>
      entry.id === target
    )
    setFilteredProvincias(filteredData)
    change('region_texto', region_texto.nombre)
  }

  const handleChangeProvincia = (e) => {
    const target = `${e[0]}${e[1]}${e[2]}`
    opt_comunas = []
    const filteredData = comunas.filter(entry =>
      entry.provincia.includes(target)
    )
    const provincia_texto = provincias.find(entry =>
      entry.value === target
    )
    setFilteredComunas(filteredData)
    change('provincia_texto', provincia_texto.label)
  }

  const handleChangeComuna = (e) => {
    const target = `${e[0]}${e[1]}${e[2]}${e[3]}${e[4]}`
    const comuna_texto = comunas.find(entry =>
      entry.value === target
    )
    change('comuna_texto', comuna_texto.label)
  }

  return (
    <div className='pl-5 pr-5 pt-5 pb-5' style={{ backgroundColor: '#ffffff' }}>
      <form onSubmit={handleSubmit}>
        <div>
          <h3 style={{ textAlign: 'center' }}>Configuración de la cuenta</h3>
        </div>
        {/*
        <div className="form-group">

            <label htmlFor="nombres">Imagen de perfil:</label>
            <Field
                name="avatar"
                aspect={1}
                imagen={me.avatar?`${basepath}${me.avatar}`:null}
                setFile={setAvatar}
                component={renderImageUpload}
                />
        </div>
*/}
        <div className='row'>
          <div className='col-sm-6'>
            <div className='form-group'>
              <label htmlFor='email'>Correo electrónico</label>
              <Field
                name='email'
                type='email'
                component={renderupdateField}
              />
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='form-group'>
              <label htmlFor='cliente_direccion'>Dirección</label>
              <Field
                name='cliente_direccion'
                type='text'
                component={renderupdateField}
              />
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='form-group'>
              <label htmlFor='nombres'>Nombre(s)</label>
              <Field
                name='first_name'
                placeholder='Nombre(s)'
                component={renderupdateField}
                type='text'
                className='form-control'
              />
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='form-group'>
              <label htmlFor='nombres'>Apellido(s)</label>

              <Field
                name='last_name'
                placeholder='Apellido(s)'
                component={renderupdateField}
                type='text'
                className='form-control'
              />
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='form-group'>
              <label htmlFor='nombres'>Teléfono</label>
              <Field
                name='cliente_telefono'
                placeholder='Teléfono'
                component={renderTelefonoFormat}
                type='text'
                className='form-control'
              />
            </div>
          </div>
          <div className='col-sm-6'>
            <label htmlFor='nombres'>RUT</label>
            <Field
              name='cliente_rut'
              placeholder='RUT'
              type='text'
              className='form-control'
              component={renderRUT}
              defaultValue={me.cliente_rut}
            />
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='region'>Región</label>
          <Field
            name='cliente_region'
            options={opt_regiones}
            component={SelectField}
            placeholder='Region'
            onChange={handleChangeRegion}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='provincia'>Provincia</label>
          <Field
            name='cliente_provincia'
            options={filtered_provincias.length > 0 ? filtered_provincias : opt_provincias}
            component={SelectField}
            onChange={handleChangeProvincia}
            placeholder='Provincia'
          />
        </div>
        <div className='form-group'>
          <label htmlFor='comuna'>Comuna</label>
          <Field
            name='cliente_comuna'
            options={filtered_comunas.length > 0 ? filtered_comunas : opt_comunas}
            component={SelectField}
            placeholder='Comuna'
            onChange={handleChangeComuna}
          />
        </div>
        <hr />
        <div className='col-sm-6'>
          <div className='form-group submit pt-10 mt-10'>
            <button className='ps-btn' type='submit' onClick={showModal}>Cambiar contraseña</button>
          </div>
        </div>
        <hr />
        <div className='form-group submit pt-10 mt-10' style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Link to='/ajustes' className='ps-btn ps-btn--gray'>
            Cancelar
          </Link>
          <button className='ps-btn' type='submit'>Guardar Cambios</button>
        </div>
      </form>
      <Modal
        title=''
        width={800}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[

        ]}
      >
        <PasswordChangeForm onSubmit={changePassword} me={me} cancel={handleCancel} />
      </Modal>
    </div>

  )
}

export default reduxForm({
  form: 'update_profile', // a unique identifier for this form
  enableReinitialize: true
})(UserForm)
