import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Currency } from 'react-intl-number-format/dist/index.js'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'


const useStyles = makeStyles({
  root: {
    maxWidth: 345
  }
})

const CardSimple = (props) => {
  return (
    <Card style={{ minHeight: '50px', width: '175px' }}>
      <CardContent>
        <div style={{ textAlign: 'center' }}>
          <h4>{props.title}</h4>
          {props.currency
            ? <h3>
              <Currency locale='es-CL' currency='CLP'>{props.description}</Currency>
            </h3>
            : <h3>{props.description}</h3>}
        </div>
      </CardContent>
    </Card>
  )
}

export default CardSimple
