import { handleActions } from "redux-actions";
import { api } from "api";
import { NotificationManager } from "react-notifications";

const actionTypes = {
    SET_HOME: "SET_HOME"
};

export function setHome(home) {
    return {
        type: actionTypes.SET_HOME,
        home
    };
}

export const getHome = () => (dispatch) => {
    api.get("plantilla/1")
        .then((home) => {
            dispatch(setHome(home));
        })
        .catch((err) => console.log(err));
};

export const updateHome = (data) => (dispatch) => {
    api.put("plantilla/1", data)
        .then(() => {
            NotificationManager.success(
                "Satisfactorio",
                "Actilizado satisfactoriamente."
            );
        })
        .catch((err) => {
            let message = "Hubo un error al enviar los datos";
            let description = "Intenta mas tarde";
            NotificationManager.error(message, description);
        });
};

export const actions = {
    getHome,
    updateHome
};

export const reducers = {
    [actionTypes.SET_HOME]: (state, { home }) => {
        return {
            ...state,
            home
        };
    }
};

export const initialState = {
    home: {}
};

export default handleActions(reducers, initialState);
