import React, { Component } from 'react';
import FamiliasList from './FamiliasList';
import CategoriasList from './CategoriasList';
import DefaultHeader from '../layout/Header/DefaultHeader';

class FamiliasCategorias extends Component {
    render() {
        return (
            <React.Fragment>
                <DefaultHeader title="Familias & Categorías" search_bar={false} visible={true}/>
                <FamiliasList />
                <CategoriasList />
            </React.Fragment>
        )
    }
}

export default FamiliasCategorias;