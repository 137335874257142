import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Table, Modal } from "antd";
import { FormControlLabel, Switch } from "@material-ui/core";
import { actions } from "../../../../../redux/modules/sitio/sitio";
import DeleteModal from "../../../Utils/GenericsModals/DeleteModal";

const TableSlider = (props) => {
    const {} = props;
    const history = useHistory();

    const [value, setValue] = useState("");
    const [dataSource, setDataSource] = useState(props.sliders);
    const [isModalVisible, setModal] = useState(false);
    const [confirm, showConfirm] = useState(false);
    const [slider, setInfo] = useState([]);
    const [slider_img, setImagen] = useState([]);
    const [Ok, setOk] = useState(false);
    const [Cancel, setCancel] = useState(false);

    const showModal = () => {
        setModal(true);
    };

    const handleSetSlider = (e, record) => {
        props.setStateSlider(record);
        history.push(`slider/detalle/${record.id}`);
    };

    const handleChange = (e, record) => {
        const data = { id: record.id, action: e.target.checked };
        props.selectSlider(data);
        setDataSource(props.sliders);
    };

    const handleDelete = (e, record) => {
        e.preventDefault();
        setInfo(record);
        showConfirm(true);
    };

    const closeModal = () => {
        showConfirm(false);
    };

    const handleSubmit = () => {
        const data = { slider_id: slider.id };
        props.deleteSlider(data);
        showConfirm(false);
    };

    useEffect(() => {
        setDataSource(props.sliders);
    }, [props.sliders]);

    const columns = [
        {
            title: "Nombre",
            dataIndex: "nombre"
        },
        {
            title: "Cantidad imagenes",
            render: (record) => (
                <React.Fragment>{record.images.length}</React.Fragment>
            )
        },
        {
            title: "PREDETERMINADO",
            dataIndex: "selected",
            render: (selected, record) => (
                <FormControlLabel
                    control={
                        <Switch
                            checked={selected}
                            onClick={(e) => {
                                handleChange(e, record);
                            }}
                            name="checkedB"
                            color="primary"
                        />
                    }
                />
            )
        },
        {
            title: "-",
            render: (record) => (
                <div className="wrap justify-content-evenly">
                    <div>
                        <button
                            className="ps-btn ps-btn--opt"
                            onClick={(e) => handleSetSlider(e, record)}
                        >
                            <i
                                className="icon-eye"
                                style={{ fontWeight: "600" }}
                            />
                        </button>
                    </div>
                    <div>
                        <button
                            className="ps-btn ps-btn--opt"
                            onClick={(e) => handleDelete(e, record)}
                        >
                            <i
                                className="icon-trash2"
                                style={{ fontWeight: "600" }}
                            />
                        </button>
                    </div>
                </div>
            )
        }
    ];

    return (
        <div>
            <div className="form-group">
                {props.styled ? (
                    <React.Fragment>
                        <Table
                            dataSource={dataSource}
                            columns={columns}
                            className="table ps-table--market"
                            rowKey={(record) => record.id}
                        />
                    </React.Fragment>
                ) : (
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        rowKey={(record) => record.id}
                    />
                )}
                <DeleteModal
                    visible={confirm}
                    closeModal={closeModal}
                    handleSubmit={handleSubmit}
                    size={600}
                    item_name={slider.nombre}
                />
            </div>
        </div>
    );
};

const md2p = { ...actions };

export default connect(null, md2p)(TableSlider);
