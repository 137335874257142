import React, { useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { renderField } from '../../Utils/renderField/renderField'
import validate, {password, passwordsMustMatch} from './validate_change'

const PasswordChangeForm = props => {
  const { handleSubmit, pristine, submitting, cancel, valid } = props

  const [is_valid, setValidator] = useState(true)

//   const handleClick = (e) => {
//     e.preventDefault()
//     router.push('/')
//   }

  return (
    <div>
      {is_valid
        ? <React.Fragment>
          <form onSubmit={handleSubmit} className='ps-form--account'>
            <div className='ps-form__footer navigation mb-0 pb-0'>
              {/* LOGO HERE! */}
            </div>
            <div className='ps-tab active' id='register'>
              <div className='ps-form__content'>
                <h4 style={{ textAlign: 'center' }}>Cambio de contraseña</h4>
                <hr />
                <div className='form-group'>
                  <Field
                    className='form-control'
                    name='password'
                    type='password'
                    component={renderField}
                    validate={password}
                    placeholder='Nueva contraseña'
                  />
                </div>
                <div className='form-group'>
                  <Field
                    name='password_confirmation'
                    type='password'
                    component={renderField}
                    placeholder='Confirmación de contraseña'
                  />
                </div>
              </div>
              <div>
                <div>
                <button
                  className={
                    pristine || submitting || !valid
                      ? 'ps-btn ps-btn--fullwidth'
                      : 'ps-btn ps-btn--fullwidth'
                  }
                  style={{
                      backgroundColor: pristine || submitting || !valid  ? '#ccc' : '',
                      cursor: pristine || submitting || !valid  ? 'not-allowed' : '',
                      color: pristine || submitting || !valid  ? '#000' : '' }}
                  type='submit'
                  disabled={pristine || submitting || !valid }
                  >
                  Cambiar
                </button>
                </div>
                <br />
                <button type='submit' className='ps-btn ps-btn--gray ps-btn--fullwidth' onClick={cancel}>
                  Cancelar
                </button>
                <hr />
              </div>
            </div>
          </form>
          </React.Fragment>
        : null}
    </div>

  )
}

export default reduxForm({
  form: 'change_password', // <------ same form name
  validate
})(PasswordChangeForm)
