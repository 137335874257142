import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Table, Modal } from "antd";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import DetalleProducto from "./DetalleProducto";
import Swal from 'sweetalert2';
import moment from "moment";

const TableProductos = (info, styled) => {
    const history = useHistory();
    const [value, setValue] = useState("");
    const [desde, setDate1] = useState(
        moment().clone().startOf("month").format("YYYY-MM-DD")
    );
    const [dataSource, setDataSource] = useState(info.info);
    const [isModalVisible, setModal] = useState(false);
    const [producto, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleOk = () => {
        setOk(false);
        setModal(false);
        setInfo([]);
    };

    const handleCancel = () => {
        setCancel(false);
        setModal(false);
        setInfo([]);
    };

    const ESTADOS = ["-", "SOLICITADO", "FINALIZADA"];

    const filter_status = [];

    ESTADOS.map((status, index) =>
        filter_status.push({
            text: status,
            value: index * 10
        })
    );

    const handleChange = (e, record) => {
        const data = { id: record.id, action: e.target.checked };
        setLoading(true);
        info.aprobarProducto1(data);
        setTimeout(() => {
            info.handleLoader();
            info.getCatalogo();
            setLoading(false);
        }, 1500);
    };

    const handleDelete = (e, record) => {
        setLoading(true);
        info.deleteProductoLocal({id: record.id});
        setTimeout(() => {
            info.handleLoader();
            info.getCatalogo();
            setLoading(false);
        }, 1500);
    }

    useEffect(() => {
        setDataSource(info.info);
    }, [info.info]);

    const handleDetail = (e, record) => {
        history.push(`producto/detalle/${record.id}`);
    };

    const columns = [
        {
            title: "No.",
            key: "id",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
            showSorterTooltip: false
        },
        {
            title: "Tiendas que lo venden",
            sorter: (a, b) => a.vendedores - b.vendedores,
            showSorterTooltip: false,
            render: (record) => <p>{record.vendedores}</p>
        },
        {
            title: "Nombre del producto / Envase",
            key: "id",
            render: (record) => (
                <p>
                    {record.nombre_comercial} - {record.envase}{" "}
                    {record.unidad_medida_texto}
                </p>
            )
        },
        {
            title: "Estado",
            dataIndex: "activo",
            key: "id",
            render: (activo, record) => (
                <FormControlLabel
                    control={
                        <Switch
                            checked={activo}
                            onClick={(e) => {
                                handleChange(e, record);
                            }}
                            name="checkedB"
                            color="primary"
                        />
                    }
                />
            )
        },
        {
            title: "-",
            render: ( activo, record) => (
                <div className="wrap justify-content-evenly">
                    <div>
                        <button
                            className="ps-btn ps-btn--opt"
                            onClick={(e) => handleDetail(e, record)}
                        >
                            <i
                                className="icon-eye"
                                style={{ fontWeight: "600" }}
                            />
                        </button>
                    </div>
                    <div>
                        <button
                            className="ps-btn ps-btn--opt"
                            style={{
                                backgroundColor: activo.activo ? '#ccc' : '',
                                cursor: activo.activo ? 'not-allowed' : '',
                                color: activo.activo ? '#000' : ''}
                            }
                            disabled={activo.activo}
                            onClick={(e) => {
                                Swal.fire({
                                    title: '¿Eliminar?',
                                    text: '¡No podrá revertir esta acción!',
                                    type: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: '¡Sí, eliminar!',
                                    cancelButtonText: 'No, cancelar',
                                    reverseButtons: true,
                                  }).then((result) => {
                                      if (result.value) {
                                        handleDelete(e, record);
                                      }
                                  });
                            }}
                        >
                            <i
                                className="icon-trash2"
                                style={{
                                    fontWeight: "600",
                                }}
                            />
                        </button>
                    </div>
                </div>
            )
        }
    ];

    return (
        <div>
            <div className="form-group">
                {styled ? (
                    <React.Fragment>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center"
                            }}
                        >
                            <div>
                                <Link to="/migracion-ap" className="ps-btn">
                                    Importar
                                    <i className="icon-sync pl-3" />
                                </Link>
                            </div>
                            <div>
                                <Link
                                    to="/aprobacion-productos"
                                    className="ps-btn"
                                >
                                    Aprobaciones ({info.solicitudes})
                                </Link>
                            </div>
                            <div>
                                <Link to="/producto-nuevo" className="ps-btn">
                                    Nuevo +
                                </Link>
                            </div>
                        </div>
                        <hr />
                        <section
                            className="ps-dashboard"
                        >
                            <div>

                                <div className="col-12 pl-0" style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    margin: '0',
                                    padding: '0'
                                    }}>
                                    <div className="ps-form--search-simple" >
                                        <input
                                            placeholder="Buscar..."
                                            className="form-control"
                                            value={value}
                                            onChange={(e) => {
                                                const currValue =
                                                    e.target.value;
                                                setValue(currValue);
                                                const filteredData =
                                                    info.info.filter((entry) =>
                                                        entry.nombre_comercial
                                                            .toLowerCase()
                                                            .includes(
                                                                currValue.toLowerCase()
                                                            )
                                                    );
                                                setDataSource(filteredData);
                                            }}
                                        ></input>
                                        <button>
                                            <i className="icon icon-magnifier" />
                                        </button>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </section>
                        <Table
                            dataSource={dataSource}
                            loading={loading}
                            columns={columns}
                            className="table ps-table--market"
                        />
                    </React.Fragment>
                ) : (
                    <Table
                        dataSource={dataSource}
                        loading={loading}
                        columns={columns}
                        rowKey="id"
                    />
                )}
            </div>
            <Modal
                title=""
                width={800}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[]}
            >
                <DetalleProducto producto={producto} />
            </Modal>
        </div>
    );
};

export default TableProductos;
