import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { renderImageUpload } from '../../../../components/Utils/renderField/renderField'

const BannerForm = (props) => {
  const { handleSubmit, banner, setBanner } = props


  return (
    <div>
      <div className='container'>
        <h4 style={{ textAlign: 'center' }}>Cambiando banner: {banner.nombre}</h4>
        <form onSubmit={handleSubmit}>

          <div className='container' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div className='form-group col-8 pt-10'>
              <label>-Haz clic sobre el recuadro para seleccionar una imagen.</label>
              <Field
                name='imagen'
                imagen={banner.imagen ? banner.imagen : null}
                aspect={3}
                setFile={setBanner}
                component={renderImageUpload}
              />
            </div>

          </div>

          <div className='container mt-20 pt-20' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button type='submit' className='ps-btn btn-warning'>Guardar</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'cambiar_banner' // a unique identifier for this form

})(BannerForm)
