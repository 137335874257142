import { combineReducers } from 'redux'
import { routerReducer as routing } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import login from './modules/cuenta/login'
import register from './modules/cuenta/register'
import profile from './modules/cuenta/profile'
import usuarios from './modules/usuarios/usuarios'
import notificaciones from './modules/notificaciones/notificaciones'
import solicitudes from './modules/solicitudes/solicitudes'
import productos from './modules/productos/productos'
import sitio from './modules/sitio/sitio'
import logs from './modules/logs/logs';
import familias from './modules/familias/familias';
import categorias from './modules/categorias/categorias';
import plantilla from './modules/plantilla/plantilla';

export default combineReducers({
  form: formReducer,
  login,
  register,
  profile,
  usuarios,
  routing,
  notificaciones,
  solicitudes,
  productos,
  sitio,
  logs,
  familias,
  categorias,
  plantilla
})
