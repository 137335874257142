import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import {
    renderImageUpload,
    renderField
} from "../../../../Utils/renderField/renderField";
import { addImageSlider } from "../../../../../../redux/modules/sitio/sitio";
import { Link } from "react-router-dom";

let SliderImageForm = (props) => {
    const { handleSubmit, image, setImage } = props;

    return (
        <form className="form" onSubmit={handleSubmit}>
            <hr />
            <div className="ps-form__content">
                <figure className="ps-block--form-box">
                    <figcaption></figcaption>
                    <div className="ps-block__content">
                        <br />
                        <div className="form-group">
                            <label>
                                -Haz clic sobre el recuadro para seleccionar una
                                imagen.
                            </label>
                            <Field
                                name="imagen"
                                imagen={image ? image : null}
                                aspect={3}
                                setFile={setImage}
                                component={renderImageUpload}
                            />
                        </div>
                        {/*
                        <div className="form-group col-12 pt-10">
                            <label htmlFor="">Pagina a redirigir</label>
                            <Field
                                className="form-control"
                                name="url"
                                component={renderField}
                                placeholder="¿A que pagina deseas redirigir esta imagen?"
                                required
                            />
                        </div>
                        */}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <div>
                                <Link
                                    to="/sliders"
                                    className="ps-btn ps-btn--gray"
                                >
                                    Cancelar
                                </Link>
                            </div>
                            <button type="submit" className="ps-btn">
                                Guardar
                            </button>
                        </div>
                    </div>
                </figure>
            </div>
        </form>
    );
};

SliderImageForm = reduxForm({
    form: "slider_form"
})(SliderImageForm);

const md2p = { addImageSlider };

export default connect(null, md2p)(SliderImageForm);
