import { handleActions } from 'redux-actions';
import { api } from 'api';

const actionTypes = {
    SET_LOGS: 'SET_LOGS',
    SET_LOG: 'SET_LOG',
    SET_METHODS: 'SET_METHODS'
}

export function setLogs (logs) {
    return {
      type: actionTypes.SET_LOGS,
      logs
    }
};

export function setLog (log) {
  return {
    type: actionTypes.SET_LOG,
    log
  }
}

export function setMethods (methods) {
  return {
    type: actionTypes.SET_METHODS,
    methods
  }
}

export const getLogs = (params) => (dispatch) => {
    api.get('logs/' + params).then(logs => {
      dispatch(setLogs(logs))
    }).catch(err => console.log(err));
}

export const getLog = (id) => (dispatch) => {
  api.get('logs/' + id).then(log => {
    dispatch(setLog(log))
  }).catch(err => console.log(err));
}

export const getMethods = () => (dispatch) => {
  api.get('logs/get_methods').then(methods => {
    dispatch(setMethods(methods))
  }).catch(err => console.log(err));
}

export const actions = {
    getLogs,
    getLog,
    getMethods
}

export const reducers = {
    [actionTypes.SET_LOGS]: (state, { logs }) => {
      return {
        ...state,
        logs
      }
    },
    [actionTypes.SET_LOG]: (state, { log }) => {
      return {
        ...state,
        log
      }
    },
    [actionTypes.SET_METHODS]: (state, { methods }) => {
      return {
        ...state,
        methods
      }
    },
}

export const initialState = {
    logs: [],
    log: {},
    methods: []
}

export default handleActions(reducers, initialState)