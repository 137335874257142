import React, { Component } from "react";
import { connect } from "react-redux";
import { getLogs, getMethods } from "../../../redux/modules/logs/logs";
import "../../../../style/scss/custom/BitacoraList.scss";
import { Link } from "react-router-dom";
import LoadMask from "../Utils/LoadMask/LoadMask";
import DefaultHeader from "../layout/Header/DefaultHeader";

class BitacoraList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            params: `&page=1&limit=10`,
            select_value: 0,
            valor: "",
            loader: true
        };
    }

    componentDidMount() {
        this.handleLoader();
        this.props.getMethods();
        this.props.getLogs(
            "?method=" + this.state.select_value + this.state.params
        );
    }

    handleLoader = () => {
        setTimeout(() => this.setState({ loader: false }), 1000);
    };

    getMethodLogs(e) {
        let metodo = parseInt(e.target.value);
        let params = `?method=${metodo}&page=1&limit=10`;
        this.setState({ select_value: metodo, params });
        this.props.getLogs(params);
    }

    getRange = (inicio, final) => {
        let rango = [];
        for (let i = inicio; i < final + 1; i++) {
            rango.push(i);
        }
        return rango;
    };

    searchLog = async (e) => {
        this.setState({ valor: e.target.value }, () => {
            this.paginator(1, 0);
        });
    };

    paginator = (page, pagina_actual) => {
        if (page != pagina_actual) {
            let params = "";
            if (this.state.valor) {
                params = `&search=${this.state.valor}`;
            }
            params = `?method=${this.state.select_value}${params}&page=${page}&limit=10`;
            this.setState({ params }, () => {
                this.props.getLogs(this.state.params);
            });
        }
    };

    render() {
        const response = this.props.logs.logs;
        const metodos = this.props.logs.methods;
        const data = response.data;
        const total = response.meta ? response.meta.page.total : 0;
        const anterior = response.links ? response.links.previos : 0;
        const primera = response.links ? response.links.first : 0;
        const ultima = response.links ? response.links.last : 0;
        const pagina_actual = response.meta
            ? response.meta.page.current_page
            : 0;
        const rango = this.getRange(primera, ultima);

        return (
            <div>
                <div className="container">
                    <DefaultHeader title={`Log (${total})`} search_bar={false} visible={true}/>
                    {this.state.loader ? (
                        <LoadMask loading={this.state.loader} dark></LoadMask>
                    ) : (
                        <React.Fragment>
                            <div className="row d-flex justify-content-center mb-3">
                                <div className="col-12">
                                    <nav
                                        className="navbar navbar-light justify-content-between"
                                        style={
                                            {
                                                padding: "0px"
                                            }
                                        }
                                    >
                                        <div className="form-inline">
                                            {metodos ? (
                                                <React.Fragment>
                                                    <label>
                                                        Método:
                                                    </label>
                                                    <select
                                                        value={
                                                            this.state
                                                                .select_value
                                                        }
                                                        style={{
                                                            marginRight: "5px"
                                                        }}
                                                        className="form-control"
                                                        onChange={(e) =>
                                                            this.getMethodLogs(
                                                                e
                                                            )
                                                        }
                                                    >
                                                        {metodos.length > 0 ? (
                                                            metodos.map(
                                                                (e, index) => (
                                                                    <option
                                                                        value={
                                                                            e.id
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            e.nombre
                                                                        }
                                                                    </option>
                                                                )
                                                            )
                                                        ) : (
                                                            <option>
                                                                No hay datos
                                                            </option>
                                                        )}
                                                    </select>
                                                </React.Fragment>
                                            ) : null}
                                        </div>
                                        <div className="ps-form--search-simple">
                                            <input
                                                placeholder="Buscar..."
                                                className="form-control"
                                                value={this.state.valor}
                                                onChange={(e) =>
                                                    this.searchLog(
                                                        e,
                                                        pagina_actual
                                                    )
                                                }
                                            ></input>
                                            <button>
                                                <i className="icon icon-magnifier" />
                                            </button>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <table className="table custom--table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">
                                                    Tipo Usuario
                                                </th>
                                                <th scope="col">Usuario</th>
                                                <th scope="col">Método</th>
                                                <th scope="col">
                                                    Fecha de creación
                                                </th>
                                                <th scope="col">Detalle</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data ? (
                                                data.length > 0 ? (
                                                    data.map(
                                                        (element, index) => (
                                                            <tr key={index}>
                                                                <td scope="row">
                                                                    {element.id}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        element.usuario_tipo
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        element
                                                                            .usuario
                                                                            .username
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        element.metodo
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        element.created_at
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        title="Detalle"
                                                                        className="btn button--color"
                                                                    >
                                                                        <Link
                                                                            to={`/bitacora/${element.id}`}
                                                                        >
                                                                            <i
                                                                                className="icon-eye"
                                                                                style={{
                                                                                    color: "white"
                                                                                }}
                                                                            ></i>
                                                                        </Link>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                ) : (
                                                    <tr>
                                                        <th
                                                            colSpan="4"
                                                            style={{
                                                                textAlign:
                                                                    "center"
                                                            }}
                                                        >
                                                            No hay datos.
                                                        </th>
                                                    </tr>
                                                )
                                            ) : (
                                                <tr>
                                                    <th
                                                        colSpan="4"
                                                        style={{
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        No hay datos.
                                                    </th>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 d-flex justify-content-center">
                                    {data && data.length > 0 ? (
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <li
                                                    className={`page-item ${
                                                        anterior == 0
                                                            ? "disabled"
                                                            : ""
                                                    }`}
                                                >
                                                    <a
                                                        className="page-link"
                                                        onClick={() =>
                                                            this.paginator(
                                                                anterior,
                                                                pagina_actual
                                                            )
                                                        }
                                                    >
                                                        &laquo;
                                                    </a>
                                                </li>
                                                {rango.map((e, index) => (
                                                    <li
                                                        key={index}
                                                        className={`page-item ${
                                                            pagina_actual == e
                                                                ? "active"
                                                                : ""
                                                        }`}
                                                    >
                                                        <a
                                                            className="page-link"
                                                            onClick={() =>
                                                                this.paginator(
                                                                    e,
                                                                    pagina_actual
                                                                )
                                                            }
                                                        >
                                                            {e}
                                                        </a>
                                                    </li>
                                                ))}
                                                <li
                                                    className={`page-item ${
                                                        pagina_actual == ultima
                                                            ? "disabled"
                                                            : ""
                                                    }`}
                                                >
                                                    <a
                                                        className="page-link"
                                                        onClick={() =>
                                                            this.paginator(
                                                                ultima,
                                                                pagina_actual
                                                            )
                                                        }
                                                    >
                                                        &raquo;
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    ) : null}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}

const ms2p = (state) => {
    return state;
};

const md2p = { getLogs, getMethods };

export default connect(ms2p, md2p)(BitacoraList);
