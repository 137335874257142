import React, { Component } from "react";
import { Link } from "react-router-dom";

import FooterThank from "../../../../components/shared/footer/FooterThank";

class Sucess extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        console.log(this.props);
        return (
            <div style={{ height: "100vh", backgroundColor: "#f1f1f1" }}>
                <div className="register-photo ">
                    <div
                        className="form-container w-75 "
                        style={{ height: "54vh" }}
                    >
                        {/* CAMBIAR ESTE LINK EN PRODUCION */}
                        <a
                            href="https://test127.ciancoders.com/"
                            className="image-holder"
                        ></a>
                        <div
                            className="ps-tab active h-100 align-items-center "
                            style={{
                                backgroundColor: "#fff"
                            }}
                        >
                            <div className="ps-form__content  p-5 ">
                                <div className="px-5 template-content">
                                    <h1
                                        className="text-center"
                                    >
                                        Cuenta activada
                                    </h1>
                                    <p className="text-sec">
                                        Hola, {} hemos activado tu cuenta. Ya
                                        puedes acceder al panel de
                                        Administración de Agromall. Para
                                        ingresar al modulo de administrador, haz
                                        click en el botón de abajo
                                    </p>
                                    <Link to="/login">
                                        <button
                                            className="ps-btn ps-btn--fullwidth mb-5 mt-3 p-4"
                                        >
                                            INICIAR SESIÓN
                                        </button>
                                    </Link>
                                    <p className="text-sec">
                                        Muchas gracias por ser parte de la
                                        comunidad de Agromall
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="ps-footer">
                    <div className="ps-container text-center">
                        <FooterThank />
                    </div>
                </footer>
            </div>
        );
    }
}

export default Sucess;
