import React, { Component } from "react";
import { connect } from "react-redux";
import { getLog } from "../../../redux/modules/logs/logs";
import DefaultHeader from "../layout/Header/DefaultHeader";
import LoadMask from "../Utils/LoadMask/LoadMask";
import { Link } from 'react-router-dom'

class BitacoraGet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }

    componentDidMount() {
        this.handleLoader();
        this.props.getLog(this.props.match.params.id);
    }

    handleLoader = () => {
        setTimeout(() => this.setState({ loader: false }), 1000);
    };

    render() {
        let descripcion = this.props.logs.log.descripcion;
        if (descripcion) {
            descripcion = descripcion.replaceAll("True", "true");
            descripcion = descripcion.replaceAll("False", "false");
            descripcion = descripcion.replaceAll("None", "null");
        }

        return (
            <div>
                <div className="container-fluid">
                    <DefaultHeader title="Detalle de Log" search_bar={false} visible={true}/>
                    {this.state.loader ? (
                        <LoadMask loading={this.state.loader} dark></LoadMask>
                    ) : (
                        <React.Fragment>
                            <div className="row mb-5">
                                <div className="col-12" align="center">
                                    {this.props.logs.log.metodo ? (
                                        <div
                                            className="card text-center"
                                            style={{ width: "100rem" }}
                                        >
                                            <div className="card-header">
                                                Método:{" "}
                                                {this.props.logs.log.metodo}
                                            </div>
                                            <div className="card-body">
                                                <h5 className="card-title">
                                                    {
                                                        this.props.logs.log
                                                            .created_at
                                                    }
                                                </h5>
                                                <p className="card-text">
                                                    Tipo de usuario:{" "}
                                                    {
                                                        this.props.logs.log
                                                            .usuario_tipo
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="card text-center"
                                            style={{ width: "100rem" }}
                                        >
                                            <div className="card-header">
                                                No hay concidencias
                                            </div>
                                            <div className="card-body">
                                                <p className="card-text">
                                                    Lo sentimos, este log no
                                                    existe.
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {this.props.logs.log.metodo ? (
                                <div className="row">
                                    <div className="col-12">
                                        <ul className="list-group">
                                            <li className="list-group-item list-group-item-dark">
                                                Datos registrados:{" "}
                                            </li>
                                            {Object.entries(
                                                JSON.parse(descripcion)
                                            ).map((elemento, index) => (
                                                <li
                                                    className="list-group-item"
                                                    key={index}
                                                >
                                                    <h4
                                                        style={{
                                                            fontWeight: "bold"
                                                        }}
                                                    >
                                                        {elemento[0]}:
                                                    </h4>
                                                    <pre>
                                                        {JSON.stringify(
                                                            elemento[1],
                                                            undefined,
                                                            2
                                                        )}
                                                    </pre>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ) : null}
                        </React.Fragment>
                    )}
                </div>
                {
                    !this.state.loader ?
                    <div style={{padding: "1rem", display:"flex",justifyContent: "end"}}>
                        <Link to="/bitacora-list">
                            <div className='ps-btn' style={{backgroundColor: "#2e3d50", color: "white"}} >
                                Regresar
                            </div>
                        </Link>
                    </div> :""
                }
               
            </div>
        );
    }
}

const ms2p = (state) => {
    return state;
};

const md2p = { getLog };

export default connect(ms2p, md2p)(BitacoraGet);
