import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Currency } from 'react-intl-number-format/dist/index.js'
import { Table, Modal } from 'antd'
import RetiroForm from './RetiroForm'
import { connect } from 'react-redux'
import { aprobarPago, pagarSolicitud, setSolicitudes, getSolicitudes } from '../../../redux/modules/solicitudes/solicitudes'
import LoadMask from "../Utils/LoadMask/LoadMask";
import { api } from '../../../utility/api'

import moment from 'moment'

const TableActividad = (props) => {
  const [value, setValue] = useState('')
  const [desde, setDate1] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'))
  const [hasta, setDate2] = useState(moment().format())
  const [dataSource, setDataSource] = useState(props.info)
  const [isModalVisible, setModal] = useState(false)
  const [seleccionado, setSeleecionado] = useState([])
  const [statusid, setStatusid] = useState(10)
  const [comprobante, setComprobante] = useState([])
  const [solicitud, setInfo] = useState([])
  const [Ok, setOk] = useState(false)
  const [Cancel, setCancel] = useState(false)
  const [onChange, setOnChange] = useState(false)

  const showModal = () => {
    setModal(true)
  }

  const handleOk = () => {
    setOk(false)
    setModal(false)
    setInfo([])
  }

  const handleSetComprobante = (imagen) => {
    setComprobante(imagen)
  }

  const handleCancel = () => {
    setCancel(false)
    setModal(false)
    setInfo([])
  }

  const handleSend = (data) => {
    const imagen = { name: 'comprobante', file: comprobante }
    data.comprobante = imagen
    // props.pagarSolicitud(data)
    let solicitud = dataSource.find(item => item.id === data.id)
    if (solicitud) {
      data.monto_transferido = solicitud.monto_solicitado - solicitud.comision_total
      props.pagarSolicitud(data)
    }else{
        console.log('no existe')
    }
    handleOk()
  }

  const handleAprobar = (data) => {
    let solicitud = dataSource.find(item => item.id === data.id)
    if (solicitud) {
      data.monto_transferido = solicitud.monto_solicitado - solicitud.comision_total
      props.aprobarPago(data)
    }else{
        console.log('no existe')
    }
    getSolicitudes()
    handleOk()
  }

  const ESTADOS = [
    '-',
    'RECIBIDA',
    'ACEPTADA',
    '-',
    'DEPOSITADA'
  ]

  const [loading, setLoading] = useState(true)
  const setData = (comisionData) => {
    let resetData = [];
    dataSource.map((movimiento, index) => {
        resetData.push({
            ...movimiento,
            comision_total: comisionData[index].comision_total,
        });
        }
    )
    const resetData2 = resetData.filter((movimiento) => {
        return movimiento.status == statusid
    })
    setDataSource(resetData2);
  }

  function calculateComisiones(callback) {
    let data = {id_cotizacion: [], id_tienda: []}
    dataSource.map((movimiento) =>{
        data.id_cotizacion.push(movimiento.venta)
        data.id_tienda.push(movimiento.tienda)
    })
    setLoading(true)
    api.post(`/categorias-feeds-commissions/calcule_comisions`, data)
        .then((res) => {
            callback(res)
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false)
        });
  };

  const filter_status = []

  ESTADOS.map((status, index) => (
    filter_status.push(
      {
        text: status,
        value: (index * 10)
      })
  ))

  const filter_estados = [
    { id: 0, nombre: '-', color: 'default' },
    { id: 1, nombre: 'SOLICITADO', color: 'orange' },
    { id: 4, nombre: 'DEPOSITADO', color: 'success' }
  ]

  const handleSetFilter = () => {
    const inicio = new Date(desde).getTime()
    const final = new Date(hasta).getTime()

    const resultData = props.info.filter(obj => {
      console.log(obj)
      const time = new Date(obj.fecha_creacion).getTime()
      return (inicio < time && time < final)
    })
    setDataSource(resultData)
  }


  const columns = [
    {
      title: 'No.',
      key: 'id',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      showSorterTooltip: false
    },
    {
      title: 'Vendedor',
      dataIndex: 'tienda_nobre',
      key: 'tienda_nobre',
      sorter: (a, b) => a.tienda_nobre.localeCompare(b.tienda_nobre),
      showSorterTooltip: false,
      render: tienda_nobre => (
        <p>
          {tienda_nobre}
        </p>
      )
    },
    {
      title: 'Fecha Solicitud',
      dataIndex: 'fecha_creacion',
      key: 'fecha_creacion',
      sorter: (a, b) => moment(a.fecha_creacion).unix() - moment(b.fecha_creacion).unix(),
      showSorterTooltip: false,
      render: fecha_creacion => (
        <p>
          {moment(fecha_creacion).format('L')} - ({moment(fecha_creacion).fromNow()})
        </p>
      )
    },
    {
      title: 'Monto solicitado',
      dataIndex: 'monto_solicitado',
      key: 'monto_solicitado',
      render: monto_solicitado => (
        <p>
          <Currency locale='es-CL' currency='CLP'>{monto_solicitado}</Currency>
        </p>
      )
    },
    {
      title: 'A depositar',
      dataIndex: 'monto_transferido',
      key: 'monto_transferido',
      render: (monto_transferido, record) => (
        <p>
          {monto_transferido > 0
            ? <Currency locale='es-CL' currency='CLP'>{monto_transferido}</Currency>
            : <Currency locale='es-CL' currency='CLP'>
              {record.monto_solicitado - record.comision_total}
            </Currency>}
        </p>
      )
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      filters: filter_status,
      onFilter: (value, record) => record.status === value,
      render: status => (
        <p>
          {ESTADOS[status / 10]}
        </p>
      )
    },
    {
      title: '-',
      render: (record) => (
        <React.Fragment>
          <button
            className='ps-btn' onClick={e => {
              setInfo(record),
              showModal(true)
              props.getSolicitudes()
            }}
          >
            Ver
          </button>
        </React.Fragment>
      )
    }
  ]

  const handleSetActive = (ruta) => {
    setSolicitudes([])
    setDataSource([])
    setOnChange(!onChange)
    setSeleecionado(`${ruta.url}`)
    const status = ruta.id
    setStatusid(status)
    const filteredData = props.info.filter(entry =>
      entry.status === parseInt(ruta.id)
    )
    setDataSource(filteredData)
  }

  const menuItems = [
    {
      text: 'Recibidas',
      url: '/solicitudes',
      icon: 'icon-home',
      id: '10'

    },
    {
      text: 'Aprobadas',
      url: '/solicitudes#aprobadas',
      icon: 'icon-database',
      id: '20'
    },
    {
      text: 'Finalizadas',
      url: '/solicitudes#finalizadas',
      icon: 'icon-database',
      id: '40'
    }
  ]

  useEffect(() => {
    getSolicitudes()
    calculateComisiones(setData);
  }, [props.info, onChange])

  return (
    <LoadMask  loading={loading} blur={true}>
    <div>
      <div className='form-group'>
        {props.styled
          ? <React.Fragment>
            <ul className='menu-horizontal'>
              {menuItems.map((item, index) => (
                <li key={index} className={window.location.hash.slice(1) === item.url ? 'active__item' : ''}>
                  <Link
                    to={item.url} onClick={handleSetActive.bind(
                    this,
                    item
                  )}
                  >
                    {item.text}
                  </Link>
                </li>
              ))}
            </ul>
            <section className='ps-dashboard'>
              <div className='container' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <div className='col-4'>
                  <label htmlFor='estados'>Desde:</label>
                  <input
                    type='date'
                    className='form-control'
                    name='fecha_i'
                    value={desde}
                    onChange={e => {
                    const currValue = e.target.value
                    setDate1(currValue)
                  }}
                  />

                  <hr />
                </div>
                <div className='col-4'>
                  <label htmlFor='estados'>Hasta:</label>
                  <input
                    type='date'
                    className='form-control'
                    name='fecha_f'
                    value={moment(hasta).format('YYYY-MM-DD')}
                    onChange={e => {
                    const currValue = e.target.value
                    setDate2(currValue)
                  }}
                  />
                  <hr />
                </div>
                <div>
                  <button className='ps-btn xs' onClick={handleSetFilter}>Filtrar</button>
                </div>

              </div>
            </section>

            <Table dataSource={dataSource} columns={columns} className='table ps-table--market' />

            </React.Fragment>
          : <Table dataSource={dataSource} columns={columns} />}
      </div>
      <div className='form-group' />
      {solicitud.status === 10
        ? <Modal width={800} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer=''>
          <RetiroForm solicitud={solicitud} onSubmit={handleAprobar} title='Aprobar pago' button='Aprobar' />
        </Modal>
        : <Modal width={800} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer=''>
          <RetiroForm
            solicitud={solicitud}
            onSubmit={handleSend}
            title='Confirmar deposito'
            aprobado
            setImagen={handleSetComprobante}
            button='Depositar'
          />
        </Modal>}
    </div>
    </LoadMask>
  )
}

const md2p = { pagarSolicitud, aprobarPago, getSolicitudes }

export default connect(null, md2p)(TableActividad)
