import React, { Component } from "react";
import DefaultHeader from "../../../layout/Header/DefaultHeader";
import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/sitio/sitio";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import TableSlider from "./TableSlider";
import { Link } from "react-router-dom";

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            isModalRequested: false,
            isModalPaid: false,
            loader: true,
            create: false
        };
    }

    componentDidMount() {
        this.handleLoader();
        this.props.getSlider();
    }

    showModal = () => {
        this.setState({ isModalVisible: true });
    };

    handleOk = () => {
        this.setState({ isModalVisible: false });
    };

    handleCancel = () => {
        this.setState({ isModalVisible: false });
    };

    handleLoader = () => {
        setTimeout(() => this.setState({ loader: false }), 1000);
    };

    handleCreate = () => {
        this.setState({ create: true });
    };

    render() {
        const { slider } = this.props.sitio;

        if (this.state.loader) {
            return (
                <React.Fragment>
                    <DefaultHeader title="Sliders del sitio" visible={true} />
                    <LoadMask loading={this.state.loader} dark />
                </React.Fragment>
            );
        }
        return (
            <div>
                <DefaultHeader title="Sliders del sitio" visible={true} />
                <div>
                    <div className="container">
                        <div className="ps-card">
                            <div>
                                {slider.length > 0 ? (
                                    <React.Fragment>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                        >
                                            <div>
                                                <Link
                                                    to="/sitio"
                                                    className="ps-btn"
                                                    style={{
                                                        backgroundColor:
                                                            "#2e3d50",
                                                        color: "white"
                                                    }}
                                                >
                                                    Regresar
                                                </Link>
                                            </div>
                                            <div>
                                                <Link
                                                    to="/slider/nuevo"
                                                    className="ps-btn"
                                                >
                                                    Nuevo +
                                                </Link>
                                            </div>
                                        </div>
                                        <hr/>
                                        <TableSlider
                                            sliders={slider}
                                            styled={true}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                        >
                                            <div>
                                                <Link
                                                    to="/sitio"
                                                    className="ps-btn"
                                                    style={{
                                                        backgroundColor:
                                                            "#2e3d50",
                                                        color: "white"
                                                    }}
                                                >
                                                    Regresar
                                                </Link>
                                            </div>
                                            <div>
                                                <Link
                                                    to="/slider/nuevo"
                                                    className="ps-btn"
                                                >
                                                    Nuevo +
                                                </Link>
                                            </div>
                                        </div>
                                        <hr />
                                        <div
                                            className="alert alert-secondary mt-20"
                                            role="alert"
                                            style={{
                                                textAlign: "center"
                                            }}
                                        >
                                            <p>
                                                No hay sliders agregados en este
                                                momento...
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const ms2p = (state) => {
    return state;
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Slider);
