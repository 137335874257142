import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Table, Modal } from 'antd'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import { actions } from '../../../../redux/modules/cuenta/profile'
import AsociadoDisable from './AsociadoDisable'
import { NotificationManager } from 'react-notifications'

const TableAdministradores = (props) => {
  const [dataSource, setDataSource] = useState(props.profile.administradores)
  const [value, setValue] = useState('')
  const [isModalVisible, setModal] = useState(false)
  const [Ok, setOk] = useState(false)
  const [usuario, setInfo] = useState([])
  const [Cancel, setCancel] = useState(false)

  const showModal = () => {
    setModal(true)
  }

  const handleOk = () => {
    setInfo([])
    setOk(false)
    setModal(false)
  }

  const handleCancel = () => {
    setInfo([])
    setCancel(false)
    setModal(false)
  }

  const handleClose = () => {
    setOk(false)
    setCancel(false)
    setModal(false)
  }

  const handlePermanentDelete = (e, record) => {
    e.preventDefault()
    const data =
    {
      username: record.username,
      action: 'false',
      usernamessesion: props.login.me.username,

    }

    handleClose()
    props.deleteAdmin(data)
  }

  const handleChange = (e, record) => {
    const data = {
      usernamessesion: props.login.me.username,
      username: record.username,
      action: e.target.checked
    }
    if(data.username==data.usernamessesion)
      return NotificationManager.warning('No puede desactivarse así mismo.', 'ADVERTENCIA', 3000)
    props.updateAsociado(data)
    setDataSource(props.profile.administradores)
  }

  useEffect(() => {
    setDataSource(props.profile.administradores)
    is_valid = true
  }, [props.profile.administradores])

  const columns = [
    {
      title: 'Usuario',
      dataIndex: 'username',
      key: 'id',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      showSorterTooltip: false
    },
    {
      title: 'Nombre(s)',
      dataIndex: 'first_name',
      key: 'contacto',
      sorter: (a, b) => a.contacto.localeCompare(b.contacto),
      showSorterTooltip: false
    },
    {
      title: 'Apellido(s)',
      dataIndex: 'last_name',
      key: 'id',
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      showSorterTooltip: false
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      showSorterTooltip: false
    },
    {
      title: 'Estado',
      key: 'id',
      render: (record) => (
        <React.Fragment>
          <FormControlLabel
            control={
              <Switch
                checked={record.is_active}
                onClick={e => {
                  handleChange(e, record)
                  props.getAdmins()
                }}
                name='checkedB'
                color='primary'
              />
                        }
          />
        </React.Fragment>
      )
    },

    {
      title: '-',
      key: 'id',
      render: (is_active, record) => (
        <React.Fragment>
          <button
            onClick={e => {
              // handlePermanentDelete(e, record)
              setInfo(record),
              showModal(true)
            }} className='ps-btn ps-btn--opt'
          >
            <i className='icon-trash2' style={{ fontWeight: '600' }} />
          </button>
        </React.Fragment>
      )
    }

  ]

  var is_valid = false
  { props.profile.administradores.length > 0
    ? is_valid = true
    : false
  }

  return (
    <div className='form-group' >
      <section className='pl-0 pr-0' >
        <div
            className='container'
            style={{
                      marginBottom: "5px",
                      padding:"0",
                      display:"flex",
                      justifyContent: "space-between"

                }}>
          <div>
            <Link to='invitar-asociado' className='ps-btn'>
              Agregar +
            </Link>
          </div>

          {is_valid
            ? <div className='col-0 pl-0'>
              <div className='ps-form--search-simple'>
                <input
                  placeholder='Buscar...'
                  className='form-control'
                  value={value}
                  onChange={e => {
                    const currValue = e.target.value
                    setValue(currValue)
                    const filteredData = props.profile.administradores.filter(entry =>
                      entry.username.toLowerCase().includes(currValue.toLowerCase())
                    )
                    setDataSource(filteredData)
                  }}
                />
                <button><i className='icon icon-magnifier' /></button>
              </div>
            </div>
            : null}


        </div>
      </section>
      <hr />
      {is_valid === true
        ? <Table dataSource={dataSource} columns={columns} className='table ps-table--market' />
        : <div>
          <hr />
          <p>Aun no tienes usuarios agregadas...</p>
        </div>}
      <Modal
        width={800}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <AsociadoDisable usuario={usuario} deletepermanent={true} handleSend={handlePermanentDelete} closeModal={handleClose} />
      </Modal>
    </div>
  )
}

const ms2p = (state) => {
  return state
}

const md2p = { ...actions }

export default connect(ms2p, md2p)(TableAdministradores)
