import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DefaultHeader from "../../../../layout/Header/DefaultHeader";
import { Link } from "react-router-dom";
import { addImageSlider } from "../../../../../../redux/modules/sitio/sitio";
import { api } from "../../../../../../utility/api";
import SliderImageForm from "./SliderImageForm";

const SliderImage = (props) => {
    const { id } = props.match.params;
    const [image, setImage] = useState(null);
    const [slider, setSlider] = useState([]);

    const getSlider = async () => {
        const response = await api.get(`/cgf/slider/${id}`).then((slider) => {
            setSlider(slider);
        });
    };
    useEffect(() => {
        getSlider();
    }, []);

    const handleSubmit = (data) => {
        const imagen = { name: "imagen", file: image };
        data.imagen = imagen;
        data.slider = slider.id;
        props.addImageSlider(data);
    };
    return (
        <div>
            <DefaultHeader title="Agregar nueva imagen" visible={true} />
            <Link to={`/slider/detalle/${slider.id}`} className="ps-btn" style={{backgroundColor: "#2e3d50", color: "white"}}>
                Regresar
            </Link>
            <div className="container">
                <div className="alert alert-info mt-20" role="alert">
                    <ul>
                        <h3>
                            <i className="icon icon-bubble-alert" /> Información
                            importante
                        </h3>
                        <li>
                            Las imagenes deben tener un aspecto horizontal (más
                            anchas que largas).
                        </li>
                        <li>
                            Sí la imagen cargada no corresponde al aspecto podrá
                            hacer un recorte para hacerla coincidir con el
                            aspecto requerido.
                        </li>
                        <li>
                            Al momento de cargar la imagen se tendrá una
                            previsualización de la misma, no es necesario que
                            cubra todo el espacio del recuadro que aparece con
                            lineas punteadas.
                        </li>
                    </ul>
                </div>
                <h4 style={{ textAlign: "center" }}>
                    Agregando imagen a: {slider.nombre}
                </h4>
                <SliderImageForm
                    onSubmit={handleSubmit}
                    setImage={setImage}
                    image={image}
                />
            </div>
        </div>
    );
};

const md2p = { addImageSlider };

export default connect(null, md2p)(SliderImage);
