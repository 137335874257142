import React, { Component } from 'react';
import DefaultHeader from '../../../layout/Header/DefaultHeader'
import { connect } from "react-redux";
import {actions} from '../../../../../redux/modules/sitio/sitio'
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import TableFeatures from './TableFeatures';

class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            isModalRequested: false,
            isModalPaid:false,
            loader: true,
        }
    }

    componentDidMount(){
        this.handleLoader()
        this.props.getFeatures()
    }

    showModal = () => {
        this.setState({isModalVisible:true})
      };
        
    handleOk = () => {
        this.setState({isModalVisible:false})
      };
    
       handleCancel = () => {
        this.setState({isModalVisible:false})
      };

      handleLoader = () => {
        setTimeout(()=> this.setState({loader:false}),1000);
    }

    handleCreate = () => {
        this.setState({create:true})
    }

render() {

    const {features} = this.props.sitio
    const {me} = this.props.login
    const { getFeatures, deleteFeatures } = this.props
    return (
    <div>
    <DefaultHeader title="Caracteristicas del sitio" visible={true}/>
    {this.state.loader?
    <LoadMask loading={this.state.loader} dark>
         
    </LoadMask>
    :
    <div>
    {features?
    <div className="container">

        <div className="ps-card">
        <div>
          <hr/>
        <TableFeatures info={features}  styled={true} getFeatures={getFeatures} deleteFeatures={deleteFeatures}/>
        </div>

        </div>
    </div>
    :null}


</div>
}
</div>

        );
    }
}


const ms2p = (state) => {
    return state;
  };

const md2p = {...actions}
  
export default connect(ms2p ,md2p)(Features);
