import React from 'react'
import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'
import { Login, Profile, Registro } from './common/components/LoginRegister'
import Home from './common/components/Home/Home'
import ProtectedRoute from './ProtectedRoute'
import NotFound from './common/components/layout/NotFound/NotFound'
import Notificaciones from './common/components/Notificaciones/Notificaciones'
import Solicitudes from './common/components/Solicitudes/Solicitudes'
import Productos from './common/components/Productos/Productos'
import AprobacionProductos from './common/components/Aprobaciones/Productos/AprobacionProductos'
import Vendedores from './common/components/Vendedor/Vendedores'
import SolicitudVendedores from './common/components/Aprobaciones/Vendedores/SolicitudVendedores'
import '../assets/fonts/fonts.css'
import '../assets/public/css/bootstrap.min.css'
import '../assets/public/css/slick.min.css'
import '../style/scss/style.scss'
import '../assets/public/fonts/Linearicons/Font/demo-files/demo.css'
import '../assets/public/fonts/font-awesome/css/font-awesome.css'
import './Layout.scss'
import DetalleProducto from './common/components/Aprobaciones/Productos/DetalleProducto'
import DetalleUsuario from './common/components/Aprobaciones/Vendedores/DetalleUsuario'
import Perfil from '../js/common/components/Perfil/Perfil'
import Tienda from './common/components/Perfil/Tienda/Tienda'
import Cotizaciones from './common/components/Perfil/Cotizaciones/Cotizaciones'
import Sitio from './common/components/Perfil/Sitio/Sitio'
import ProductoAP from './common/components/Importacion/ProductoAP'
import Recovery from './common/components/LoginRegister/PasswordRecovery/Recuperacion'
import Asociado from './common/components/Perfil/Asociados/Asociado'
import CrearAsociado from './common/components/Perfil/Asociados/CrearAsociado'
import User from './common/components/Perfil/User/User'
import PasswordRecoveryConfirm from './common/components/LoginRegister/PasswordRecovery/PasswordRecoveryConfirm'
import FormChange from './common/components/LoginRegister/PasswordRecovery/FormChangePassword/FormChange'
import Activacion from './common/components/Perfil/User/Activacion'
import Sucess from './common/components/Perfil/User/ResultadoActivacion/Sucess'
import Failed from './common/components/Perfil/User/ResultadoActivacion/Failed'
import Banner from './common/components/Perfil/Sitio/Banner/Banner'
import Features from './common/components/Perfil/Sitio/Features/Features'
import Slider from './common/components/Perfil/Sitio/Slider/Slider';
import DetailSlider from './common/components/Perfil/Sitio/Slider/Detail/DetailSlider'
// import VerSlider from './common/components/Perfil/Sitio/Slider/VerSlider';
import BitacoraList from './common/components/Bitacora/BitacoraList';
import BitacoraGet from './common/components/Bitacora/BitacoraGet'
import SliderForm from './common/components/Perfil/Sitio/Slider/New/SliderNew'
import SliderImage from './common/components/Perfil/Sitio/Slider/SliderImage/SliderImage'
import SliderImageUpdate from './common/components/Perfil/Sitio/Slider/UpdasteSliderImage/SliderImageUpdate'
import FamiliasCategorias from './common/components/Familias/FamiliasCategorias';
import CategoriaList from './common/components/Categorias/CategoriaList'; 
import Plantilla from './common/components/Platilla/Plantilla';
import DetalleProductoLocal from './common/components/Productos/DetalleProducto'
import NuevoProducto from './common/components/Productos/NuevoProducto'

module.exports = (
  <div>
    <div className='container__content'>
      <Switch>
        <Route exact path='/login' component={Login} />
        <Route exact path='/activacion-de-invitacion/asociados/:id' component={Activacion} />
        <Route exact path='/cuenta-activacion/completado' component={Sucess} />
        <Route exact path='/cuenta-activacion/error' component={Failed} />
        <Route exact path='/recuperacion/confirmacion' component={PasswordRecoveryConfirm} />
        <Route exact path='/recovery/r/:id' component={FormChange} />
        <Route exact path='/recuperacion' component={Recovery} />
        <ProtectedRoute exact path='/' component={Home} />
        <ProtectedRoute exact path='/solicitudes' component={Solicitudes} />
        <ProtectedRoute exact path='/productos' component={Productos} />
        <ProtectedRoute exact path='/producto/detalle/:id' component={DetalleProductoLocal} />
        <ProtectedRoute exact path='/producto-nuevo' component={NuevoProducto} />
        <ProtectedRoute exact path='/datos-acceso' component={User} />
        <ProtectedRoute exact path='/asociados' component={Asociado} />
        <ProtectedRoute exact path='/migracion-ap' component={ProductoAP} />
        <ProtectedRoute exact path='/invitar-asociado' component={CrearAsociado} />
        {/* <ProtectedRoute exact path="/aprobacion-productos/:id" component={DetalleProducto}/> */}
        <ProtectedRoute exact path='/aprobacion-productos' component={AprobacionProductos} />
        <ProtectedRoute exact path='/vendedores-solicitudes' component={SolicitudVendedores} />
        <ProtectedRoute exact path='/vendedores' component={Vendedores} />
        <ProtectedRoute exact path='/sitio' component={Sitio} />
        <ProtectedRoute exact path='/banners' component={Banner} />
        <ProtectedRoute exact path='/features' component={Features} />
        <ProtectedRoute exact path="/sliders" component={Slider} />
        <ProtectedRoute exact path='/slider/detalle/:id' component={DetailSlider}/>
        <ProtectedRoute exact path='/slider/agregar-imagen/:id' component={SliderImage}/>
        <ProtectedRoute exact path='/slider/actualizar-imagen/:id' component={SliderImageUpdate}/>
        <ProtectedRoute exact path='/slider/nuevo' component={SliderForm}/>
        <ProtectedRoute exact path='/ajustes' component={Perfil} />
        <ProtectedRoute exact path='/ajustes-cotizaciones' component={Cotizaciones} />
        <ProtectedRoute exact path='/bitacora-list' component={BitacoraList} />
        <ProtectedRoute exact path='/bitacora/:id' component={BitacoraGet} />
        <ProtectedRoute exact path='/familias-list' component={FamiliasCategorias} />
        <ProtectedRoute exact path='/categorias/:id' component={CategoriaList} />
        <ProtectedRoute exact path='/plantilla' component={Plantilla} />
        <Route component={NotFound} />
      </Switch>
    </div>
    <NotificationContainer />
  </div>
)
