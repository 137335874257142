import React from "react";
import { Field, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import { renderImageUpload } from "../../../../../components/Utils/renderField/renderField";

const SliderUpdate = (props) => {
    const { handleSubmit, slider, setImage, selected } = props;

    return (
        <form className="form" onSubmit={handleSubmit}>
            <hr />
            <div className="ps-form__content">
                <figure className="ps-block--form-box">
                    <figcaption></figcaption>
                    <div className="ps-block__content">
                        <b>
                            Por el momento solo es necesario que elijas un
                            nombre para identificarlo
                        </b>
                        <br />
                        <div className="form-group col-8 pt-10">
                            <label>
                                -Haz clic sobre el recuadro para seleccionar una
                                imagen.
                            </label>
                            <Field
                                name="imagen"
                                imagen={selected ? selected.imagen : null}
                                aspect={3}
                                setFile={setImage}
                                component={renderImageUpload}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <div>
                                <Link
                                    to="/sliders"
                                    className="ps-btn ps-btn--gray"
                                >
                                    Cancelar
                                </Link>
                            </div>
                            <button type="submit" className="ps-btn">
                                Crear
                            </button>
                        </div>
                    </div>
                </figure>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "cambiar_slider"
})(SliderUpdate);
