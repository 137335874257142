import React, { useEffect, useState } from "react";
import { Link , useHistory } from "react-router-dom";
import { api } from "../../../../../../utility/api";
import { Table, Modal } from "antd";
import DefaultHeader from "../../../../../components/layout/Header/DefaultHeader";
import LazyLoad from "react-lazyload";
import SliderUpdate from "./SliderUpdate";
import DeleteModal from "../../../../Utils/GenericsModals/DeleteModal";
import { connect } from "react-redux";
import { deleteImageSlider} from "../../../../../../redux/modules/sitio/sitio"

const DetailSlider = (props) => {
    const history = useHistory();
    const { id } = props.match.params;
    const [slider, setSlider] = useState([]);
    const [image, setImage] = useState(null);
    const [confirm, showConfirm] = useState(false);
    const [selected, setSelected] = useState(null);
    const [showModal, setModal] = useState(false);

    const getSlider = async () => {
        const response = await api.get(`/cgf/slider/${id}`).then((slider) => {
            setSlider(slider);
        });
    };

    const handleDelete = (e, record) => {
        e.preventDefault();
        setSelected(record);
        showConfirm(true);
    };

    const handleSubmit = (data) => {
        const imagen = { name: "imagen", file: image };
        data.imagen = imagen;
        data.slider_id = slider.id;
        data.imagen_id = selected.id;
    };

    const handleSubmitDelete = () => {
        let data = {};
        data.image = selected.id
        data.slider = slider.id
        props.deleteImageSlider(data);
        showConfirm(false);
        
    }

    const openModal = (e, record) => {
        //setImage(record.imagen);
        //setSelected(record);
        history.push(`/slider/actualizar-imagen/${record.id}`);
    };

    const closeModal = () => {
        setImage([]);
        setSelected([]);
        setModal(false);
    };

    useEffect(() => {
        getSlider();
    }, []);

    const columns = [
        {
            title: "Miniatura",
            render: (record) => (
                <LazyLoad>
                    <img
                        src={record.imagen}
                        alt={"slider-image"}
                        style={{ width: "250px", height: "100px" }}
                    />
                </LazyLoad>
            )
        },
        {
            title: "-",
            render: (record) => (
                <div className="wrap justify-content-evenly">
                    <div>
                        <button
                            className="ps-btn ps-btn--opt"
                            onClick={(e) => openModal(e, record)}
                        >
                            <i
                                className="icon-refresh"
                                style={{ fontWeight: "600" }}
                            />
                        </button>
                    </div>
                    <button
                        className="ps-btn ps-btn--opt"
                        onClick={(e) => handleDelete(e, record)}
                    >
                        <i
                            className="icon-trash2"
                            style={{ fontWeight: "600" }}
                        />
                    </button>
                </div>
            )
        }
    ];

    return (
        <div>
            <DefaultHeader
                title={`Editando: "${slider.nombre}" `}
                visible={true}
            />
            <div className="d-flex justify-content-between">
                <div>
                    <Link to="/sliders" className="ps-btn" style={{backgroundColor: "#2e3d50", color: "white"}}>
                        Regresar
                    </Link>
                </div>
                <div>
                    <Link
                        to={`/slider/agregar-imagen/${id}`}
                        className="ps-btn"
                    >
                        Agregar +
                    </Link>
                </div>
            </div>
            <hr />

            <Table
                className="table ps-table--market"
                columns={columns}
                dataSource={slider.images}
                rowKey="id"
            ></Table>
            <Modal
                title=""
                width={800}
                visible={showModal}
                onOk={closeModal}
                onCancel={closeModal}
                footer={[]}
            >
                <SliderUpdate
                    slider={slider}
                    selected={selected}
                    onSubmit={handleSubmit}
                    setImage={setImage}
                />
            </Modal>
            <DeleteModal
                    visible={confirm}
                    closeModal={closeModal}
                    handleSubmit={handleSubmitDelete}
                    size={600}
                    item_name={"la imagen seleccionada"}
                />
        </div>
    );
};

const md2p = {deleteImageSlider}

export default connect(null , md2p)(DetailSlider)
