import React, { Component } from 'react'
import {connect} from 'react-redux';
import {actions} from '../../../redux/modules/notificaciones/notificaciones';
import { Link } from "react-router-dom";
import {api} from '../../../utility/api'
import DefaultHeader from '../layout/Header/DefaultHeader'
import {Table , Tag , Input} from 'antd';

import moment from 'moment'


class  Notificaciones extends Component{
    constructor(props) {
        super(props);
    }


    state = {
        cotizaciones : [],
        seleccionado: '/cotizaciones',
        estado:'1',
        value: '',
        dataSource: [],
    };
    


render(){
    const notificaciones = this.props.notificaciones
    const get_ruta = location.href.split("/");
    let route = "/" + get_ruta[get_ruta.length - 1];
    let keyword = route.split("#")

    const estados = [
        {"id":10 , "nombre":"-", "color":"default"},
        {"id":20 , "nombre":"RECIBIDA", "color":"orange"},
        {"id":30 , "nombre":"ENVIADA", "color":"processing"},
        {"id":40 , "nombre":"EXPIRADA", "color":"error"},
        {"id":50 , "nombre":"FINALIZADA", "color":"purple"},
        {"id":60 , "nombre":"APROBADA-PARCIAL", "color":"yellow"},
        {"id":70 , "nombre":"APROBADA-TOTAL", "color":"processing"},
    ]

    const columns = [

        {
            title: 'Fecha',
            key: 'create_date',
            dataIndex: 'create_date',
            render: (create_date) => (
                <React.Fragment>
                        <p>{moment({create_date}).format('DD/MM/YYYY')}</p>
                </React.Fragment>
            )
          },

        {
            title: 'Contenido',
            dataIndex: 'content',
            key: 'content',
            render: (content, record) =>(
                <React.Fragment>
                        <Link to={`cotizacion/${record.cotizacion}`} className="menu">
                        <span>
                            <Tag color={estados[record.contenido/10].color}>
                            <b>{record.remitente}</b> {content} <b>No. {record.cotizacion}</b>
                            </Tag>
                        </span>
                        </Link>
                </React.Fragment>
                )
          },
      ];

    return(

        <div>
            <DefaultHeader title="Cotizacion"/>
            <h2>Mis notificaciones</h2>
            <a className="ps-btn">
            Vaciar notificaciones
            </a>
            <hr></hr>
            <div className="container">
                {notificaciones.length>0 ?
                <Table 
                    className="ps-table--market"
                    columns={columns} 
                    dataSource={notificaciones}  
                />
                :
                <div>
                    <h1>No hay registros</h1>
                    </div>    
                }
            </div>
        </div>
    );
}

};

const mapStateToProps = state => {
    return state.profile;
};

const md2p = { ...actions };

export default connect(mapStateToProps, md2p)(Notificaciones);