import React, { Component } from "react";
import { connect } from "react-redux";
import "../../../../style/scss/custom/BitacoraList.scss";
import LoadMask from "../Utils/LoadMask/LoadMask";
import { SortablePane, Pane } from "react-sortable-pane";
import {
    getHome,
    updateHome
} from "../../../redux/modules/plantilla/plantilla";
import PlantillaModal from "./PlantillaModal";
import PlantillaForm from "./PlantillaForm";
import DefaultHeader from "../layout/Header/DefaultHeader";

class Plantilla extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            show: false,
            item: null,
            index: null,
            home_oden_original: null,
            home: null
        };
        this.onDragStop = this.onDragStop.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.editItem = this.editItem.bind(this);
    }

    componentDidMount() {
        this.handleLoader();
    }

    handleLoader = () => {
        setTimeout(() => {
            this.setState({ loader: false });
            this.props.getHome();
        }, 1000);
    };

    onDragStop(e, home, home_orden_original) {
        let reordenado_home = [];
        e.forEach((element) => {
            reordenado_home.push(home_orden_original[parseInt(element)]);
        });
        reordenado_home = JSON.stringify(reordenado_home);
        home.orden = reordenado_home;
        this.props.updateHome(home);
    }

    hideModal() {
        this.setState({ show: false });
    }

    showModal(e, index, home_oden_original, home) {
        this.setState({ show: true, item: e, index, home_oden_original, home });
    }

    editItem(data) {
        let copia = [...this.state.home_oden_original];
        copia[this.state.index] = data;
        let new_home = this.state.home;
        new_home.orden = JSON.stringify(copia);
        this.props.updateHome(new_home);
        this.setState({ show: false });
        this.handleLoader();
    }

    render() {
        const home = this.props.plantilla.home;
        let home_orden = null;
        let home_oden_original = null;
        if (home.orden) {
            home_orden = JSON.parse(home.orden);
            home_oden_original = [...home_orden];
        }
        return (
            <div>
                <div className="container">
                    <DefaultHeader title="Plantilla" search_bar={false} visible={true}/>
                    <div className="row mb-5">
                        <div className="col-12">
                            <p>
                                Orden de los elementos mostrados en la página
                                principal de la tienda.
                            </p>
                            <p>
                                Arrastra los componentes según el orden deseado:
                            </p>
                        </div>
                    </div>
                    {this.state.loader ? (
                        <LoadMask loading={this.state.loader} dark></LoadMask>
                    ) : (
                        <React.Fragment>
                            <PlantillaModal
                                show={this.state.show}
                                handleClose={this.hideModal}
                            >
                                <PlantillaForm
                                    onSubmit={this.editItem}
                                    handleCancel={this.hideModal}
                                    feature={this.state.item}
                                    initialValues={this.state.item}
                                    home={home}
                                    home_oden_original={home_oden_original}
                                />
                            </PlantillaModal>
                            <div className="row d-flex justify-content-center mb-3">
                                <div className="col-12">
                                    {home_orden ? (
                                        <SortablePane
                                            direction="vertical"
                                            margin={5}
                                            onDragStop={(
                                                e,
                                                key,
                                                elementRef,
                                                order
                                            ) =>
                                                this.onDragStop(
                                                    order,
                                                    home,
                                                    home_oden_original
                                                )
                                            }
                                        >
                                            {home_orden.map((e, index) => (
                                                <Pane
                                                    id={e.id}
                                                    key={index}
                                                    minWidth={300}
                                                    height="100%"
                                                >
                                                    <li className="list-group-item">
                                                        <div className="row">
                                                            <div className="col-8">
                                                                {e.nombre}
                                                            </div>
                                                            <div className="col-4 d-flex justify-content-end">
                                                                <button
                                                                    onClick={() =>
                                                                        this.showModal(
                                                                            e,
                                                                            index,
                                                                            home_oden_original,
                                                                            home
                                                                        )
                                                                    }
                                                                    title="Actualizar nombre"
                                                                    className="btn btn-info"
                                                                >
                                                                    <i className="icon-pencil"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </Pane>
                                            ))}
                                        </SortablePane>
                                    ) : (
                                        <h3>Cargando...</h3>
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}

const ms2p = (state) => {
    return state;
};

const md2p = { getHome, updateHome };

export default connect(ms2p, md2p)(Plantilla);
