import React, { Component } from 'react';
import DefaultHeader from '../layout/Header/DefaultHeader'
import { connect } from "react-redux";
import LoadMask from "../Utils/LoadMask/LoadMask";
import CardItem from './CardItem';
import TiendaImage from  '../../../../assets/img/tienda.png'
import FacturaImage from  '../../../../assets/img/cuenta.png'
import UserImage from  '../../../../assets/img/usuarios.png'
import PasswordImage from  '../../../../assets/img/password.png'
import {actions} from '../../../redux/modules/sitio/sitio'


class Perfil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            loader: true
        }; 
    }

    timer = null;

    showModal = () => {
        this.setState({isModalVisible:true})
      };

    handleOk = () => {
        this.setState({isModalVisible:false})
      };
    
    handleCancel = () => {
    this.setState({isModalVisible:false})
    };
    
    componentDidMount(){
        this.handleLoader()
    }

    handleLoader = () => {
        setTimeout(()=> this.setState({loader:false}),1000);
    }
    



render() {
    return (
        <div>
            <DefaultHeader title="Ajustes" visible={true}/>
        {this.state.loader?
        <LoadMask loading={this.state.loader} dark>
        
        </LoadMask>
        :
            <div>   
            <hr/>
            <section>
            <div className="container" style={{display:'flex' , flexWrap:"wrap" , justifyContent:"space-around"}}>
                <div className="ps-block--form-box2">
                    <CardItem 
                        image = {TiendaImage}
                        title="Marketplace"
                        description="Cambiar banner del sitio web de agromall"
                        text_button="Ver"
                        url='/sitio'
                    />
                </div>
                <div className="ps-block--form-box2">
                    <CardItem
                        image={UserImage}
                        title="Usuarios"
                        description="Usuarios que pueden administrar la tienda"
                        text_button="Ver" 
                        url="/asociados"
                    />
                </div>
                <div className="ps-block--form-box2">
                    <CardItem 
                        image = {FacturaImage}
                        title="Comisión y fees"
                        description="Valores de comision fija, variable, impuesto y otros"
                        text_button="Ver"
                        url='/ajustes-cotizaciones'
                    />
                </div>
                <div className="ps-block--form-box2">
                    <CardItem 
                        image={PasswordImage}
                        title="Credenciales de acceso"
                        description="cambiar datos de acceso"
                        text_button="Ver"
                        url='datos-acceso'
                    />
                </div>
            
            </div>
            </section>
        </div>
        }
        </div>
            );
        }
}



const ms2p = state => ({...state})

const md2p = {...actions};
  
export default connect(ms2p, md2p)(Perfil);
