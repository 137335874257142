import React, { Component } from "react";
import DefaultHeader from "../../layout/Header/DefaultHeader";
import { connect } from "react-redux";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import CardItem from "./../CardItem";
import BannerImage from "../../../../../assets/img/banner.png";
import SliderImage from "../../../../../assets/img/slider.png";
import FeatureImage from "../../../../../assets/img/webpage.png";
import SortImage from "../../../../../assets/img/ordenamiento.png";
import { actions } from "../../../../redux/modules/sitio/sitio";
import { Link } from "react-router-dom";

class Sitio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            loader: true
        };
    }

    timer = null;

    showModal = () => {
        this.setState({ isModalVisible: true });
    };

    handleOk = () => {
        this.setState({ isModalVisible: false });
    };

    handleCancel = () => {
        this.setState({ isModalVisible: false });
    };

    componentDidMount() {
        this.handleLoader();
    }

    handleLoader = () => {
        setTimeout(() => this.setState({ loader: false }), 1000);
    };

    render() {
        return (
            <div>
                <DefaultHeader title="Ajustes Marketplace" visible={true} />
                {this.state.loader ? (
                    <LoadMask loading={this.state.loader} dark></LoadMask>
                ) : (
                    <div>
                        <div className="header__left">
                            <Link to="/ajustes" className="ps-btn" style={{backgroundColor: "#2e3d50", color: "white"}}>
                                Regresar
                            </Link>
                        </div>
                        <hr />
                        <section>
                            <div
                                className="container"
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    justifyContent: "space-around"
                                }}
                            >
                                <div className="ps-block--form-box2">
                                    <CardItem
                                        image={SliderImage}
                                        title="Slider principal"
                                        description="Seleccionar slider para mostrar en la pagina"
                                        text_button="Ver"
                                        url="/sliders"
                                    />
                                </div>
                                <div className="ps-block--form-box2">
                                    <CardItem
                                        image={BannerImage}
                                        title="Banner"
                                        description="cambiar los diferentes banner de la pagina"
                                        text_button="Ver"
                                        url="/banners"
                                    />
                                </div>
                                <div className="ps-block--form-box2">
                                    <CardItem
                                        image={FeatureImage}
                                        title="Features"
                                        description="features de la pagina principal"
                                        text_button="Ver"
                                        url="/features"
                                    />
                                </div>
                                <div className="ps-block--form-box2">
                                    <CardItem
                                        image={SortImage}
                                        title="Home"
                                        description="Distribución de los carousel en home."
                                        text_button="Ver"
                                        url="/plantilla"
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                )}
            </div>
        );
    }
}

const ms2p = (state) => ({ ...state });

const md2p = { ...actions };

export default connect(ms2p, md2p)(Sitio);
