import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { renderImageUpload,renderField } from '../../../../components/Utils/renderField/renderField'

const BannerNew = (props) => {
  const { handleSubmit, image,setImage } = props
  const handleInterrupt = (e)=>{
    e.preventDefault()
    handleSubmit(e)
    e.target["nombre"].value = ""
    e.target["imagen"].src = "http://0.0.0.0:8081/assets/21b9e0f75b5cb0cde90e8b57875ff6a1.png"
  }

  return (
    <div>
      <div className='container'>
        <form onSubmit={handleInterrupt}>
          <div className='container' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div className='form-group col-8 pt-10'>
              <label>-Haz clic sobre el recuadro para seleccionar una imagen.</label>
              <Field
                name='imagen'
                imagen={image ? image : null}
                aspect={3}
                setFile={setImage}
                component={renderImageUpload}
              />
            </div>
          </div>
          <div className="form-group col-12 pt-10">
                <label htmlFor="">Nombre:</label>
                <Field
                    className="form-control"
                    name="nombre"
                    component={renderField}
                    placeholder="Nombre"
                    required
                />
          </div>
          <div className='container mt-20 pt-20' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button type='submit' className='ps-btn btn-warning'>Guardar</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'new_banner' // a unique identifier for this form
})(BannerNew)
