import React, { Component } from 'react';
import DefaultHeader from '../layout/Header/DefaultHeader'
import { connect } from "react-redux";
import {actions} from '../../../redux/modules/productos/productos'
import LoadMask from "../Utils/LoadMask/LoadMask";
import TableProductosAP from "./TableProductosAP"


class ProductoAP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            isModalRequested: false,
            isModalPaid:false,
            loader: true
        }
    }

    componentDidMount(){
        this.handleLoader()
        this.props.getProductosAP()
        this.props.getCategoriasAP()
        this.props.getFamiliasAP()
    }

    showModal = () => {
        this.setState({isModalVisible:true})
      };

      showModalRequested = () => {
        this.setState({isModalRequested:true})
      };

      showModalPaid = () => {
        this.setState({isModalPaid:true})
      };
    
       handleOk = () => {
        this.setState({isModalVisible:false})
        this.setState({isModalRequested:false})
        this.setState({isModalPaid:false})
      };
    
       handleCancel = () => {
        this.setState({isModalVisible:false})
        this.setState({isModalRequested:false})
        this.setState({isModalPaid:false})
      };

      handleSend = (data) =>{
        this.props.onSubmitSolicitud(data);
      }

      handleLoader = () => {
        setTimeout(()=> this.setState({loader:false}),1000);
    }

render() {
    return (
    <div>
    <DefaultHeader title="Importar desde AP" visible={true}/>
    {this.state.loader?
    <LoadMask loading={this.state.loader} dark>
         
    </LoadMask>
    :
    <div>
    {this.props.productos.productos?
    <div className="container">
        <hr/>
        <div className="ps-card">
        {this.props.productos.productos_ap.length>0 ?
        <div>
        <TableProductosAP 
            info={this.props.productos.productos_ap} 
            categorias_ap={this.props.productos.categorias_ap}
            familias_ap={this.props.productos.familias_ap}
            styled={true}
        />
        </div>
        :
        <p>En este momento no hay productos de aprobación</p>
        }

        </div>
    </div>
    :null}


</div>
}


</div>

        );
    }
}


const ms2p = (state) => {
    return state;
  };

const md2p = {...actions}
  
export default connect(ms2p ,md2p)(ProductoAP);
