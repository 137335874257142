import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Table, Modal } from 'antd'

import moment from 'moment'
import DetalleUsuario from './DetalleUsuario'

const TableVendedores = (info, styled) => {
  const [value, setValue] = useState('')
  const [desde, setDate1] = useState(moment().clone().startOf('month').format('YYYY-MM-DD'))
  const [hasta, setDate2] = useState(moment().format())
  const [dataSource, setDataSource] = useState(info.info)
  const [isModalVisible, setModal] = useState(false)
  const [usuario, setInfo] = useState([])
  const [Ok, setOk] = useState(false)
  const [Cancel, setCancel] = useState(false)

  console.log(info.info)
  const showModal = () => {
    setModal(true)
  }

  const handleOk = () => {
    setModal(false)
    setInfo([])
  }

  const handleCancel = () => {
    setModal(false)
    setInfo([])
  }

  const ESTADOS = [
    '-',
    'SOLICITADO',
    'DEPOSITADO',
    'DEPOSITADA',
    'FINALIZADA'
  ]

  const filter_status = []

  ESTADOS.map((status, index) => (
    filter_status.push(
      {
        text: status,
        value: (index * 10)
      })
  ))

  const filter_estados = [
    { id: 0, nombre: '-', color: 'default' },
    { id: 1, nombre: 'RECIBIDA', color: 'orange' },
    { id: 4, nombre: 'ACEPATADA', color: 'success' },
    { id: 5, nombre: 'RECHAZADA', color: 'warning' }

  ]

  const handleSetFilter = () => {
    const inicio = new Date(desde).getTime()
    const final = new Date(hasta).getTime()

    const resultData = info.info.filter(obj => {
      const time = new Date(obj.date_joined).getTime()
      return (inicio < time && time < final)
    })
    setDataSource(resultData)
  }

  const columns = [
    {
      title: 'No.',
      key: 'id',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      showSorterTooltip: false
    },
    {
      title: 'Fecha Solicitud',
      dataIndex: 'date_joined',
      key: 'date_joined',
      sorter: (a, b) => moment(a.date_joined).unix() - moment(b.date_joined).unix(),
      showSorterTooltip: false,
      render: date_joined => (
        <p>
          {moment(date_joined).format('L')}
        </p>
      )
    },
    {
      title: 'Empresa',
      dataIndex: 'empresa_nombre',
      key: 'id',
      sorter: (a, b) => a.empresa_nombre.localeCompare(b.empresa_nombre),
      showSorterTooltip: false,
      render: (empresa_nombre, record) => (
        <p>
          {empresa_nombre} , RUT: {record.empresa_rut}
        </p>
      )
    },
    {
      title: 'Direccion',
      key: 'id',
      render: (record) => (
        <p>
          {record.empresa_direccion}, {record.empresa_comuna}
        </p>
      )
    },
    {
      title: 'Contacto',
      key: 'id',
      render: (record) => (
        <p>
          {record.contacto_nombre}, {record.contacto_primer_apellido} Tel. {record.empresa_telefono}
        </p>
      )
    },
    {
      title: 'Marca',
      dataIndex: 'marca',
      key: 'monto_solicitado',
      render: marca => (
        <p>
          {marca}
        </p>
      )
    },
    {
      title: '-',
      render: (record) => (
        <React.Fragment>
          <button
            className='ps-btn' onClick={e => {
              setInfo(record),
              showModal()
            }}
          >
            Ver
          </button>
        </React.Fragment>
      )
    }
  ]

  return (
    <div>
      <div className='form-group'>
        {styled
          ? <React.Fragment>
            <section className='ps-dashboard'>
              <div className='col-3'>
                <label htmlFor='estados'>Buscar:</label>
                <div className=''>
                  <div className='ps-form--search-simple'>
                    <input
                    placeholder='Buscar...'
                    className='form-control'
                    value={value}
                    onChange={e => {
                        const currValue = e.target.value
                        setValue(currValue)
                        const filteredData = info.info.filter(entry =>
                          entry.empresa_nombre.toLowerCase().includes(currValue.toLowerCase())
                        );
                        setDataSource(filteredData)
                      }}
                  />
                    <button><i className='icon icon-magnifier' /></button>
                  </div>
                </div>
                <hr />
              </div>
              <div className='container' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <div className='col-4'>
                  <label htmlFor='estados'>Desde:</label>
                  <input
                    type='date'
                    className='form-control'
                    name='fecha_i'
                    value={desde}
                    onChange={e => {
                    const currValue = e.target.value
                    setDate1(currValue)
                  }}
                  />

                  <hr />
                </div>
                <div className='col-4'>
                  <label htmlFor='estados'>Hasta:</label>
                  <input
                    type='date'
                    className='form-control'
                    name='fecha_f'
                    value={moment(hasta).format('YYYY-MM-DD')}
                    onChange={e => {
                    const currValue = e.target.value
                    setDate2(currValue)
                  }}
                  />
                  <hr />
                </div>
                <div>
                  <button className='ps-btn xs' onClick={handleSetFilter}>Filtrar</button>
                </div>
              </div>
            </section>

            <Table dataSource={dataSource} columns={columns} rowKey="id" className='table ps-table--market' />

            </React.Fragment>
          : <Table dataSource={dataSource} columns={columns} rowKey="id" />}
      </div>
      <div className='form-group'>
        <Link
          to='/vendedores'
          className='ps-btn'
          style={{backgroundColor: "#2e3d50", color: "white"}}
        >
          Regresar
        </Link>
      </div>

      <Modal
        title=''
        width={800}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[

        ]}
      >

        <DetalleUsuario usuario={usuario} />

      </Modal>

    </div>
  )
}

export default TableVendedores
