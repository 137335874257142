import { initial } from 'lodash';
import React, { useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'
import { renderupdateField, renderField } from '../Utils/renderField/renderField';

const CategoriaForm = (props) => {
  const { handleSubmit, handleCancel, change, feature, titulo } = props;

//   console.log(feature);
  const handleSave= (e) =>{
    e.preventDefault();
    if(e.target["nombre"].value){
      const data = {
        id: feature.id,
        nombre: e.target["nombre"].value
      }
      e.target.nombre.value = ""
      props.onSubmit(data)
    }
  }

  useEffect(() => {
    change('nombre', !feature ? '' : feature.nombre)
  }, [], [])

  return (
    <div>
      <div className='container'>
        <h4 style={{ textAlign: 'center' }}>{titulo}</h4>
        <form onSubmit={handleSubmit}>

          <div className='container' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div className='form-group col-8 pt-10'>
              <label>Nombre:</label>
              <Field
                name='nombre'
                component={!feature ? renderField : renderupdateField}
              />
            </div>
          </div>

          <div className='container mt-20 pt-20' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button type='button' onClick={handleCancel} className='ps-btn cancel--button'>Cancel</button>
            <button type='submit' className='ps-btn btn-success'>Guardar</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default reduxForm({
  form: 'categoria_form', // a unique identifier for this form
  enableReinitialize: true
})(CategoriaForm)
