import React from "react";
import { Field, reduxForm, reset, formValueSelector  } from "redux-form";
import validate from "./validate";
import {
    renderField,
    SelectField,
    renderupdateField,
    renderFilePicker
} from "../../components/Utils/renderField/renderField";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import productos from "../../../redux/modules/productos/productos";

let ProductoForm = (props) => {
    const {
        handleSubmit,
        setFile,
        pristine,
        submitting,
        imagen,
        change,
        valid,
        categorias,
        familias,
        selected_familia,
      } = props;


    const unidades_medidas = [
        { value: "500", label: "cc" },
        { value: "200", label: "grs" },
        { value: "100", label: "kg" },
        { value: "300", label: "lt" },
        { value: "800", label: "mg" },
        { value: "600", label: "mk" },
        { value: "400", label: "ml" },
        { value: "700", label: "und" },

    ];

    const opt_categorias = [];
    const opt_familia = [];
    console.log(selected_familia)
    console.log(categorias)
    {
        categorias.length > 0
          ? categorias.filter(cat => cat.familia.id === selected_familia).map((categoria) =>
              opt_categorias.push({
                label: categoria.nombre,
                value: categoria.id,
              })
            )
          : null;
      }

    {
        familias.length > 0
            ? familias.map((familia) =>
                  opt_familia.push({
                      label: familia.nombre,
                      value: familia.id
                  })
              )
            : null;
    }

    return (
        <form className="form" onSubmit={handleSubmit}>
            <hr />
            <div className="ps-form__content">
                <p style={{ marginLeft: "3px" }}>
                Campos requeridos: <small style={{ color: "red" }}>*</small>
                </p>
                <figure className="ps-block--form-box">
                    <figcaption>Crear producto</figcaption>
                    <div className="ps-block__content">
                        <div className="form-group">
                            <Field
                                name="imagen"
                                imagen={imagen}
                                type="file"
                                setFile={setFile}
                                component={renderFilePicker}
                            />
                        </div>
                        <div className="form-group">
                            <label>Familia: <small style={{ color: "red" }}>*</small></label>
                            <Field
                                className="form-control"
                                name="familia"
                                options={opt_familia}
                                component={SelectField}
                                placeholder="Familia"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Categorías: <small style={{ color: "red" }}>*</small></label>
                            <Field
                                className="form-control"
                                name="categoria"
                                options={opt_categorias}
                                component={SelectField}
                                placeholder="Categoría"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Marca: <small style={{ color: "red" }}>*</small></label>
                            <Field
                                name="marca"
                                component={renderField}
                                type="text"
                                placeholder="Marca"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Nombre del producto: <small style={{ color: "red" }}>*</small></label>
                            <Field
                                name="nombre_comercial"
                                component={renderField}
                                type="text"
                                placeholder="Nombre del producto"
                            />
                        </div>

                        <div className="form-group">
                            <label>Tipo de formulación:</label>
                            <Field
                                name="tipo_formulacion"
                                component={renderField}
                                type="text"
                                placeholder="Formulacion"
                            />
                        </div>
                        <div className="form-group">
                            <label>Ingrediente activo:</label>
                            <Field
                                name="sustancia_activa"
                                component={renderField}
                                type="text"
                                placeholder="Ingrediente activo"
                            />
                        </div>

                        <div className="form-group">
                            <label>Concentración:</label>
                            <Field
                                name="concentracion"
                                component={renderField}
                                type="text"
                                placeholder="Concrentración"
                            />
                        </div>

                        <div className="form-group">
                            <label>Envase: <small style={{ color: "red" }}>*</small></label>
                            <Field
                                name="envase"
                                component={renderupdateField}
                                type="number"
                                max={9999.99}
                                placeholder="Envase"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Unidad de medida: <small style={{ color: "red" }}>*</small></label>
                            <Field
                                className="form-control"
                                name="unidad_medida"
                                options={unidades_medidas}
                                component={SelectField}
                                placeholder="Unidad de medida"
                                required
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <div>
                                <Link
                                    to="productos"
                                    className="ps-btn ps-btn--gray"
                                >
                                    Cancelar
                                </Link>
                            </div>
                            <button
                              className={
                                !valid
                                  ? 'ps-btn'
                                  : 'ps-btn'
                              }
                              style={{
                                  backgroundColor: !valid ? '#ccc' : '',
                                  cursor:!valid ? 'not-allowed' : '',
                                  color: !valid ? '#000' : '' }}
                              type='submit'
                              disabled={!valid}
                              >
                              Crear
                            </button>
                        </div>
                    </div>
                </figure>
            </div>
        </form>
    );
};

ProductoForm = reduxForm({
    form: "new_product_form", // a unique identifier for this form
    validate,
    onSubmitSuccess: (result, dispatch) => dispatch(reset("new_product_form")),
})(ProductoForm);

const selector = formValueSelector('new_product_form')
ProductoForm = connect(state => {
  const selected_familia = selector(state, 'familia')
  return {
    selected_familia,
  }
})(ProductoForm)

export default ProductoForm
