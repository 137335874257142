import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { api } from "api";
import {notification } from 'antd';
import Swal from 'sweetalert2';

const actionTypes = {
    GET_SOLICITUD: "GET_SOLICITUD",
    SEND_SOLICITUD: "SEND_SOLICITUD",
    SET_SOLICITUDES: " SET_SOLICITUDES",
    SET_PRODUCTOS: "SET_PRODUCTOS",
    SET_SOLICITUD_VENDEDORES: "SET_SOLICITUD_VENDEDORES",
    REDIRECT: "REDIRECT"
};

// ------------------------------------
// Pure Actions
// ------------------------------------

const SwalAlert = (title, text, type) => {
    Swal.fire({
        width: "400px",
        heightAuto: true,
        title: title,
        text: text,
        type: type
    });
};

const modalSuccessAny = (type, message, description) => {
    notification[type]({
        message: message,
        description: description,
        duration: 1
    });
};

export const setLoader = (loader) => ({
    type: actionTypes.LOADER,
    loader
});

export function sendSolicitud(payload) {
    return {
        type: actionTypes.SEND_SOLICITUD,
        payload
    };
}

export function setSolicitudes(data) {
    return {
        type: actionTypes.SET_SOLICITUDES,
        data
    };
}

export function setProductos(productos) {
    return {
        type: actionTypes.SET_PRODUCTOS,
        productos
    };
}

export function setSoliVendedor(vendedores) {
    return {
        type: actionTypes.SET_SOLICITUD_VENDEDORES,
        vendedores
    };
}

export const getSolicitudes = () => (dispatch) => {
    api.get("solicitud/retiro")
        .then((data) => {
            dispatch(setSolicitudes(data));
        })
        .catch(() => {})
        .finally(() => {});
};

export const getProductos = () => (dispatch) => {
    api.get("solicitud/producto")
        .then((productos) => {
            dispatch(setProductos(productos));
        })
        .catch(() => {})
        .finally(() => {});
};

export const getVendedores = () => (dispatch) => {
    api.get("solicitud/vendedores")
        .then((vendedores) => {
            dispatch(setSoliVendedor(vendedores));
        })
        .catch(() => {})
        .finally(() => {});
};

export const rechazarVendedor = (data) => (dispatch) => {
    api.post("solicitud/rechazo", data)
        .then((response) => {
            dispatch(sendSolicitud());
            dispatch(push("vendedores"));
            const title = "Completado";
            const text = "Se notificó al vendedor la solicitud de rechazo";
            const type = "success";
            SwalAlert(title, text, type);
        })
        .catch((error) => {
            NotificationManager.error(
                "Algo salio mal, intenta mas tarde",
                "ERROR",
                3000
            );
        })
        .finally(() => {});
};

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmitSolicitud = (data) => (dispatch) => {
    const current = data;
    const parse = {
        monto_solicitado: Number(
            current.monto_solicitado.replace(/[^0-9-]+/g, "")
        )
    };
    api.post("solicitud", parse)
        .then((response) => {
            dispatch(sendSolicitud());
            NotificationManager.success(
                "Se ha enviado la solicitud de retiro",
                "SUCCESS",
                3000
            );
            dispatch(push("/#/vendedores"));
        })
        .catch((error) => {
            NotificationManager.error(
                "Algo salio mal, intenta mas tarde",
                "ERROR",
                3000
            );
        })
        .finally(() => {});
};

export const aprobarProducto1 = (data) => (dispatch) => {
    api.post("solicitud/aprobacion_producto", data)
        .then((response) => {
            dispatch(sendSolicitud());
            dispatch(push('/productos'));
            dispatch(getProductos());
            modalSuccessAny(
                "success",
                "Completado",
                "Se actualizo el producto correctamente"
            );
        })
        .catch((error) => {
            console.error(error);
            modalSuccessAny("error", "Error", "Hubo un error al realizar");
        })
        .finally(() => {});
};


export const deleteProductoLocal = (data) => (dispatch) => {
    api.post("solicitud/delete_producto_local", data)
        .then((response) => {
            dispatch(sendSolicitud());
            dispatch(push('/productos'));
            dispatch(getProductos());
            modalSuccessAny(
                "success",
                "Completado",
                "Se elimino el producto correctamente"
            );
        })
        .catch((error) => {
            console.error(error);
            modalSuccessAny("error", "Error", "Hubo un error al realizar");
        })
        .finally(() => {});
};

export const aprobarProducto = (data) => (dispatch) => {
    api.post("solicitud/aprobacion_inicial_producto", data)
        .then((response) => {
            dispatch(sendSolicitud());
            dispatch(push('/productos'));
            dispatch(getProductos());
            modalSuccessAny(
                "success",
                "Completado",
                "Se aprobo el producto correctamente"
            );
        })
        .catch((error) => {
            console.error(error);
            modalSuccessAny("error", "Error", "Hubo un error al realizar");
        })
        .finally(() => {});
};

export const rechazarProducto = (data) => (dispatch) => {
    api.post("solicitud/rechazo_inicial_producto", data)
        .then((response) => {
            dispatch(sendSolicitud());
            dispatch(push('/productos'));
            dispatch(getProductos());
            modalSuccessAny(
                "success",
                "Completado",
                "Se rechazó el producto correctamente"
            );
        })
        .catch((error) => {
            console.error(error);
            modalSuccessAny("error", "Error", "Hubo un error al realizar");
        })
        .finally(() => {});
};

export const aprobarVendedor = (data) => (dispatch) => {
    api.post("solicitud/activacion_usuario", data)
        .then((response) => {
            dispatch(sendSolicitud());
            const title = "Completado";
            const text = "Se notificó al vendedor que fue aceptado";
            const type = "success";
            SwalAlert(title, text, type);
            dispatch(push("/vendedores"));
        })
        .catch((error) => {
            NotificationManager.error(
                "Algo salio mal, intenta mas tarde",
                "ERROR",
                3000
            );
        })
        .finally(() => {});
};

export const aprobarPago = (data) => (dispatch) => {
    api.post("solicitud/aprobar_pago", data)
        .then((response) => {
            dispatch(sendSolicitud());
            dispatch(push("/solicitudes"));
            window.location.reload();
            dispatch(getSolicitudes());
            modalSuccessAny("success", "Completado", " ");
        })
        .catch((error) => {
            NotificationManager.error(
                "Algo salio mal, intenta mas tarde",
                "ERROR",
                3000
            );
        })
        .finally(() => {});
};

export const pagarSolicitud = (data) => (dispatch) => {
    api.post("solicitud/pagar_solicitud", data)
        .then((response) => {
            dispatch(sendSolicitud());
            dispatch(push("/solicitudes"));
            window.location.reload();
            modalSuccessAny("success", "Completado", " ");
        })
        .catch((error) => {
            NotificationManager.error(
                "Algo salio mal, intenta mas tarde",
                "ERROR",
                3000
            );
        })
        .finally(() => {});
};

export const actions = {
    onSubmitSolicitud,
    getSolicitudes,
    getProductos,
    getVendedores,
    aprobarProducto,
    aprobarVendedor,
    pagarSolicitud,
    rechazarVendedor,
    aprobarPago,
    aprobarProducto1,
    deleteProductoLocal,
    rechazarProducto
};

export const reducers = {
    [actionTypes.SET_SOLICITUDES]: (state, { data }) => {
        return {
            ...state,
            data
        };
    },
    [actionTypes.SEND_SOLICITUD]: (state) => {
        return {
            ...state
        };
    },
    [actionTypes.SET_PRODUCTOS]: (state, { productos }) => {
        return {
            ...state,
            productos
        };
    },
    [actionTypes.SET_SOLICITUD_VENDEDORES]: (state, { vendedores }) => {
        return {
            ...state,
            vendedores
        };
    }
};

export const initialState = {
    loader: false,
    data: {},
    productos: [],
    vendedores: []
};

export default handleActions(reducers, initialState);
