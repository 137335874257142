import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DefaultHeader from "../../../../layout/Header/DefaultHeader";
import { Link } from "react-router-dom";
import { updateImageSlider } from "../../../../../../redux/modules/sitio/sitio";
import { api } from "../../../../../../utility/api";
import SliderImageUpdateForm from "./SliderImageUpdateForm";

const SliderImageUpdate = (props) => {
    const { id } = props.match.params;
    const [image, setImage] = useState(null);
    const [image_slider, setSlider] = useState([]);

    const getSlider = async () => {
        const response = await api
            .get(`/cgf/slider-image/${id}`)
            .then((image_slider) => {
                setSlider(image_slider);
            });
    };

    const handleSubmit = (data) => {
        const imagen = { name: "imagen", file: image };
        data.imagen = imagen;
        data.image_id = image_slider.id;
        data.slider = image_slider.slider
        props.updateImageSlider(data);
    };

    useEffect(() => {
        getSlider();
    }, []);

    return (
        <div>
            <DefaultHeader title="Actualizar imagen" visible={true} />
            <Link to={`/slider/detalle/${image_slider.slider}`} className="ps-btn" style={{backgroundColor: "#2e3d50", color: "white"}}>
                Regresar
            </Link>
            <div className="container">
                <div className="alert alert-info mt-20" role="alert">
                    <ul>
                        <h3>
                            <i className="icon icon-bubble-alert" /> Información
                            importante
                        </h3>
                        <li>
                            Las imagenes deben tener un aspecto horizontal (más
                            anchas que largas).
                        </li>
                        <li>
                            Sí la imagen cargada no corresponde al aspecto podrá
                            hacer un recorte para hacerla coincidir con el
                            aspecto requerido.
                        </li>
                        <li>
                            Al momento de cargar la imagen se tendrá una
                            previsualización de la misma, no es necesario que
                            cubra todo el espacio del recuadro que aparece con
                            lineas punteadas.
                        </li>
                    </ul>
                </div>
                <h4 style={{ textAlign: "center" }}>
                    Actualizando imagen a: {image_slider.slider}
                </h4>
                <SliderImageUpdateForm
                    onSubmit={handleSubmit}
                    setImage={setImage}
                    image={image_slider.imagen}
                />
            </div>
        </div>
    );
};

const md2p = { updateImageSlider };

export default connect(null, md2p)(SliderImageUpdate);
