import React, { useEffect, useState } from 'react'
import DefaultHeader from '../../layout/Header/DefaultHeader'
import { connect } from 'react-redux'
import LoadMask from '../../Utils/LoadMask/LoadMask'
import { actions } from '../../../../redux/modules/cuenta/profile'
import AsociadoForm from './AsociadoForm'

const CrearAsociado = (props) => {
  const [loader, setLoader] = useState(true)

  const handleLoader = () => {
    setTimeout(() => setLoader(false), 1000)
  }

  const handleSend = (data) => {
    props.invitarUsuario(data)
  }

  useEffect(() => {
    handleLoader()
  })

  return (
    <div className='container'>
      <DefaultHeader title='Crear usuario' visible />
      {loader
        ? <LoadMask loading={loader} dark />
        : <div>

          <div className='header__center'>
            <div className='row'>
              <hr />
            </div>
          </div>
          <div className='container'>
            <AsociadoForm onSubmit={handleSend} />
          </div>
        </div>}
    </div>
  )
}

const ms2p = state => ({ ...state })

const md2p = { ...actions }

export default connect(ms2p, md2p)(CrearAsociado)
