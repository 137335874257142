import React, { Component } from "react";
import { connect } from "react-redux";
import { getParity } from "../../../redux/modules/sitio/sitio";

class Paridad extends Component {
    componentDidMount() {
        this.props.getParity();
    }

    render() {
        const parity = this.props.sitio.parity;
        let fecha = null;
        if(parity.serie) {
            if(parity.serie.length > 0) {
                fecha = parity.serie[0].fecha.split("T");
            } 
        }

        return (
            <React.Fragment>
                {parity.serie ? (
                    parity.serie.length > 0 ? (
                        <div
                            className="card"
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                        >
                            <div className="card-body">
                                <p className="card-text">
                                    <strong>Paridad:</strong> Al {fecha[0]}{" "}
                                    {"-"} {parity.serie[0].valor} CLP / 1 USD
                                </p>
                            </div>
                        </div>
                    ) : null
                ) : null}
            </React.Fragment>
        );
    }
}

const ms2p = (state) => {
    return state;
};

const md2p = { getParity };

export default connect(ms2p, md2p)(Paridad);
