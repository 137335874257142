import React, { Component } from "react";
import DefaultHeader from "../../layout/Header/DefaultHeader";
import { connect } from "react-redux";
import LoadMask from "../../Utils/LoadMask/LoadMask";
import { Link } from "react-router-dom";
import {
    getCategorias,
    postCategoriaFeedCommission,
    updateCategoriaFeedCommission,
    getCategoriaFeed,
    getIva,
    updateIva
} from "../../../../redux/modules/categorias/categorias";
import { getAllFamilias } from "../../../../redux/modules/familias/familias";
import CotizacionesForm from "./CotizacionesForm";
import CotizacionesModal from "./CotizacionesModal";
import IvaForm from "./IvaForm";
import { api } from "api";

class Cotizaciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valor: "",
            select_value: 1,
            params: `?familia=${1}&page=1&limit=10`,
            loader: true,
            show: false,
            categoria_id: 0,
            categoria_nombre: ""
        };
        this.hideModal = this.hideModal.bind(this);
        this.saveCategoria = this.saveCategoria.bind(this);
        this.editCategoria = this.editCategoria.bind(this);
        this.updateIva = this.updateIva.bind(this);
    }

    componentDidMount() {
        api.get("productos/familias/all")
            .then((all_familias) => {
                if (all_familias.length > 0) {
                    this.setState({
                        select_value: all_familias[0].id,
                        params: `?familia=${all_familias[0].id}&page=1&limit=10`
                    });
                }
            })
            .catch((err) => console.log(err));
        this.handleLoader();

    }

    handleLoader = () => {
        setTimeout(() => {
            this.setState({ loader: false });
            this.props.getAllFamilias();
            this.props.getCategorias(this.state.params);
            this.props.getIva();
        }, 1000);
    };

    getFamiliaCategorias(e) {
        const familia = parseInt(e.target.value);
        let params = `?familia=${familia}&page=1&limit=10`;
        this.setState({ select_value: familia, params });
        this.props.getCategorias(params);
    }

    getRange = (inicio, final) => {
        let rango = [];
        for (let i = inicio; i < final + 1; i++) {
            rango.push(i);
        }
        return rango;
    };

    searchCategoria = async (e) => {
        this.setState({ valor: e.target.value }, () => {
            this.paginator(1, 0);
        });
    };

    paginator = (page, pagina_actual) => {
        if (page != pagina_actual) {
            let params = "/?";
            if (this.state.valor) {
                params = params + "search=" + this.state.valor + "&";
            }
            params =
                params +
                `familia=${this.state.select_value}&page=${page}&limit=10`;
            this.setState({ params }, () => {
                this.props.getCategorias(this.state.params);
            });
        }
    };

    async showModal(element) {
        await this.props.getCategoriaFeed(element.id);
        this.setState({
            show: true,
            categoria_id: element.id,
            categoria_nombre: element.nombre
        });
    }

    saveCategoria(data) {
        if (Object.keys(data).length > 0) {
            const objCFC = {
                comision_f: parseFloat(parseFloat(data.comision_f).toFixed(1)),
                comision_v: parseFloat(parseFloat(data.comision_v).toFixed(1)),
                iva: parseFloat(parseFloat(data.iva).toFixed(1)),
                categoria: this.state.categoria_id
            };
            this.props.postCategoriaFeedCommission(objCFC);
            this.hideModal();
            this.setState({ loader: true });
            this.handleLoader();
        }
    }

    editCategoria(data) {
        console.log(data);
        if (Object.keys(data).length > 0) {
            const objCFC = {
                id: this.state.categoria_id,
                comision_f: parseFloat(parseFloat(data.comision_f).toFixed(1)),
                comision_v: parseFloat(parseFloat(data.comision_v).toFixed(1)),
                iva: parseFloat(parseFloat(data.iva).toFixed(1)),
                categoria: this.state.categoria_id,
            };
            this.props.updateCategoriaFeedCommission(objCFC);
            this.hideModal();
            this.setState({ loader: true });
            this.handleLoader();
        }
    }

    hideModal() {
        this.setState({ show: false });
    }

    updateIva(data) {
        data.valor_numerico = parseInt(data.valor_numerico);
        this.props.updateIva(data);
    }

    render() {
        const iva = this.props.categorias.iva;
        const categoria_feed = this.props.categorias.categoria_feed;
        const response = this.props.categorias.categorias;
        const familias = this.props.familias.all_familias;
        const categorias = response.datos;
        const estadisticas = response.estadisticas;
        const total = response.meta ? response.meta.page.total : 0;
        const anterior = response.links ? response.links.previos : 0;
        const primera = response.links ? response.links.first : 0;
        const ultima = response.links ? response.links.last : 0;
        const pagina_actual = response.meta
            ? response.meta.page.current_page
            : 0;
        const rango = this.getRange(primera, ultima);

        return (
            <div>
                <CotizacionesModal
                    show={this.state.show}
                    handleClose={this.hideModal}
                >
                    <CotizacionesForm

                        titulo={this.state.categoria_nombre}
                        // onSubmit={
                        //     categoria_feed == null
                        //         ? this.saveCategoria
                        //         : this.editCategoria
                        // }
                        onSubmit={this.editCategoria}
                        handleCancel={this.hideModal}
                        feature={categoria_feed}
                        initialValues={categoria_feed}
                    />
                </CotizacionesModal>
                <DefaultHeader title="Comisiones y fees" visible={true} />
                {this.state.loader ? (
                    <LoadMask loading={this.state.loader} dark></LoadMask>
                ) : (
                    <div>
                        <Link
                            to={`/ajustes`}
                            className="ps-btn"
                            style={{
                                backgroundColor: "#2e3d50",
                                color: "white"
                            }}
                        >
                            Regresar
                        </Link>
                        <hr />
                        <section>
                            <div className="ps-container">
                                <div className="row d-flex justify-content-center container-fluid">
                                    <IvaForm
                                        onSubmit={this.updateIva}
                                        feature={iva}
                                        initialValues={iva}
                                    />
                                </div>
                                <div className="row d-flex justify-content-center container-fluid">
                                    <div className="card">
                                        {estadisticas ? (
                                            <div className="card-body">
                                                Si una categoria no tiene
                                                asignado una comisión fija y
                                                variable, se utilizarán el
                                                promedio de los valores
                                                asignados para otras categorias.
                                                Actualmente estos valores son:
                                                "Comisión fija":{" "}
                                                {estadisticas["comision_f"]}{" "}
                                                (CLP + IVA) y "Comisión
                                                variable":{" "}
                                                {estadisticas[
                                                    "comision_v"
                                                ].toFixed(2)}{" "}
                                                (% + IVA).
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <React.Fragment>
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-12">
                                            <nav
                                                className="navbar justify-content-between"
                                                style={{
                                                    marginTop: "5px"
                                                }}
                                            >
                                                <a className="navbar-brand">
                                                    Categoría(s) {total}
                                                </a>
                                                <div className="form-inline">
                                                    {familias ? (
                                                        <React.Fragment>
                                                            <label
                                                                style={{
                                                                    marginRight:
                                                                        "5px"
                                                                }}
                                                            >
                                                                Familia:
                                                            </label>
                                                            <select
                                                                value={
                                                                    this.state
                                                                        .select_value
                                                                }
                                                                style={{
                                                                    marginRight:
                                                                        "5px"
                                                                }}
                                                                className="form-control"
                                                                onChange={(e) =>
                                                                    this.getFamiliaCategorias(
                                                                        e
                                                                    )
                                                                }
                                                            >
                                                                {familias.length >
                                                                0 ? (
                                                                    familias.map(
                                                                        (
                                                                            e,
                                                                            index
                                                                        ) => (
                                                                            <option
                                                                                value={
                                                                                    e.id
                                                                                }
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {
                                                                                    e.nombre
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )
                                                                ) : (
                                                                    <option>
                                                                        No hay
                                                                        datos
                                                                    </option>
                                                                )}
                                                            </select>
                                                        </React.Fragment>
                                                    ) : null}
                                                </div>
                                                <div className="ps-form--search-simple">
                                                    <input
                                                        placeholder="Buscar..."
                                                        className="form-control"
                                                        value={this.state.valor}
                                                        onChange={(e) =>
                                                            this.searchCategoria(
                                                                e,
                                                                pagina_actual
                                                            )
                                                        }
                                                    ></input>
                                                    <button>
                                                        <i className="icon icon-magnifier" />
                                                    </button>
                                                </div>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <table className="table custom--table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">
                                                            Nombre
                                                        </th>
                                                        <th scope="col">
                                                            Comision fija (CLP +
                                                            IVA)
                                                        </th>
                                                        <th scope="col">
                                                            Comision variable (%
                                                            + IVA)
                                                        </th>
                                                        <th scope="col">
                                                            Acciones
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {categorias ? (
                                                        categorias.length >
                                                        0 ? (
                                                            categorias.map(
                                                                (
                                                                    element,
                                                                    index
                                                                ) => (
                                                                    <tr
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <td scope="row">
                                                                            {
                                                                                element.id
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                element.nombre
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {element.comision_f
                                                                                ? parseInt(
                                                                                      element.comision_f
                                                                                  )
                                                                                : estadisticas[
                                                                                      "comision_f"
                                                                                  ]}{" "}
                                                                            CLP
                                                                        </td>
                                                                        <td>
                                                                            {element.comision_v
                                                                                ? element.comision_v
                                                                                : estadisticas[
                                                                                      "comision_v"
                                                                                  ].toFixed(
                                                                                      2
                                                                                  )}{" "}
                                                                            %
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                style={{
                                                                                    marginRight:
                                                                                        "5px",
                                                                                    color: "white"
                                                                                }}
                                                                                title="Cotizaciones y feeds"
                                                                                className="ps-btn ps-btn--opt edit--button"
                                                                                onClick={() =>
                                                                                    this.showModal(
                                                                                        element
                                                                                    )
                                                                                }
                                                                            >
                                                                                <i className="icon-coin-dollar"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )
                                                        ) : (
                                                            <tr>
                                                                <th
                                                                    colSpan="4"
                                                                    style={{
                                                                        textAlign:
                                                                            "center"
                                                                    }}
                                                                >
                                                                    No hay
                                                                    datos.
                                                                </th>
                                                            </tr>
                                                        )
                                                    ) : (
                                                        <tr>
                                                            <th
                                                                colSpan="4"
                                                                style={{
                                                                    textAlign:
                                                                        "center"
                                                                }}
                                                            >
                                                                No hay datos.
                                                            </th>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12 d-flex justify-content-center">
                                            {categorias &&
                                            categorias.length > 0 ? (
                                                <nav aria-label="Page navigation example">
                                                    <ul className="pagination">
                                                        <li
                                                            className={`page-item ${
                                                                anterior == 0
                                                                    ? "disabled"
                                                                    : ""
                                                            }`}
                                                        >
                                                            <a
                                                                className="page-link"
                                                                onClick={() =>
                                                                    this.paginator(
                                                                        anterior,
                                                                        pagina_actual
                                                                    )
                                                                }
                                                            >
                                                                &laquo;
                                                            </a>
                                                        </li>
                                                        {rango.map(
                                                            (e, index) => (
                                                                <li
                                                                    key={index}
                                                                    style={{
                                                                        margin: "0px 25px"
                                                                    }}
                                                                    className={`page-item ${
                                                                        pagina_actual ==
                                                                        e
                                                                            ? "active"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    <a
                                                                        className="page-link"
                                                                        onClick={() =>
                                                                            this.paginator(
                                                                                e,
                                                                                pagina_actual
                                                                            )
                                                                        }
                                                                    >
                                                                        {e}
                                                                    </a>
                                                                </li>
                                                            )
                                                        )}
                                                        <li
                                                            className={`page-item ${
                                                                pagina_actual ==
                                                                ultima
                                                                    ? "disabled"
                                                                    : ""
                                                            }`}
                                                        >
                                                            <a
                                                                className="page-link"
                                                                onClick={() =>
                                                                    this.paginator(
                                                                        ultima,
                                                                        pagina_actual
                                                                    )
                                                                }
                                                            >
                                                                &raquo;
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            ) : null}
                                        </div>
                                    </div>
                                </React.Fragment>
                            </div>
                        </section>
                    </div>
                )}
            </div>
        );
    }
}

const ms2p = (state) => ({ ...state });

const md2p = {
    getCategorias,
    postCategoriaFeedCommission,
    updateCategoriaFeedCommission,
    getAllFamilias,
    getCategoriaFeed,
    getIva,
    updateIva
};

export default connect(ms2p, md2p)(Cotizaciones);
