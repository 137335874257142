import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { api } from "../../../utility/api";
import DefaultHeader from "../layout/Header/DefaultHeader";
import { InformacionProducto } from "./InformacionProducto";
import { TiendasDiponibles } from "./TiendasDisponibles";
import UpdateProductoForm from "./UpdateProductoForm";
import { connect } from "react-redux";
import { updateProducto } from "../../../redux/modules/productos/productos";

const DetalleProductoLocal = (props) => {
    const { id } = props.match.params;
    const [producto, setProducto] = useState([]);
    const [tiendas, setTiendas] = useState([]);
    const [familias, setFamilias] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [showEdit, setshowEdit] = useState(false);


    const handleSend = (idOld,dataNew) => {
        if(idOld && Object.keys(dataNew).length>0){
            const data = {
                id: idOld,
                productoUpdate: dataNew
            }
            props.updateProducto(data)
        }  
    };

    const getProduct = async () => {
        const response = await api
            .get(
                `/productos/productos/detalle_producto_general/?producto_id=${id}`
            )
            .then((producto) => {
                setProducto(producto);
                setTiendas(producto.tiendas);
            });
    };

    const getCategorias = async () => {
        const response = await api
            .get(`/productos/categorias/all/`)
            .then((categorias) => {
                setCategorias(categorias);
            });
    };

    const getFamilias = async () => {
        const response = await api
            .get(`/productos/familias/all/`)
            .then((familias) => {
                setFamilias(familias);
            });
    };

    useEffect(() => {
        getProduct();
        getCategorias();
        getFamilias();
    }, []);

    const showUpdateProduct = () => setshowEdit(!showEdit)
    return (
        <div>
            <DefaultHeader
                title={`Detalle de: ${producto.nombre_comercial}`}
                visible={true}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}
            >
                <div>
                    <Link to="/productos" className="ps-btn ps-btn">
                        Regresar
                    </Link>
                </div>
                    
                   
                 {
                    !showEdit? <div className="ps-btn ps-btn" onClick={showUpdateProduct}>Editar  <i className="icon icon-pencil smaller" /></div>:
                    ""
                 }
                   

                
            </div>
            <hr />
            <InformacionProducto producto={producto} />
            <TiendasDiponibles tiendas={tiendas} />
            {
                showEdit?
                <UpdateProductoForm
                onSubmit={handleSend}
                producto={producto}
                categorias={categorias}
                familias={familias}
                initialValues={producto}
                handlerClose={showUpdateProduct}
                /> : ""
            }
            
        </div>
    );
};

const md2p = { updateProducto };
export default connect(null, md2p)(DetalleProductoLocal);

